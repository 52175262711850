import backArrowIcon from "../assets/images/back-arrow.svg"
import { Link, useNavigate ,useLocation} from "react-router-dom"

const GamePageHeader = () => {
    const navigate = useNavigate();
    const location = useLocation()
    return (
        <header className="position-fixed">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-12 d-flex align-items-center">
                    {
                         location.pathname.startsWith('/game/join/') ?
                         <Link to={navigate(-1)}  className="backButtonPadding backButtonAddPadding"><img src={backArrowIcon} alt=""/></Link>
                         :
                         <Link to="/home"  className="backButtonPadding backButtonAddPadding"><img src={backArrowIcon} alt=""/></Link>
                    }
                    
                </div>
            </div>
        </div>
    </header>
    )

}
export default GamePageHeader