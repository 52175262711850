import plusIcon from "../../assets/images/plus-icon.svg";
import goldCoinIcon from "../../assets/images/coin-gold.svg";
import flipkartReward from "../../assets/images/flipkart-reward.jpg";
import { Link } from "react-router-dom";
import PlusImgIcon from "../../assets/images/plus-icon.svg";
import brandReward from "../../assets/images/brand-reward.jpg";
import redbullReward from "../../assets/images/redbull-reward.jpg";
import lovecornReward from "../../assets/images/lovecorn-reward.jpg";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import helper from "../../common/Helper";
import rewardBanner from "../../assets/images/reward-banner.png";
import Lottie from "react-lottie";
import * as animationData from "../../RewardStore.json";
import DiamondImgIcon from "../../assets/images/diamond_img_icon.png";
import { useSelector } from "react-redux";
import userServices from "../../redux/user/user.services";
import { Audio, Oval } from "react-loader-spinner";
import { UserAdDisplay } from "../../common/UserAdDisplay";

const MyRewards = (props) => {
  const myRewardList = props.myRewardList;
  const navigate = useNavigate();
  const [totalBalance, setTotalBalance] = useState(0);
  const user = useSelector((state) => state.user);
  const isShowLoader = props.isLoaderShow;

  const onClickRewadBox = (data) => {
    //  console.log("data", data);
    navigate("/rewardcoupon", {
      state: {
        data: data,
      },
    });
    // navigate('/rewardcoupon',{state:{id:1,name:'sabaoon'}});
    //setSelectedReward(data);
    // setIsShowGetMyRewardPopup(true);
  };

  UserAdDisplay("Gamerji_Reward_HP_300x250");

  const [myrewardsAd, setmyrewardsAd] = useState(0);

  const [isVisible2, setIsVisible2] = useState(false);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedCount = localStorage.getItem("myrewardsAd");
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedTimestamp && currentTime > storedTimestamp) {
      localStorage.setItem("myrewardsAd", 0);
      localStorage.setItem("timestamp", new Date());
      setmyrewardsAd(0);
    } else {
      let newCount = Number(storedCount) + 1;

      // let date = new Date(new Date().getTime() + 15 * 1000);
      localStorage.setItem("myrewardsAd", newCount);
      // localStorage.setItem("timestamp", moment().format("DD-MM-YYYY"));
    }
    setTimeout(() => {
      // const storedCount = localStorage.getItem("myrewardsAd");
      const storedCount = localStorage.getItem("count");
      let newCount = Number(storedCount);
      // console.log(newCount, "newCount");
      setmyrewardsAd(newCount);
    }, 2000);
  }, []);

  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   if (myrewardsAd > 0 && myrewardsAd % 3 === 0) {
    //     setIsVisible2(true);
    //   } else {
    //     setIsVisible2(false);
    //   }
    // }, 10);

    // return () => clearTimeout(timeout);
    if (myrewardsAd >= 3) {
      setIsVisible2(true);
    } else {
      setIsVisible2(false);
    }
  }, [myrewardsAd]);

  useEffect(() => {
    (async () => {
      if (user?.userDeatils?._id) {
        const userDeatils = await userServices.getUserDetails(
          user?.userDeatils?._id
        );
        const wallet = userDeatils && userDeatils?.wallet[0];
        let userWalletTotal = userServices.getWalletTotalAmount(wallet);
        setTotalBalance(userWalletTotal);
      }
    })();
  }, [user]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="all_rewards_main_component main-content reward-main">
        <div class="container-fluid px-0">
          <div class="reward-cover d-flex">
            <img class="w-100" src={rewardBanner} alt="" />
            <Lottie
              options={defaultOptions}
              height={130}
              width={130}
              background="transparent"
              style={{ position: "absolute", right: "45px", top: "10px" }}
              speed="1"
              loop
              autoplay
            />
          </div>
        </div>

        <div className="reward-content">
          <div className="container-fluid">
            <div className="d-flex mb-3 pb-1 add-coin">
              <a
                href="#"
                className="d-flex align-items-center text-decoration-none"
              >
                <span className="badge coin-badge me-2">
                  <img className="me-1" src={DiamondImgIcon} alt="" />
                  {totalBalance}
                </span>
                <Link
                  className="all_rewards_header_coin_plus_icon"
                  to={"/buy-gems"}
                >
                  <img className="plus_icon" src={PlusImgIcon} />
                </Link>
              </a>
            </div>
            <ul
              className="nav nav-tabs justify-content-center"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <a className="nav-link" href="rewards">
                  all rewards
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link active" href="myrewards">
                  my rewards
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="myReward-tab-pane"
                role="tabpanel"
                aria-labelledby="myReward-tab"
                tabIndex="0"
              >
                <div
                  className="row g-3 mt-3 "
                  style={{
                    marginBottom: isVisible2 ? "20rem" : "none",
                  }}
                >
                  {isShowLoader ? (
                    <div className="main-content registration-main">
                      <Oval
                        height={50}
                        width={50}
                        color="#fff"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffffd1"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </div>
                  ) : myRewardList.length > 0 ? (
                    myRewardList.map((value, i) => {
                      return (
                        <>
                          <div className="col-6">
                            <div
                              className="card reward-card"
                              onClick={() => onClickRewadBox(value)}
                            >
                              <div className="card-img">
                                <img
                                  className="card-img"
                                  src={
                                    value.rewardProductDetails?.img?.default
                                      ? helper.imageBaseUrl +
                                        value.rewardProductDetails.img.default
                                      : flipkartReward
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div className="main-content registration-main">
                      <h2 className="text-white">No product found</h2>
                    </div>
                  )}
                  {/* <div className="col-6">
                                <div className="card reward-card">
                                    <div className="card-img">
                                        <img className="card-img" src={flipkartReward} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card reward-card">
                                    <div className="card-img">
                                        <img className="card-img" src={brandReward} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card reward-card">
                                    <div className="card-img">
                                        <img className="card-img" src={redbullReward} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card reward-card" onClick={() => onClickRewadBox()}>
                                    <div className="card-img">
                                        <img className="card-img" src={lovecornReward} alt="" />
                                    </div>
                                </div>
                            </div> */}

                  <div
                    id="Gamerji_Reward_HP_300x250"
                    style={{
                      display: isVisible2 ? "block" : "none",
                      //  position: "fixed",
                      // justifyContent: "center",
                      // alignItems: "center",
                      // margin: "auto",
                      // bottom: "63px",
                      // //position: 'absolute',
                      // //left: '50%',
                      // transform: 'translate(-50%, 0)',
                      //  left: 0,
                      //  right: 0,
                      // color: "white",
                       position: "fixed",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                      bottom: "63px",
                      left: 0,
                      right: 0,
                      color: "white",
                      textAlign: 'center',
                      color:"white" // change due to mismatch in staging and live 
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyRewards;
