import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";

function rewardAdcoins() {
  const video = async (data) => {
    return await resolve(
      axios({
        method: "post",
        url: helper.viApiUrl()+ "/view/video",
        responseType: "json",
        headers: helper.headers(),
        data: data,
      }).then((response) => response.data)
    );
  };
  return {
    video,
  };
}
const rewardAdcoinsApi = rewardAdcoins();
export default rewardAdcoinsApi;
