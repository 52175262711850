import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";


function ingamename() {

    const add = async (data) => {
        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl() + 'inGameName/add',
                responseType: 'json',
                headers: helper.headers(),
                data: data
            })
                .then(response => response.data)
        )

    }
    const list = async (data) => {
        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl() + 'inGameName/list',
                responseType: 'json',
                headers: helper.headers(),
                data: data
            })
                .then(response => response.data)
        )

    }


    return {
        add,
        list
    }
}
const ingamenameApi = ingamename();
export default ingamenameApi;