import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";


function customercare() {


    const customerCareAdd = async (data) => {
        // let url = "http://localhost:9090/api/"+ 'customerCare/add'
        let url = helper.viApiUrl() + 'customerCare/add'

        return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(),
                data: data
            })  
                .then(response => response.data)
        )

    }

    const customerCareCategoryList = async (data) => {
        let url = helper.saasApiUrl() + 'customercare/category/list'

        return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(),
                data:data
            })
                .then(response => response.data)
        )

    }

    const ticketList = async (data) => {
        // let url = "http://localhost:9090/api/" + 'customercare/list'
        let url = helper.viApiUrl() + 'customercare/list'

        return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(),
                data: data

            })
                .then(response => response.data)
        )

    }


    const getChatById = async (chatId) => {
        return await resolve(
            axios({
                method: 'get',
                url: helper.viApiUrl() + 'customercare/get/' + chatId,
                responseType: 'json',
                headers: helper.headers()
            })
                .then(response => response.data)
        )

    }

    const sendMessage = async (data,ticketId) => {
        return await resolve(
            axios({
                method: 'post',
                url: helper.viApiUrl() + 'customercare/addConversations/'+ticketId,
                // url: "http://localhost:9090/api/" + 'customercare/addConversations/'+ticketId,
                responseType: 'json',
                headers: helper.headers(),
                data: data
            })
                .then(response => response.data)
        )

    }





    return {
        customerCareAdd,
        customerCareCategoryList,
        ticketList,
        getChatById,
        sendMessage

    }
}
const customerCareApi = customercare();
export default customerCareApi;