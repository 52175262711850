import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import AllGames from "../../component/AllGames";
import CoinSection from "../../component/CoinSection";
import ExitPopup from "../../component/ExitPopup";
import HomeBanner from "../../component/HomeBanner";
import LastPlayed from "../../component/LastPlayed";
import Terms from "../../component/Terms";
import UpcomingBattles from "../../component/UpcomingBattles";
import { setOpenExitPopup } from "../../redux/user/user.action";
import { Audio, Oval } from 'react-loader-spinner';
import powered from "../../assets/images/powered2.png"
import vector from "../../assets/images/vector.png"

const Index = (props) => {
    // console.log(props?.bannerList)
    const [isShowExitPopup, setIsShowExitPopup] = useState(false);
    const [isAgreeTermsAndConditions, setIsAgreeTermsAndConditions] = useState(true);

    const user = useSelector((state) => state.user);
    const dispatch = useDispatch()

    const location = useLocation()
    const isShowLoader = props.isLoaderShow

    useEffect(() => {
        console.log(user.isFirstTime);  
        setIsAgreeTermsAndConditions(user.isAgreeTermsAndConditions)
        // console.log(user.isAgreeTermsAndConditions)  

        if (user.openExitPopup) {
            setIsShowExitPopup(false)
            //setIsShowExitPopup(true)
        }
        else {
            setIsShowExitPopup(false)

        }
        


    }, [user.openExitPopup, user.isAgreeTermsAndConditions])

    const onClickCancelIcon = () => {
        dispatch(setOpenExitPopup(false))

    }
    const onClickExitApp = () => {
        setIsShowExitPopup(false)
        dispatch(setOpenExitPopup(false))

        // console.log("setIsShowExitPopup");
        const storedCount = localStorage.getItem("count");
        localStorage.clear();

        localStorage.setItem("count", storedCount);
        
        let value = JSON.stringify({ "tag": "back pressed" });
        if (window.ReactNativeWebView) {

            window.ReactNativeWebView.postMessage(value);
        }

    }

    return (
        <>
            {
                <CoinSection />
            }
            {
                !isAgreeTermsAndConditions?
                <>
                    <Terms
                    agreeTerms={props.agreeTerms}
                    />
                </>
                :
                ""

            }
            {
                props?.bannerList.length > 0 &&
                <HomeBanner
                    bannerList={props.bannerList}
                />
            }

            {
                props.upcomingContestList && props?.upcomingContestList?.length > 0 &&
                <UpcomingBattles
                    upcomingContestList={props.upcomingContestList}
                />
            }
            {!user.isFirstTime &&
                <div className={props.upcomingContestList && props?.upcomingContestList?.length > 0 ? "esports addupcoming" : "esports" }>
                    <div class="esports-content">
                        <h1>what is esports?</h1>
                        <p>here's everything you need to know</p>
                        <Link to={'/ESportsFaq'} ><h2>know more<i class="fa fa-angle-double-right" aria-hidden="true"></i></h2></Link>
                    </div>
                    <img src={vector} alt="" />
                </div>
            }
            {
                props?.gameList?.length > 0 &&
                <AllGames
                    gameList={props.gameList}
                    onScroll={props.onScroll}
                    listInnerRef={props.listInnerRef}
                />

            }
            {
            /*
                props?.complatedContestList?.length > 0 &&
                <LastPlayed
                    complatedContestList={props.complatedContestList}
                />
            */

            }
            {
                isShowExitPopup && 
                <ExitPopup
                    onClickCancelIcon={onClickCancelIcon}
                    onClickExitApp={onClickExitApp} />
            }
            {/*<section className="last-played py-4">
                <img className="powered" src={powered} alt="Powered By Gamerji" />
            </section>*/}
        </>
    )

}
export default Index;