import AfterJoin from "../view/contest/AfterJoin"
import { useParams,useLocation } from "react-router-dom"
import React, { useState, useEffect } from "react"
import contestApi from "../api/contest"
import { useSelector, useDispatch } from "react-redux";
import helper from "../common/Helper";

function AfterJoinController() {
    const perems = useParams()
    const location = useLocation()

    const [contestDetails, setContestDetails] = useState({})
    const user = useSelector((state) => state.user);

    useEffect(() => {
        (async () => {
            // console.log(user)
            if (perems.contestId) {
                await getContest(perems.contestId,user?.userDeatils?._id)
                // await getContest(perems.contestId,helper.TEMP_USER_ID)

            }

        })()
    }, [perems.contestId,user])

    async function getContest(contestId,user='') {
        const list = await contestApi.getContestById(contestId,user)
        if (list.error) {
            const error = list.error
            console.log('error', error.message)
        } else {
            if (list.data) {
                const data = list.data
                if (data.success) {
                    // console.log(data.data)
                    setContestDetails(data.data)

                }
            }
        }

    }


    return (
        <AfterJoin
            contestDetails={contestDetails}
        />
    )

}
export default AfterJoinController