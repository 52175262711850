export const customStyles = {
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    padding: '5px 10px',
    scrollBehavior: 'smooth'
  }),
  input: (provided) => ({
    ...provided,
    fontWeight: 400,
    fontSize: '16px',
    color: '#EAEAEC',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 400,
    fontSize: '16px',
    color: '#EAEAEC',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    padding: '0 20px',
    background: '#2F3043',
    boxShadow: '2px 4px 14px rgba(0, 0, 0, 0.0474213)',
    borderRadius: '8px',
  }),
  menuList: (provided) => ({
    ...provided,
    '::-webkit-scrollbar': {
      display: 'none',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #595969',
    color: '#EAEAEC',
    backgroundColor: 'transparent',
    padding: '10px 0',
    fontWeight: 400,
    fontSize: '16px',
    ':active': {
      backgroundColor: 'transparent',
    },
  }),
}