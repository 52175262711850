import nightrider from "../../assets/images/nightrider-img.png";
import tiburonsharks from "../../assets/images/tiburonsharks-img.png";
import skullweapon from "../../assets/images/skullweapon-img.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import backArrow from "../../assets/images/back-arrow.svg";
import { useEffect, useState, useRef } from "react";
import { UserAdDisplay } from "../../common/UserAdDisplay";
import { Audio, Oval } from "react-loader-spinner";

const Index = (props) => {
  UserAdDisplay("Gamerji_AfterJoin_LB_320x50");
  const [contestUserList, setContestUserList] = useState([]);
  // console.log("contestUserList", contestUserList);
  const listInnerRef = useRef();
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        console.log('------')
        props.getLoadMore();
        // This will be triggered after hitting the last element.
        // API call should be made here while implementing pagination.
      }
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    setContestUserList(props.contestUserList);
  }, [props.contestUserList]);
  const location = useLocation();
  const gameName = location?.state?.gameName;

  const [leaderboardAd, setleaderboardAd] = useState(0);
  const [isVisible2, setIsVisible2] = useState(false);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedleaderboardAd = localStorage.getItem("leaderboardAd");
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedTimestamp && currentTime > storedTimestamp) {
      localStorage.setItem("leaderboardAd", 0);
      localStorage.setItem("timestamp", new Date());
      setleaderboardAd(0);
    } else {
      let newleaderboardAd = Number(storedleaderboardAd) + 1;

      let date = new Date(new Date().getTime() + 15 * 1000);
      localStorage.setItem("leaderboardAd", newleaderboardAd);
      // localStorage.setItem("timestamp", moment().format("DD-MM-YYYY"));
    }
    setTimeout(() => {
      // const storedleaderboardAd = localStorage.getItem("leaderboardAd");
      const storedleaderboardAd = localStorage.getItem("count");
      let newleaderboardAd = Number(storedleaderboardAd);
      console.log(newleaderboardAd, "newleaderboardAd");
      setleaderboardAd(newleaderboardAd);
    }, 2000);
  }, []);

  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   console.log(leaderboardAd);
    //   if (leaderboardAd > 0 && leaderboardAd % 3 === 0) {
    //     setIsVisible2(true);
    //   } else {
    //     setIsVisible2(false);
    //   }
    // }, 10);

    // return () => clearTimeout(timeout);
    if (leaderboardAd >= 3) {
      setIsVisible2(true);
    } else {
      setIsVisible2(false);
    }
  }, [leaderboardAd]);

  return (
    <section className="container-fluid leaderboard-main">
      <div className="leaderboard-inner">
        <table className="table table-fixed">
          <thead>
            <tr>
              <th scope="col" className="col-3">
                {gameName}
              </th>
              <th scope="col" className="col-3 text-center">
                kills
              </th>
              <th scope="col" className="col-3 text-center">
                rank
              </th>
            </tr>
          </thead>
          {
            props.isLoading ?
              <div className="main-content registration-main">
                <Oval
                  height={50}
                  width={50}
                  color="#fff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#ffffffd1"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
              :
              <tbody onScroll={onScroll} ref={listInnerRef}>
                {contestUserList.map((value, i) => {
                  return (
                    <tr>
                      <td className="col-3" style={{display:'flex'}}>
                        <img className="me-2" src={nightrider} alt="" />
                        <span className="fw-bold h3 m-0" style={{display:'block',width:'10rem',whiteSpace: "nowrap", overflow: "hidden",textOverflow: "ellipsis", paddingTop: '10px'}}> {/* GJVI-363 Added css for marquee  */}
                          {value.inGameUserName}
                        </span>
                      </td>
                      <td className="col-3 fw-medium text-center">{value.kills}</td>
                      {/* <td className="col-3 text-center"><img src="assets/images/rank-1.svg" alt=""/></td> */}
                      {value.rank === 1 && (
                        <td className="col-3 text-center">
                          <img src="assets/images/rank-1.svg" alt="" />
                        </td>
                      )}
                      {value.rank === 2 && (
                        <td className="col-3 text-center">
                          <img src="assets/images/rank-2.svg" alt="" />
                        </td>
                      )}
                      {value.rank === 3 && (
                        <td className="col-3 text-center">
                          <img src="assets/images/rank-3.svg" alt="" />
                        </td>
                      )}
                      {value.rank !== 1 && value.rank !== 2 && value.rank !== 3 && (
                        <td className="col-3 text-center">{value.rank}</td>
                      )}
                    </tr>
                  );
                })}
                <div
                  id="Gamerji_AfterJoin_LB_320x50"
                  style={{
                    display: isVisible2 ? "block" : "none",
                    position: "fixed",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    color: "white",
                    textAlign: 'center'
                  }}
                >

                </div>
              </tbody>
          }
        </table>
      </div>
    </section>
  );
};
export default Index;
