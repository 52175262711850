import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";


function banner() {

    const list = async (data) => {
        return await resolve(
            axios({
                method: 'post',
                url: helper.viApiUrl() + 'banner/list',
                responseType: 'json',
                headers: helper.headers(),
                data:data
            })
                .then(response => response.data)
        )

    }
    return {
        list,
    }
}
const bannerApi = banner();
export default bannerApi;