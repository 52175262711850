import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";


function faq() {

    const listByGame = async (gameId) => {
        return await resolve(
            axios({
                method: 'get',
                url: helper.viApiUrl() + 'faqByGame/byGame/' + gameId,
                responseType: 'json',
                headers: helper.headers()
            })
                .then(response => response.data)
        )

    }
    const list = async (title = '',data) => {
        let url = helper.viApiUrl() + 'faq/list'
        if (title !== '') {
            url = helper.viApiUrl() + 'faq/list?title=' + title
        }
        return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(),
                data:data
            })
                .then(response => response.data)
        )

    }

    return {
        listByGame,
        list,
    }
}
const faqApi = faq();
export default faqApi;