import AccountFaq from "../view/faq/AccountFaq"
import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import faqApi from "../api/faq";

function AccountFaqController() {
    const { setTitle } = useOutletContext();
    const [faqList, setFaqList] = useState([])
    const [searchValue, setSearchValue] = useState([])
    const [isLoader, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setTitle('FAQs')
            await getFaqList()
        })();
    }, []);

    const onChange = async (e) => {
        setSearchValue(e.target.value)
        await getFaqList(e.target.value)

    }

    async function getFaqList(title = '') {
        let data = {}
        data.search = {}
        data.search.isActive = true
        data.pagination = {}

        // data.pagination.pageNo = currPage
        data.pagination.recordPerPage = 100
        data.pagination.sortBy = "order"
        data.pagination.sortDirection = "asc"
        const list = await faqApi.list(title, data)
        if (list.error) {
            const error = list.error
            console.log('error', error.message)
        } else {
            setIsLoading(false)
            if (list.data) {
                const data = list.data
                if (data.success) {
                    setFaqList(data.data)
                } else {
                    setFaqList([])

                }
            }
        }
    }




    return (
        <AccountFaq
            faqList={faqList}
            onChange={onChange}
            searchValue={searchValue}
            isLoaderShow={isLoader}
        />
    )

}
export default AccountFaqController