import { useState, useEffect } from "react";
import { UserAdDisplay } from "../../common/UserAdDisplay";
// import { useAdVisibility } from "../../common/UseAdVisibility";
import { Link, useNavigate, useLocation, useHistory } from "react-router-dom";
export const AfterjoinPopup = () => {
  UserAdDisplay("Gamerji_AfterJoin_320x480");

  const [adJoincontestPopup, setadJoincontestPopup] = useState(true);
  const [cross, setCross] = useState(false);
  const closeAd = () => {
    localStorage.setItem("isShowFAQAdInContestList", "false");

    setadJoincontestPopup(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (adJoincontestPopup) {
        setCross(true);
      }
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    localStorage.setItem("isVisibleAD", "false");
  }, []);

  return (
    <div>
      {adJoincontestPopup && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgb(0 0 0 / 88%)",
            zIndex: 9999,
          }}
        >
          <div
            id="Gamerji_AfterJoin_320x480"
            style={{
              position: "relative",
              // width: "80%",
              // maxWidth: "800px",

              borderRadius: "8px",
              padding: "20px",

              // margin: "0 auto",
              // display: isVisible2 ? "block" : "none",
              // color: "white",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-27px",
                right: "19px",
                color: "white",
                cursor: "pointer",
                fontSize: "2rem",
              }}
            >
              {cross && <div onClick={closeAd}>X</div>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
