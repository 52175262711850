import close from "../../assets/images/closeframe.png";
import demo from "../../assets/images/home-banner-bg.png";

export const ImagePopup = ({setExpandimage,imageUrl}) => {
// console.log(index,"dd")
const collapse = () => {
    setExpandimage(false);
}

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgb(0 0 0 / 84%)",
        zIndex: 9999,
      }}
    >
      <div className="expand-image">
        {" "}
        <img className="" src={imageUrl} alt="" width="100%" height="200px" />
        <button className="expand-image-img" onClick={() => collapse()} >
          {" "}
          <img src={close} alt="expand" />
        </button>
      </div>
    </div>
  );
};
