import Game from "../view/game/Game";
import { useRef } from "react";
import { useState, useEffect } from "react";
import { UserAdDisplay } from "../common/UserAdDisplay";
import { useSelector, useDispatch } from "react-redux";
// import { useAdVisibility } from "../common/UseAdVisibility";

const AllGames = (props) => {
  // const { isVisible } = useAdVisibility();
  const user = useSelector((state) => state.user);
  const [isAgreeTermsAndConditions, setIsAgreeTermsAndConditions] =
    useState(true);

  // {
  //   user && user.isFirstTime ? (
  //     <div></div>
  //   ) : (
  //     UserAdDisplay("Gamerji_HP_300x250")
  //   );
  // }
  useEffect(() => {
    if (isAgreeTermsAndConditions) {
      setTimeout(() => {
        UserAdDisplay("Gamerji_HP_300x250");
      }, 2000);
      setTimeout(() => {
        UserAdDisplay("Gamerji_HP_320x50");
      }, 2000);
    }
  }, [isAgreeTermsAndConditions]);
  // {isAgreeTermsAndConditions ? (
  //   UserAdDisplay("Gamerji_HP_300x250")
  // ) : (
  //   <></>
  // )}
  useEffect(() => {
    console.log(user.isFirstTime);
    setIsAgreeTermsAndConditions(user.isAgreeTermsAndConditions);
    console.log(user.isAgreeTermsAndConditions);
  }, [user.isAgreeTermsAndConditions]);
  const gameList = props.gameList;


  UserAdDisplay("Gamerji_HP_300x250");
  UserAdDisplay("Gamerji_HP_320x50");

  const [myhome2Ad, setmyhome2Ad] = useState(0);
  const [isVisible2, setIsVisible2] = useState(true);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedCount = localStorage.getItem("myhome2Ad");
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedTimestamp && currentTime > storedTimestamp) {
      localStorage.setItem("myhome2Ad", 0);
      localStorage.setItem("timestamp", new Date());
      setmyhome2Ad(0);
    } else {
      let newCount = Number(storedCount) + 1;

      // let date = new Date(new Date().getTime() + 15 * 1000);
      localStorage.setItem("myhome2Ad", newCount);
      // localStorage.setItem("timestamp", moment().format("DD-MM-YYYY"));
    }
    setTimeout(() => {
      // const storedCount = localStorage.getItem("myhome2Ad");
      const storedCount = localStorage.getItem("count");
      let newCount = Number(storedCount);
      // console.log(newCount, "newCount");
      setmyhome2Ad(newCount);
    }, 2000);
  }, []);

  useEffect(() => {
   
    if (myhome2Ad >= 3) {
      setIsVisible2(true);
    } else {
      setIsVisible2(false);
    }
  }, [myhome2Ad]);



  return (
    <>
     <div
        id="Gamerji_HP_320x50"
        style={{
          display: isVisible2 ? "block" : "none",
          marginTop:"1rem",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          color:"white"
        }}
      ></div>
    <section className="all-games">
      <div
        className="container-fluid"
        //    onScroll={props.onScroll}
        //    ref={props.listInnerRef}
        //    style={{ height: "80vh", overflowY: "auto" }}
      >
        <h2 className="h1 text-white mb-3">all games</h2>
        <div className="row">
          {gameList?.length > 0 ? (
            gameList?.map((game, key) => {
              return <Game key={key} myKey={key} {...game} />;
            })
          ) : (
            <></>
          )}
        </div>
      </div>

      <div
        id="Gamerji_HP_300x250"
        style={{
          display: isVisible2 ? "block" : "none",
          marginTop:"1rem",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          color:"white"
        }}
      ></div>
    </section>
    </>
  );
};
export default AllGames;
