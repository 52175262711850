import rightChevroncon from "../../assets/images/right-chevron.svg";
// import calendarIcon from "../../assets/images/calendar-icon.svg";
// import timeIcon from "../../assets/images/time-icon.svg";
// import coinIcon from "../../assets/images/coin-icon.svg";
// import mapIcon from "../../assets/images/map-icon.svg";
// import trophyIcon from "../../assets/images/trophy-icon.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Contest from "./Contest";
import helper from "../../common/Helper";
import { Audio, Oval } from "react-loader-spinner";
import { UserAdDisplay } from "../../common/UserAdDisplay";
// import { useAdVisibility } from "../../common/UseAdVisibility";
import { useState, useEffect } from "react";
import { AdPopup } from "./AdPopup";
import { useSelector } from "react-redux";
import clevertap from "clevertap-web-sdk";

// import { AdPopup } from "../common/AdPopup";
/**
 * import { Audio, Oval } from 'react-loader-spinner';
isShowLoader ?
<div className="main-content registration-main">
       <Oval
             height={50}
             width={50}
             color="#fff"
             wrapperStyle={{}}
             wrapperClass=""
             visible={true}
             ariaLabel='oval-loading'
             secondaryColor="#ffffffd1"
             strokeWidth={2}
             strokeWidthSecondary={2}
       />
</div>
:
contestList.length > 0
 */

const List = (props) => {
  // console.log(props.gameId);
  clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
  clevertap.privacy.push({ useIP: true }); // Set the flag to true, if the user agrees to share their IP data
  clevertap.init(helper.cleverTapAccount, "in"); //
  clevertap.setLogLevel(3); // 3 = all logs
  const user = useSelector((state) => state.user);
  useEffect(() => {
    console.log(user?.userCurrentGameName);
    clevertap.event.push("Gamerji_GameListVisited", {
      GameName: user?.userCurrentGameName,
      Date: new Date(),
    });
  });

  const [adPopup, setAdPopup] = useState(false);
  UserAdDisplay("Gamerji_MyContest_320x50");
  const [allcontestsAd, setallcontestsAd] = useState(0);
  const [isVisible2, setIsVisible2] = useState(false);
  const location = useLocation();
  localStorage.setItem("isVisibleAD", "false");

  useEffect(() => {
    const isShowFAQAdInContestList = localStorage.getItem(
      "isShowFAQAdInContestList"
    );
    // console.log("isShowFAQAdInContestList out",isShowFAQAdInContestList)

    if (isShowFAQAdInContestList === "true") {
      // console.log("isShowFAQAdInContestList in",isShowFAQAdInContestList)
      setAdPopup(true);
      localStorage.setItem("isShowFAQAdInContestList", "false");
    }
  }, []);

  // useEffect(() => {
  //   const previousPath = "/gamefaq/" + props.gameId;
  //   const currentPath = location.pathname;

  //   if (currentPath === "/contest/" + props.gameId && previousPath) {
  //     setAdPopup(true)
  //   } else {
  //     console.log("hey-----");
  //     setAdPopup(false)
  //   }

  //   console.log(previousPath);
  // }, [location]);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedCount = localStorage.getItem("allcontestsAd");
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedTimestamp && currentTime > storedTimestamp) {
      localStorage.setItem("allcontestsAd", 0);
      localStorage.setItem("timestamp", new Date());
      setallcontestsAd(0);
    } else {
      let newCount = Number(storedCount) + 1;

      // let date = new Date(new Date().getTime() + 15 * 1000);
      localStorage.setItem("allcontestsAd", newCount);
      // localStorage.setItem("timestamp", moment().format("DD-MM-YYYY"));
    }
    setTimeout(() => {
      // const storedCount = localStorage.getItem("allcontestsAd");
      const storedCount = localStorage.getItem("count");
      let newCount = Number(storedCount);
      // console.log(newCount, "newCount");
      setallcontestsAd(newCount);
    }, 2000);
  }, []);

  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   if (allcontestsAd > 0 && allcontestsAd % 3 === 0) {
    //     setIsVisible2(true);
    //   } else {
    //     setIsVisible2(false);
    //   }
    // }, 10);

    // return () => clearTimeout(timeout);
    if (allcontestsAd >= 3) {
      setIsVisible2(true);
    } else {
      setIsVisible2(false);
    }
  }, [allcontestsAd]);

  const navigate = useNavigate();
  const contestList = props.contestList;

  const isShowLoader = props.isLoaderShow;

  const processBarStyle = {
    width: "25%",
    ariaValuenow: "25",
    ariaValuemin: "25",
    ariaValuemax: "100",
  };
  // const { isVisible } = useAdVisibility();
  const goTocontests = () => {
    if (props.gameId) navigate("/mycontest/" + props.gameId);
  };
  return (
    <section className="game-detail game-detail-back">
      <div className="container-fluid">
        <div onClick={() => goTocontests()} className="contest-link">
          my contests
          <img src={rightChevroncon} alt="" />
        </div>

        {isShowLoader ? (
          <div className="main-content registration-main">
            <Oval
              height={50}
              width={50}
              color="#fff"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#ffffffd1"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : contestList.length > 0 ? (
          contestList.map((contest, key) => {
            return (
              <>
                <Contest
                  key={key}
                  {...contest}
                  gameId={props.gameId}
                  contestDetails={contest}
                />
              </>
            );
          })
        ) : (
          <>
            {/* <div>
                                <h2 className="text-white">No Contest Found</h2>
                            </div> */}
            <div className="contest-no-data registration-main ">
              <h2 className="text-white m-0">No Contest Found</h2>
            </div>
          </>
        )}
      </div>

      <div
        id="Gamerji_MyContest_320x50"
        style={{
          display: isVisible2 ? "block" : "none",
          // position: "fixed",
          // justifyContent: "center",
          // alignItems: "center",
          // margin: "auto",
          // bottom: 0,
          // left: 0,
          // right: 0,
          // color: "white",
          position: "fixed",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          bottom: 0,
          left: 0,
          right: 0,
          color: "white",
        }}
      ></div>

      {adPopup && <AdPopup setAdPopup={setAdPopup} props={props} />}
    </section>
  );
};
export default List;
