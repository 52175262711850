import calendarIcon from "../../assets/images/calendar-icon.svg";
import timeIcon from "../../assets/images/time-icon.svg";
import coinIcon from "../../assets/images/coin-icon.svg";
import mapIcon from "../../assets/images/map-icon.svg";
import trophyIcon from "../../assets/images/trophy-icon.svg";
import infoIcon from "../../assets/images/info-icon.svg";
import closeIcon from "../../assets/images/close-btn-1.svg";
import diamondIcon from "../../assets/images/diamond-icon.svg";
import downchevronIcon from "../../assets/images/down-chevron.svg";
import clevertap from "clevertap-web-sdk";

import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import InfoPopup from "./InfoPopup";
import userServices from "../../redux/user/user.services";

const Contest = (props) => {
  console.log("sk", props);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const contestDetails = props.contestDetails;
  const progress = (props.totalJoinedPlayers * 100) / props.totalPlayers;
  const processBarStyle = {
    width: progress + "%",
    ariaValuenow: "25",
    ariaValuemin: "25",
    ariaValuemax: "100",
  };
  const setWidth = {
    minWidth:"70px"
  } 

  console.log(contestDetails?.gameAry?.name);
  // useEffect(() => {
  //   console.log(user?.userCurrentGameName);
  //   clevertap.event.push("Gamerji_GameListVisited", {
  //     GameName: "Free Fire",
  //     Date: new Date(),
  //   });
  // });

  function sumOfWallet(obj) {
    let objTotal = Object.keys(obj).reduce((sum, key) => {
      if (isNumber(obj[key])) sum = sum + parseFloat(obj[key] || 0);
      return sum;
    }, 0);

    return objTotal;
  }

  function isNumber(num) {
    return (
      (typeof num == "string" || typeof num == "number") &&
      !isNaN(num - 0) &&
      num !== ""
    );
  }

  const goToJoin = () => {
    console.log("/afterjoin/", props._id);
    navigate("/afterjoin/" + props._id);
  };

  const goToJoinGame = async () => {
    console.log("goToJoinGame", 1, user);
    const userDetails = await userServices.getUserDetails(
      user?.userDeatils?._id
    );
    // console.log("goToJoinGame",2,userDetails)
    // console.log(userDetails.wallet)
    // console.log(props.contestFor)

    // return false

    // console.log("user.userDeatils",user.userDeatils)
    console.log(!props.isJoined);
    if (!props.isJoined) {
      // var balance = user.userDeatils.wallet[0].winningAmount + user.userDeatils.wallet[0].packs;
      // if (balance >= props.entryFee)
      //     navigate('/game/join/' + props._id + "/" + props.gameId)
      // else
      //     toast("Don't have enough balance to join this game ");

      if (parseInt(props.entryFee) > 0) {
        //  console.log("goToJoinGame",4)
        if (Array.isArray(userDetails?.wallet)) {
          let login_company_id = null,
            contestCompanyList = props.contestFor[0];
          if (props.contestFor.length > 0) {
          }

          if (localStorage.getItem("companyInfo")) {
            login_company_id = localStorage.getItem("companyInfo");
          }
          //   console.log("goToJoinGame",5,contestCompanyList)
          let currentUserCompany = [],
            userCurrency = contestCompanyList?.inCurrency?.value,
            walletCurrentCurrency = [],
            userWalletTotal = 0;
          //  console.log("goToJoinGame",6)
          let currentUserAmount = userDetails.wallet;

          console.log(currentUserAmount);
          // return false
          //  console.log("goToJoinGame",7,currentUserAmount)
          // for (let i = 0; i < currentUserAmount.length; i++) {
          //   if (
          //     currentUserAmount[i].currency.toString() ==
          //     userCurrency.toString()
          //   ) {
          //     walletCurrentCurrency.push(currentUserAmount[i]);
          //   }
          // }
          //   console.log("goToJoinGame",8)
          console.log(currentUserAmount[0].winningAmount);
          if (currentUserAmount[0].winningAmount > 0) {
            let userWalletBallance = currentUserAmount[0].winningAmount;
            console.log(userWalletBallance);
            userWalletTotal = userWalletBallance;
              console.log("goToJoinGame",userWalletTotal)
              console.log(userWalletTotal >= parseInt(props.entryFee))
            if (userWalletTotal >= parseInt(props.entryFee)) {
              //    console.log("goToJoinGame",10)
              navigate("/game/join/" + props._id + "/" + props.gameId);
            } else {
              //    console.log("goToJoinGame",11)
              toast("Don't have enough balance to join this game ");
            }
          } else {
            //    console.log("goToJoinGame",12)
            toast("Don't have enough balance to join this game ");
          }
        } else {
          //   console.log("goToJoinGame",13)
          toast("Don't have enough balance to join this game ");
        }
      } else {
        //   console.log("goToJoinGame",14)
        navigate("/game/join/" + props._id + "/" + props.gameId);
      }
    } else navigate("/afterjoin/" + props._id);
  };

  const [infoModalOpen, setInfoModalOpen] = useState(true);
  const [infoModalData, setInfoModalData] = useState(undefined);
  const [prizepoolData, setPrizePoolData] = useState([]);
  const openInfoModal = (data) => {
    // console.log("data",data);
    setInfoModalData(undefined);
    setInfoModalData(data);
    // console.log("infoModalData",infoModalData.prizePool);
    // setInfoModalOpen(true)
  };

  useEffect(() => {
    // setInfoModalData(props)
    if (infoModalData) {
      // console.log("infoModalData=>", infoModalData);
      // console.log("infoModalData", infoModalData?.prizePool);
      setPrizePoolData(infoModalData.prizePoolInfo);
      // document.getElementById('prizepool').innerText = infoModalData?.prizePool;
    }
    setInfoModalData();
  }, [infoModalData]);

  useEffect(() => {
    console.log("contestDetails", contestDetails);
  }, [contestDetails]);

  return (
    <>
      <div className="game-detail-card card">
        <div className="card-body">
          <div className="card-header d-flex align-items-center marquee-div">
            {/* <h2 className="text-white mb-0">{props.title} */}
            {props.title?.length > 20 ? (
              <div className="">
                <div className="marquee-text"> {props.title}</div>
              </div>
            ) : (
              //   <h2 className="">
              //     {props.title}{" "}

              //   </h2>

              <div className="text-white mb-0 pb-1">
                <div className=""> {props.title}</div>
              </div>
            )}
            {/* data-bs-toggle="modal" data-bs-target="#infoModal"
                        <h2 class="text-white mb-0 pb-1"><marquee scrollamount="5">{props.title}</marquee><img src={infoIcon} data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Add Text Here" alt="" /></h2> */}

            <h2 className="text-white mb-0 d-flex align-item-center">
              {/* <marquee width="85%" height="20px" scrollamount="4">
           
                </marquee> */}

              <img
                src={infoIcon}
                className="ms-2"
                data-bs-toggle="modal"
                data-bs-target="#infoModal"
                alt=""
              />
            </h2>

            <span className="badge ms-auto">ID - {props.shortCode}</span>
          </div>
          <div className="card-content">
            <div className="row mb-3">
              <div className="col-5">
                <div className="d-flex align-items-center">
                  {/* <img src={calendarIcon} alt="" /> */}
                  {/* <p className="ms-2 mb-0 h3 text-muted">22 Sep 22'</p> */}
                  {/* <p className="ms-2 mb-0 h3 text-muted">{moment(props.date).format('DD MMM YY')}</p> */}
                  <div className="">
                    <h3 className="mb-1 text-white fw-bold d-flex align-items-center">
                      {moment(props.date).format("DD MMM YY")}
                    </h3>
                    <p className="mb-0 h4 text-muted">date</p>
                  </div>
                </div>
              </div>
              <div className="col-4 px-0">
                <div className="d-flex align-items-center">
                  {/* <img src={timeIcon} alt="" /> */}
                  {/* <p className="ms-2 mb-0 h3 text-muted">02:30 Pm</p> */}
                  {/* <p className="ms-2 mb-0 h3 text-muted">{moment(props.time).format('hh:mmA')}</p> */}
                  <div className="">
                    <h3 className="mb-1 text-white fw-bold d-flex align-items-center">
                      {moment(props.time).format("hh:mm a")}
                    </h3>
                    <p className="mb-0 h4 text-muted">time</p>
                  </div>
                </div>
              </div>
              <div className="col-3 px-0">
                <div className="d-flex align-items-center">
                  {/* <img src={coinIcon} alt="" /> */}
                  {/* <p className="ms-2 mb-0 h3 text-muted">{props.entryFee == 0 ? 'free' : props.entryFee}</p> */}
                  <div className="">
                    <h3 className="mb-1 text-white fw-bold d-flex align-items-center">
                      {props.entryFee == 0 ? (
                        "Free"
                      ) : (
                        <div className="d-flex align-items-center icon-title  ">
                          <img src={diamondIcon} alt="" />
                          <h3 className="mb-1 text-white ms-1 my-0 fw-bold">
                            {props.entryFee ?? "-"}
                          </h3>
                        </div>
                      )}
                    </h3>
                    <p className="mb-0 h4 text-muted">entry fee</p>
                  </div>
                </div>
              </div>
            </div>
            {console.log(props.titles)}
            <div className="row">
              {/* Change Start */}

              {
                // props.titles.length > 0 && props.titles[0] &&
                <div className="col-5">
                  <div className="d-flex align-items-center">
                    {/* <img src={mapIcon} alt="" />
                                        <p className="ms-2 mb-0 h3 text-muted">{props.titles[0]?.value ?? '-'}</p> */}
                    <div className="">
                      <h3 className="mb-1 text-white fw-bold d-flex align-items-center">
                        {props.titles[0]?.value ?? "-"}
                      </h3>
                      <p className="mb-0 h4 text-muted">
                        {props.titles[0]?.name}
                      </p>
                    </div>
                  </div>
                </div>
              }

              {
                contestDetails?.gameAry[0]?.settings?.titles.length > 0 && contestDetails?.gameAry[0]?.settings?.titles[1].isSelection && (
                  // <div className='col-4'>
                  <div className="col-4 px-0">
                    <div className="d-flex align-items-center">
                      {/* <img src={mapIcon} alt="" /> */}
                      {/* <p className="ms-2 mb-0 h3 text-muted">{props.titles[1]?.value ?? '-'}</p> */}
                      <div>
                        <h3 className="mb-1 text-white fw-bold d-flex align-items-center">
                          {props.titles[1]?.value ?? "-"}
                        </h3>
                        <p className="mb-0 h4 text-muted">
                          {props.titles[1]?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                )
                // :
                // <div className='col-4'>

                // </div>
              }
              <div
                className="col-3  px-0"
                onClick={() => setInfoModalData(props)}
              >
                <div
                  className="d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target={"#prizepoolModal" + props._id}
                >
                  {/* <img src={trophyIcon} alt="" /> */}
                  {/* <p className="ms-2 mb-0 h3 text-muted">{props.prizePool ?? '-'}</p> */}
                  <div className="">
                    {/* <h3 className="mb-1 text-white fw-bold d-flex align-items-center">{props.prizePool ?? '-'}  <img src={downchevronIcon} alt="" /></h3> */}
                    <div className="d-flex align-items-center icon-title">
                      <img src={diamondIcon} alt="" />
                      <h3 className="mb-1 text-white ms-1 my-0 fw-bold">
                        {props.prizePool ?? "-"}
                      </h3>
                    </div>

                    <p className="mb-0 h4 text-muted">prize pool</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer px-0">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-label="Basic example"
                    style={processBarStyle}
                  ></div>
                </div>
                <p className="text-white h4 mt-1 mb-0">
                  <strong>{props.totalJoinedPlayers}</strong>/
                  {props.totalPlayers} players joined{" "}
                </p>
              </div>
              <div className="col-6 d-flex justify-content-end">
                {props.totalPlayers - props.totalJoinedPlayers > 0 ? (
                  <div
                    className="btn btn-danger"
                    onClick={() => goToJoinGame()}
                  >
                    {/* {props.entryFee > 0 ? "join now" : "join for free"} */}
                    {props.isJoined
                      ? "joined"
                      : props.entryFee > 0
                      ? "join now"
                      : "join for free"}
                  </div>
                ) : (
                  <div className="btn btn-danger" onClick={() => goToJoin()}>
                    full
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {infoModalOpen &&
                <InfoPopup />
            } */}

      {/* <!-- Modal --> */}
      <div
        class="modal fade common-modal"
        id="infoModal"
        tabindex="-1"
        aria-labelledby="infoModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <a
                href="#"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src={closeIcon} alt="" />
              </a>
              <h2>more details</h2>
              <hr />
              {contestDetails?.gameAry[0]?.settings?.titles?.map(
                (title, key) => {
                  // console.log('44',p1)
                  return (
                    title.isSelection && (
                      <>
                        <h3 class="fw-bold">{title.value} -</h3>
                        <p>{title.moreinfo}</p>
                        {/* <p>Types - Erangel, Vikendi, Miramar, Sanhok, Livik, TDM</p> */}
                      </>
                    )
                  );
                }
              )}
              {/* {contestDetails.titles[0]?.name === "Map" &&
                               
                            }
                            {contestDetails.titles[0]?.name === "players" &&
                                <>
                                    <h3 class="fw-bold">players -</h3>
                                    <p></p>
                                </>
                            }
                            {contestDetails.titles[1]?.name === "Perspective" &&
                                <>
                                    <h3 class="fw-bold">perspective -</h3>
                                    <p>Perspective is gaming mode in which you need to spectate the match.</p>
                                    <p>Types - Third-person perspective(TPP), First-person perspective(FPP)</p>
                                </>
                            } */}
              <h3 class="fw-bold">prize pool -</h3>
              <p>
                Prizepool is the total amount of gems that is distributed among
                the winners in a tournament.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div
        class="modal fade common-modal"
        id={"prizepoolModal" + props._id}
        tabindex="-1"
        aria-labelledby="prizepoolModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <a
                href="#"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src={closeIcon} alt="" />
              </a>
              <div class="d-flex align-items-center justify-content-between">
                <h2>prize pool breakup</h2>
                <div class="d-flex align-items-center icon-title" style={setWidth}>
                  <img src={diamondIcon} alt="" />
                  <h3 id="prizepool" class="ms-1 my-0 fw-bold">
                    {props.prizePool}
                  </h3>
                </div>
              </div>
              <hr />

              {contestDetails?.prizePoolInfo.length > 0 ? (
                contestDetails?.prizePoolInfo?.map((p1, key) => {
                  // console.log('44',p1)
                  return (
                    // <h1>ff</h1>
                    <div class="d-flex justify-content-between align-items-center py-2">
                      <h3 class="m-0" id={"rank" + key}>
                        {p1.rankFrom === p1.rankTo ? (
                          <span>Rank {p1.rankFrom} </span>
                        ) : (
                          <span>
                            Rank {p1.rankFrom}
                            {p1.rankTo !== "0" && " to "}
                            {p1.rankTo !== "0" && p1.rankTo}
                          </span>
                        )}
                      </h3>
                      <div class="d-flex align-items-center icon-title" style={setWidth}>
                        <img src={diamondIcon} alt="" />
                        <h3 class="ms-1 my-0" id={"amount" + key}>
                          {p1.amount}
                        </h3>
                      </div>
                    </div>
                  );
                })
              ) : (
                // <div className="">
                //     <h2 className="text-white m-0">No Prizepool added for this contest.</h2>
                // </div>
                <div class="d-flex justify-content-between align-items-center py-2">
                  <h3 class="m-0" id={"rank"}>
                    <span> Per Kill</span>
                  </h3>
                  <div class="d-flex align-items-center icon-title" style={setWidth}>
                    <img src={diamondIcon} alt="" />
                    <h3 class="ms-1 my-0" id={"amount"}>
                      {contestDetails.killPoints}
                    </h3>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};
export default Contest;
