import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import DiamondImgIcon from "../../assets/images/diamond_img_icon.png";
import BackImgIcon from "../../assets/images/back-arrow.svg";

import "./Rewards.css";
import buycoinsApi from "../../api/buycoins";
import buyCoinsXsollaAPI from "../../api/buyCoinsXsolla";
import { useSelector } from "react-redux";
import userServices from "../../redux/user/user.services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configData from "../../config/config.json";
import { ShowRewardedAd } from "../../common/ShowRewardedAd";
import getSymbolFromCurrency from "currency-symbol-map";
import { Oval } from "react-loader-spinner";
const countryToCurrency = require('country-to-currency');
// var easebuzzCheckout = new window.EasebuzzCheckout(
//   configData.EASEBUZZ_KEY,
//   configData.EASEBUZZ_ENV
// );

export default function BuyGems(props) {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector((state) => state.user);
  const [buyGemsList, setBuyGemsList] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const [isShowLoader, setIsShowLoader] = useState(false);
  // let invoiceId = searchParams.get("invoice_id") || null;
  // let status = searchParams.get("status") || null;
  // let foreignInvoice = searchParams.get("foreignInvoice") || null;
  // let user_id = searchParams.get("user_id") || null
  // let CPAmount = localStorage.getItem('CPAmount') || 0
  // let sku = localStorage.getItem('sku') || 0
  // let CPCoin = localStorage.getItem('CPCoin') || 0
  // useEffect(() => {
  //   console.log(">>1")
  //   if (invoiceId && status && user_id && foreignInvoice && CPAmount && sku && CPCoin) {
  //     console.log(">>2")
  //     onPurchaseGems(invoiceId, status, foreignInvoice, CPAmount, user_id, sku, CPCoin)
  //   }
  // }, [invoiceId]);

  async function getBuyCoinList() {
    setIsShowLoader(true)
    let playload = { group_id: 1, limit: 10, skip: 0, country: "IN" }
    const list = await buyCoinsXsollaAPI.list(playload);
    if (list.error) {
      setIsShowLoader(false)
      const error = list.error;
      console.log("error", error.message);
    } else {
      if (list.data) {
        setIsShowLoader(false)
        const data = list.data;
        if (data.success) {
          console.log(data.items);
          setBuyGemsList(data.items);
        } else {
          setBuyGemsList([]);
        }
      }
    }
  }
  const handleClick = () => {
    console.log("hey");
    ShowRewardedAd();

  };
  useEffect(() => {
    (async () => {
      getBuyCoinList();
      // let temp = [];
      // for (let i = 0; i < 10; i++) {
      //   temp.push({
      //     amount: 200,
      //     price: 5,
      //   })
      // }
      // setOffersData(temp);
      if (user?.userDeatils?._id) {
        const userDeatils = await userServices.getUserDetails(
          user?.userDeatils?._id
        );
        const wallet = userDeatils.wallet[0];
        let userWalletTotal = userServices.getWalletTotalAmount(wallet);
        setTotalBalance(userWalletTotal);
      }
    })();
  }, [user]);
  const onBuyGames = async (value) => {
    navigate('/payment-option', { state: value });
  }
  async function orderCreation(item_sku, amount, coin, type, code) {
    // if (checkDobNstateConditions(dobNstateScreenNameBuyItem)) {
    //   setTagShowDobNstate(true);
    // } else {
    // setShowLoader(true);
    let countryObj = JSON.parse(localStorage.getItem("userCountryObj"));
    let payload = {
      sku: item_sku,
      userCurrency: code,
      // amount: parseInt(amount),
      amount: amount,
      type: type,
    };

    let data = await buyCoinsXsollaAPI.buyXsollaCoin(payload);
    console.log("RESPONSE::::::::", data)
    data = data.data
    if (data?.success) {
      localStorage.setItem('CPAmount', amount)
      localStorage.setItem('sku', item_sku)
      localStorage.setItem('CPCoin', coin)
      let transactionUniqueID = data?.data?.transactionUniqueID;
      let body = {
        transactionUniqueID: transactionUniqueID,
        coin: coin,
        sku: item_sku,
        // amount: parseInt(amount),
        amount: amount,
      };
      localStorage.setItem("body", JSON.stringify(body));
      let url = `https://secure.xsolla.com/paystation4/?token=${data?.data?.token}`;
      window.location.href = url;
    }
    // }
  }
  // async function onPurchaseGems(invoiceId, status, foreignInvoice, CPAmount, user_id, sku, CPCoin) {

  //   let gemPurchaseData = localStorage.getItem("body");
  //   let payload = {
  //     "transactionUniqueID": foreignInvoice,
  //     "coin": CPCoin,
  //     "sku": sku,
  //     "amount": CPAmount,
  //     "pgData": {
  //       "status": status,
  //       "invoice_id": invoiceId
  //     }
  //   }

  //   // console.log(">>4")
  //   let data = await buyCoinsXsollaAPI.buyXsollaCoinUpdate(payload);
  //   // console.log("RESPONSE::::::::",data)
  //   data = data.data
  //   if (data?.success) {
  //     console.log(">>5")
  //     toast.success(data?.message);
  //     console.log("UPDATE SUCCESS:::DONE", data)
  //     setTimeout(() => {
  //       navigate('/home');
  //     }, 3000);

  //     //   let transactionUniqueID = data?.data?.transactionUniqueID;
  //     //   let body = {
  //     //     transactionUniqueID: transactionUniqueID,
  //     //     coin: coin,
  //     //     sku: item_sku,
  //     //     // amount: parseInt(amount),
  //     //     amount: amount,
  //     //   };
  //     //   localStorage.setItem("body", JSON.stringify(body));
  //     //   //setToken(data?.data?.token);
  //     //  // setShowLoader(false);
  //     //   // if (checkDobNstateConditions(dobNstateScreenNameBuyItem)) {
  //     //   //   setTagShowDobNstate(true);
  //     //   // } else {

  //     //   let url = `https://sandbox-secure.xsolla.com/paystation4/?token=${data?.data?.token}`;
  //     //  // console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^URL::",url)
  //     //   window.location.href = url;
  //     // navigate('/payment-option', {state: value});
  //     //   // }
  //   } else {
  //     toast.error(data?.message);
  //   }
  // }



  // const onBuyGames = async (value) => {
  //   // console.log(value, 'hi', user);
  //   let params = {
  //     amount: value.amount.toFixed(2),
  //     name: user.userDeatils.name,
  //     phone: user.userDeatils.phone,
  //     coinStore: value._id,
  //     gemsAmount: value.coins,
  //   };
  //   const res = await buycoinsApi.buyCoin(params);
  //   if (res.error) {
  //     const error = res.error.response.data.data[0];
  //     toast.error(error.msg);
  //   } else {
  //     console.log(res);
  //     // window.location.href = 'https://testpay.easebuzz.in/' + res.data.data.accessToken
  //     var options = {
  //       access_key: res.data.data.accessToken, // access key received via Initiate Payment
  //       onResponse: (response) => {
  //         // console.log(response);
  //         let paymentStatus = "";
  //         if (response.status === "success") {
  //           paymentStatus = "SUCCESS";
  //           toast.success("Your payment was successfully.");
  //         } else if (response.status === "failure") {
  //           paymentStatus = "FAILED";
  //           toast.error("We couldn`t process your payment.");
  //         } else if (response.status === "userCancelled") {
  //           paymentStatus = "USER_CANCELLED";
  //           toast.error("You have cancelled your payment process.");
  //         } else {
  //           paymentStatus = response.status.toUpperCase();
  //           toast.error("Oh no, your payment failed");
  //         }
  //         // PaymentResponse, payment status, database data, coin data
  //         onPurchaseGems(response, paymentStatus, res.data, value);
  //       },
  //       theme: "#123456",
  //     };
  //     console.log(easebuzzCheckout);
  //     easebuzzCheckout.initiatePayment(options);
  //   }
  // };
  // useEffect(() => {
  //   console.log(searchParams.get("txnid"))
  //   if(searchParams.get("txnid")){
  //     const params = {};
  //     for(let entry of searchParams.entries()) {
  //       // console.log(entry[1])
  //       params[entry[0]] = entry[1]
  //       // params.push(entry);
  //     }
  //     onPurchaseGems(params);
  //   }
  // }, [searchParams])
  // const onPurchaseGems = async (paymentRes, paymentStatus, data, values) => {
  //   // console.log(paymentRes)
  //   // console.log(paymentStatus)
  //   // console.log(data)
  //   let params = {
  //     transactionId: data.data.transactionId,
  //     pgData: paymentRes,
  //     paymentStatus: paymentStatus,
  //     paymentMode: paymentRes.card_type,
  //     paymentRefID: paymentRes.easepayid,
  //     gemsAmount: values.coins,
  //     coinStore: values._id,
  //   };
  //   await buycoinsApi.purchasedCoin(params);
  // };
  // const onPurchaseGems = async (pgData) => {
  //   // console.log(paymentRes)
  //   // console.log(paymentStatus)
  //   let paymentStatus = "";
  //   console.log(pgData)
  //   if (pgData.status === "success") {
  //     paymentStatus = "SUCCESS";
  //     toast.success("Your payment was successfully.");
  //   } else if (pgData.status === "failure") {
  //     paymentStatus = "FAILED";
  //     toast.error("We couldn`t process your payment.");
  //   } else if (pgData.status === "userCancelled") {
  //     paymentStatus = "USER_CANCELLED";
  //     toast.error("You have cancelled your payment process.");
  //   } else {
  //     paymentStatus = pgData.status.toUpperCase();
  //     toast.error("Oh no, your payment failed");
  //   }
  //   let params = {
  //     transactionId: pgData.txnid,
  //     pgData: pgData,
  //     paymentStatus: paymentStatus,
  //     paymentMode: pgData.card_type,
  //     paymentRefID: pgData.easepayid,
  //     gemsAmount: Number(pgData.udf1),
  //     coinStore: pgData.udf2,
  //   };
  //   navigate('/buy-gems')

  //   await buycoinsApi.purchasedCoin(params);
  // };
  return (
    <div className="buy_gems_main_component">
      <ToastContainer />

      <div className="buy_gems_header">
        <Link onClick={() => navigate(-1)}>
          <img className="buy_gems_back_icon" src={BackImgIcon} />
        </Link>
      </div>
      {isShowLoader ?
        <div className="main-content registration-main">
          <Oval
            height={50}
            width={50}
            color="#fff"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#ffffffd1"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
        :
        <div className="buy_gems_body">
          <div className="buy_gems_title_component">
            <div className="buy_gems_title">buy gems</div>
            <div className="buy_gems_user_balance">
              <img className="diamond_icon" src={DiamondImgIcon} /> {totalBalance}
            </div>
          </div>

          <div className="buy_gems_offers_component">
            {buyGemsList.map((value, i) => {
              return (
                <div className="buy_gems_offer" key={i}>
                  <div className="buy_gems_offer_gems">
                    <img className="diamond_icon" src={DiamondImgIcon} />{" "}
                    {value?.description}
                  </div>
                  <button
                    className="buy_gems_offer_price"
                    // onClick={() => onBuyGames(value)}
                    onClick={() => orderCreation(value?.sku, value?.price?.amount, value?.description, 1, value?.price?.country_iso)}


                  >
                    {getSymbolFromCurrency(countryToCurrency[value?.price?.country_iso])} {value?.price?.amount}
                  </button>
                </div>
              );
            })}

            {/* <div className="buy_gems_offer">
            <div className="buy_gems_offer_gems">
              <img className="diamond_icon" src={DiamondImgIcon} /> 101
            </div>
            <button className="buy_gems_offer_price" onClick={handleClick}>
              Watch AD
            </button>
          </div> */}
          </div>
        </div>
      }
    </div>
  );
}
