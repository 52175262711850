import { Outlet, useLocation } from "react-router-dom";
import NavigationSection from "../component/NavigationSection";
import PageHeader from "../component/PageHeader";
import React, { useState } from "react";
import { UserAdDisplay } from "../common/UserAdDisplay";

export default function ListLayout() {
  const [title, setTitle] = useState("");
  const [faqGameId, setFaqGameId] = useState("");
  const [image, setImage] = useState("");
  const location = useLocation();
  

  return (
    <>
      <PageHeader
        title={title}
        faqGameId={faqGameId}
        module={location.pathname}
        image={image}
      />
      {location.pathname !== "/gamefaq" ||
        (location.pathname !== "/leaderboard" && <NavigationSection />)}
      <div className="main-content  reward-main my-reward-main">
        <Outlet context={{ setTitle, setFaqGameId, setImage }} />
   
      </div>


    </>
  );
}