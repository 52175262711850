import closeBtnIcon from "../assets/images/close-btn.svg"

const ExitPopup = (props) => {
    return (
        <section className="exit-popup">
            {/* <a className="close-btn"><img src={closeBtnIcon} alt="" onClick={()=>props.onClickCancelIcon(false)}/></a> */}
            <div className="container-fluid">
                <h2>are you sure <br />you want to leave?</h2>
                <div className="d-grid mt-4 mb-3 pb-1">
                    <a  onClick={()=>props.onClickCancelIcon(false)} className="btn btn-danger btn-lg">Continue Playing</a>
                </div>
                <div className="text-center">
                    <a onClick={()=>props.onClickExitApp()} className="fw-medium text-decoration-underline text-silver">exit now</a>
                </div>
            </div>
        </section>
    )

}
export default ExitPopup