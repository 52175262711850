 import { useEffect } from "react";
 
 export const UserAdDisplay = (id) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.innerHTML = `adserver.display('${id}')`;

   

    document.getElementById(id).appendChild(script);
    console.log(document.getElementById(id).children)



    
  }, [id]);
};
