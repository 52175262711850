import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";

function firstTimePlaying() {
  const firsttime = async (data) => {
    return await resolve(
      axios({
        method: "post",
        url: helper.saasApiUrl()+ "join/firsttime",
        responseType: "json",
        headers: helper.headers(),
        data: data,
      }).then((response) => response.data)
    );
  };
  const howToJoin = async (data) => {
    console.log("data",data)
    return await resolve(
      axios({
        method: 'post',
        url: helper.viApiUrl()+ "howtojoin/details",
        responseType: "json",
        headers: helper.headers(),
        data: data,
      }).then((response) => response.data)
    );
  };
  return {
    firsttime,
    howToJoin
  };
}
const firstTimePlayingApi = firstTimePlaying();
export default firstTimePlayingApi;
