import Index from "../view/buyCoin/Index"
import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import faqApi from "../api/faq";
import buycoinsApi from "../api/buycoins";

function BuyCoinController() {
    const { setTitle } = useOutletContext();
    const [buyCoinList, setBuyCoinList] = useState([])


    useEffect(() => {
        (async () => {
            setTitle('')
            await getBuyCoinList()
        })();
    }, []);

   
    async function getBuyCoinList() {
        const list = await buycoinsApi.list()
        if (list.error) {
            const error = list.error
            console.log('error', error.message)
        } else {
            if (list.data) {
                const data = list.data
                if (data.success) {
                    console.log(data.data)
                    setBuyCoinList(data.data)
                } else {
                    setBuyCoinList([])

                }
            }
        }

    }



    return (
        <Index
        buyCoinList={buyCoinList}
    />
    )

}
export default BuyCoinController