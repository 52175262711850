import registrationAnimation from "../assets/images/registration-animation.png"
import ControllerPng from "../assets/images/Controller_1.png"
import { Link, useParams, useNavigate, useLocation } from "react-router-dom"
import { useEffect } from "react"

const RegistrationDone = () => {
    const perems = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        // console.log('ddd')

        // let intervalId = setInterval(navigate('/afterjoin/' + perems?.contestId, { state: { module: 'registration_done' } }),3000)

        let myInterval = setInterval(function () {

            navigate('/afterjoin/' + perems?.contestId, { state: { module: 'registration_done' } })
        }, 3000);

        return (() => {
            clearInterval(myInterval)
        })
    }, [])




    // 

    // let myInterval = setInterval(function () {
    //     if (location.pathname !== '/registration/done/6337284db9fa6f6401d5a4c8') {
    //         clearInterval(myInterval);
    //     }
    //     navigate('/afterjoin/' + perems?.contestId, { state: { module: 'registration_done' } })
    // }, 3000);

    return (
        <div className="main-content registration-main">
            <img src={ControllerPng} alt="" className="mb-4"/>
            <h2 className="text-white">Wohoo!<br /> registration is done!<br /> now go kill it! </h2>
        </div>
    )

}
export default RegistrationDone