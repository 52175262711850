import MyContest from "../view/contest/MyContest"
import React, { useEffect, useState, useRef } from "react"
import { useOutletContext, useParams } from "react-router-dom";
import contestApi from "../api/contest";
import { useSelector, useDispatch } from "react-redux";


function MyContestController() {
    const perems = useParams()
    const { setTitle } = useOutletContext();
    const [currentOption, setCurrentOption] = useState('all')
    const user = useSelector((state) => state.user);
    const [contestList, setContestList] = useState([])


    const listInnerRef = useRef();
    const [currPage, setCurrPage] = useState(1);
    const [prevPage, setPrevPage] = useState(0);
    const [lastList, setLastList] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setTitle('my contest')
    }, []);



    useEffect(() => {
        (async () => {
            if (user?.userDeatils?._id) {
                if (!lastList && prevPage !== currPage) {
                    await getContestList(currentOption, user?.userDeatils?.user_id, perems.gameId)
                }

            }

        })()
    }, [user, perems.gameId, currPage, lastList, prevPage, contestList])

    useEffect(() => {
        (async () => {
            if (user?.userDeatils?.user_id) {
                setCurrPage(1);
                setPrevPage(0);
                setLastList(false);
                setContestList([])
                // fetchData();
                await getContestList(currentOption, user?.userDeatils?.user_id, perems.gameId);
            }

        })()
        
    }, [currentOption])

    async function getContestList(status, userId, gameId) {
        setIsLoading(true)
        let data = {}
        data.search = {}
        data.search.user = userId
        if (gameId != null)
            data.search.game = gameId
        //if (status !== 'all') {

            if(status == 'upcoming'){
                data.search.status = ['waiting','started']
            }else{
                data.search.status = status
            }
            
       // }
        
        // data.pagination = {}
        // data.pagination.pageNo = currPage
        // data.pagination.recordPerPage = 5
        data.pagination = {
            pageNo: currPage,
            recordPerPage: 10,
            sortBy: "createdAt",
            sortDirection: "desc"
        }

        const list = await contestApi.listByUser(data)
        if (list.error) {
            const error = list.error
        setIsLoading(false)

        } else {
            setIsLoading(false)

            if (list.data) {
                const data = list.data
                if (data.success) {
                    if (!data.data.length) {

                        setLastList(true);
                        return;
                    }
                    // // console.log(data.data)
                    // data.data.sort(function(a,b){
                    //     // Turn your strings into dates, and then subtract them
                    //     // to get a value that is either negative, positive, or zero.
                    //     return new Date(b.contestData.createdAt) - new Date(a.contestData.createdAt);
                    //   });

                    setPrevPage(currPage);

                    if(currPage === 1) {
                        setContestList(data.data)
                    } else {
                        let tempData = contestList.concat(data.data)
                        setContestList([...tempData])
                    }
                    // setContestList(data.data)

                    // setContestList([...contestList, ...data.data])

                }
            }
        }

    }

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                setCurrPage(currPage + 1);
            }
        }
    };


    return (
        <MyContest
            currentOption={currentOption}
            setCurrentOption={setCurrentOption}
            contestList={contestList}
            setContestList={setContestList}
            onScroll={onScroll}
            listInnerRef={listInnerRef}
            isLoading ={isLoading}
        />
    )

}
export default MyContestController