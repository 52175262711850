import ESportsFaq from "../view/faq/ESportsFaq";
import { useOutletContext, useParams } from "react-router-dom";
import React, { useEffect,useState } from "react"
import faqApi from "../api/faq"


function ESportsFaqController() {
    const { setTitle } = useOutletContext();
    const perems = useParams()
    const [faqList, setFaqList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setTitle('check this out before you play')
    }, []);

    useEffect(() => {
        (async () => {
            await getFaqList()
        })();
    }, [perems]);

    async function getFaqList(title = '') {
        let data = {}
        data.search = {}
        data.search.isActive = true
        data.pagination = {}

        // data.pagination.pageNo = currPage
        data.pagination.recordPerPage = 100
        data.pagination.sortBy = "order"
        data.pagination.sortDirection = "asc"
        const list = await faqApi.list(title, data)
        if (list.error) {
            const error = list.error
            console.log('error', error.message)
        } else {
            setIsLoading(false)
            if (list.data) {
                const data = list.data
                if (data.success) {
                    setFaqList(data.data)
                } else {
                    setFaqList([])

                }
            }
        }
    }
    return (
        <>
            <ESportsFaq 
             faqList={faqList}
             isLoaderShow ={isLoading}
            />
        </>
    )

}
export default ESportsFaqController