import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import expand from "../../assets/images/openframe.png";
import calendarIcon from "../../assets/images/calendar-icon.svg";
import demo from "../../assets/images/home-banner-bg.png";
import firstTimePlayingApi from "../../api/firstTimePlaying";
import rupeeIcon from "../../assets/images/rupee-icon.svg";
import moment from "moment";
import { ImagePopup } from "./ImagePopup";
import helper from "../../common/Helper";

export const NotPlayedGame = (contestDetails) => {
  const user = useSelector((state) => state.user);
  const [howtojoindata, sethowtojoinData] = useState("");
  console.log(contestDetails?.contestDetails?.entryFee);
  const details = contestDetails?.contestDetails;
  const [expandimage, setExpandimage] = useState(false);

  console.log(details?.entryFee);

  async function howToJoin() {
 
    let data = {
      type: "2",
      game: "634a42a89633f3f5b7228455",
    };
    console.log(data);
    const HowtoJoin = await firstTimePlayingApi.howToJoin(JSON.stringify(data));
    console.log(data);
    console.log(HowtoJoin?.data?.data);
    sethowtojoinData(HowtoJoin?.data?.data);
    // console.log(howtojoindata.images)
  }
  const [selectedImage, setSelectedImage] = useState(null);
  const expandImage = (index) => {
    setExpandimage(true);
    setSelectedImage(index);
  };
  useEffect(() => {
    howToJoin();
  }, []);
  return (
    <div className="joinGame-content height100">
      <div className="gameContent-header">
        <h2 className="gameName">{user.userCurrentGameName}</h2>
        <div className="d-flex justify-content-center">
          <div className="d-flex align-items-center">
            <img className="calendar-icon" src={calendarIcon} alt="" />
            <p className="ms-1 mb-0 h3 text-silver">
              {moment(details?.dateTime).format("DD MMM YY, hh:mmA")}
            </p>{" "}
          </div>
          <span className="vertical-line"></span>
          <div className="d-flex align-items-center">
            <img className="rupee-icon" src={rupeeIcon} alt="" />
            <p className="ms-1 mb-0 h3 text-silver">
              {details?.entryFee == 0 ? "free" : details?.entryFee}
            </p>
          </div>
        </div>

        {/* <h3>{props.name}</h3> */}
      </div>
      <div className="gameContent-body pt-0">
        <div className="flex column-flex gap">
          {" "}
          <div className="text-center fontw-7">{howtojoindata.title}</div>
          {howtojoindata &&
            howtojoindata.images &&
            howtojoindata.images.map((detail, index) => (
              <div key={detail.label}>
                <p>{detail.label}</p>
                <div className="expand-image">
                  <img
                    className=""
                    src={helper.imageBaseUrl + detail.thumbImage}
                    alt=""
                    width="100%"
                    height="180px"
                  />
                  <button
                    className="expand-image-img"
                    onClick={() => expandImage(index)}
                  >
                    <img src={expand} alt="expand" />
                  </button>
                </div>
              </div>
            ))}
          {expandimage && (
            <ImagePopup
              setExpandimage={setExpandimage}
              imageUrl={
                helper.imageBaseUrl +
                howtojoindata.images[selectedImage].thumbImage
              }
            />
          )}
          <div>
            {" "}
            <div className="d-grid my-4">
              <input
                // disabled={disabled ? true : false}
                type="text"
                className="form-control userName"
                // value={props.name}
                // onBlur={() => setFocus(false)}
                // onFocus={() => setFocus(true)}
                id="floatingInput"
                name="bgmiName"
                placeholder="Enter and save your supercell ID"
                // onChange={(e) => props.onChangeEditedName(e)}
              />
            </div>
          </div>
          <div>
            <p className="h4 text-center margin-top5">
              By joining this contest/tournament, you accept the Gamerji’s{" "}
              <br />
              <Link to="/terms-and-conditions">
                <a className="fw-bold text-decoration-underline text-silver">
                  {" "}
                  terms & conditions
                </a>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
