import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";

function buycoins() {
    const list = async () => {
        let url = helper.saasApiUrl() + 'coinstore/list'
               return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers()
            })
                .then(response => response.data)
        )

    }
    const buyCoin = async (data) => {
        let url = helper.viApiUrl() + 'coin/buy'
        return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(),
                data: data
            })
            .then(response => response.data)
        )

    }
    const purchasedCoin = async (data) => {
        let url = helper.viApiUrl() + 'coin/purchased'
        return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(),
                data: data
            })
            .then(response => response.data)
        )

    }
    return {
        list,
        buyCoin,
        purchasedCoin
    }
}
const buycoinsApi = buycoins();
export default buycoinsApi;