import calendarIcon from "../../assets/images/calendar-icon.svg";
import liveCircleIcon from "../../assets/images/live-circle.svg";
import copyIcon from "../../assets/images/copy-icon.svg";
import ContestCard from "./ContestCard";
import { useEffect, useState } from "react";
import { UserAdDisplay } from "../../common/UserAdDisplay";
import { Audio, Oval } from "react-loader-spinner";
// import { useAdVisibility } from "../../common/UseAdVisibility";
const MyContest = (props) => {
  const contestListData = props.contestList;
  const [contestList, setContestList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const isLoadingData = props.isLoading;

  {
    props.currentOption === "all" || props.currentOption === "upcoming" ? (
      UserAdDisplay("Gamerji_MyContest_320x50")
    ) : (
      <></>
    );
  }

  {
    props.currentOption === "completed" ? (
      UserAdDisplay("Gamerji_MyContest_Complete_300x250")
    ) : (
      <></>
    );
  }

  // ad logic

  const [mycontestAd, setmycontestAd] = useState(0);

  const [isVisible2, setIsVisible2] = useState(false);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedCount = localStorage.getItem("mycontestAd");
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedTimestamp && currentTime > storedTimestamp) {
      localStorage.setItem("mycontestAd", 0);
      localStorage.setItem("timestamp", new Date());
      setmycontestAd(0);
    } else {
      let newCount = Number(storedCount) + 1;

      // let date = new Date(new Date().getTime() + 15 * 1000);
      localStorage.setItem("mycontestAd", newCount);
      // localStorage.setItem("timestamp", moment().format("DD-MM-YYYY"));
    }
    setTimeout(() => {
      // const storedCount = localStorage.getItem("mycontestAd");
      const storedCount = localStorage.getItem("count");
      let newCount = Number(storedCount);
      setmycontestAd(newCount);
    }, 2000);
  }, []);

  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   if (mycontestAd > 0 && mycontestAd % 3 === 0) {
    //     setIsVisible2(true);
    //   } else {
    //     setIsVisible2(false);
    //   }
    // }, 10);

    // return () => clearTimeout(timeout);
    if (mycontestAd >= 3) {
      setIsVisible2(true);
    } else {
      setIsVisible2(false);
    }
  }, [mycontestAd]);

  useEffect(() => {
    setContestList(contestListData);
  }, [contestListData]);

  useEffect(() => {
    setLoading(isLoadingData);
  }, [isLoadingData]);

  return (
    <section className="all-contest">
      {props.currentOption === "all" || props.currentOption === "upcoming" ? (
        <div
          className="container-fluid"
          onScroll={props.onScroll}
          ref={props.listInnerRef}
          style={{
            height: "90vh",
            overflowY: "auto",
            // height: isVisible2 ? "62vh" : "80vh",
          }}
        >
          <div className="contestFilter mb-3">
            <span
              data-filter="*"
              className={
                props.currentOption === "all"
                  ? "btn btn-outline-light px-3 me-2 active"
                  : "btn btn-outline-light px-3 me-2"
              }
              onClick={() => {
                props.setCurrentOption("all");
              }}
            >
              all
            </span>
            <span
              data-filter=".upcoming"
              className={
                props.currentOption === "upcoming"
                  ? "btn btn-outline-light px-3 me-2 active"
                  : "btn btn-outline-light px-3 me-2"
              }
              onClick={() => props.setCurrentOption("upcoming")}
            >
              upcoming
            </span>
            <span
              data-filter=".completed"
              className={
                props.currentOption === "completed"
                  ? "btn btn-outline-light px-3 me-2 active"
                  : "btn btn-outline-light px-3 me-2"
              }
              onClick={() => props.setCurrentOption("completed")}
            >
              completed
            </span>
          </div>
          <div className="grid y">
            {contestList &&
            contestList?.length > 0 &&
            props.currentOption === "all1" ? (
              contestList[0]?.length === 0 &&
              contestList[1]?.length === 0 &&
              contestList[2]?.length === 0 ? (
                <>
                  <div className="main-content registration-main">
                    {!isLoading ? (
                      <h2 className="text-white m-0">No Contest Found</h2>
                    ) : (
                      <div className="">
                        <div className="spinner"></div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                contestList.map((contestData, key1) => {
                  return (
                    contestData.length > 0 &&
                    contestData.map((contest, key) => {
                      return (
                        <div>
                          <ContestCard key={key} {...contest} />
                        </div>
                      );
                    })
                  );
                })
              )
            ) : contestList?.length > 0 &&
              contestList[0]?.length === undefined ? (
              contestList?.map((contest1, key2) => {
                // console.log("wweqweqw", props.currentOption);
                return (
                  <>
                    <ContestCard key={key2} {...contest1} />
                  </>

                  // <h2 className="text-white m-0"> Contest Found</h2>
                );
              })
            ) : (
              // <h2 className="text-white m-0"> Contest Found</h2>

              <>
                <div className="main-content registration-main">
                  {!isLoading ? (
                    <h2 className="text-white m-0">No Contest Found</h2>
                  ) : (
                    <div className="main-content registration-main">
                      <Oval
                        height={50}
                        width={50}
                        color="#fff"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffffd1"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      {props.currentOption === "completed" ? (
        <div
          className="container-fluid"
          onScroll={props.onScroll}
          ref={props.listInnerRef}
          style={{
            // height: "80vh",
            overflowY: "auto",
            height: isVisible2 ? "62vh" : "",
          }}
        >
          <div className="contestFilter mb-3">
            <span
              data-filter="*"
              className={
                props.currentOption === "all"
                  ? "btn btn-outline-light px-3 me-2 active"
                  : "btn btn-outline-light px-3 me-2"
              }
              onClick={() => {
                props.setCurrentOption("all");
              }}
            >
              all
            </span>
            <span
              data-filter=".upcoming"
              className={
                props.currentOption === "upcoming"
                  ? "btn btn-outline-light px-3 me-2 active"
                  : "btn btn-outline-light px-3 me-2"
              }
              onClick={() => props.setCurrentOption("upcoming")}
            >
              upcoming
            </span>
            <span
              data-filter=".completed"
              className={
                props.currentOption === "completed"
                  ? "btn btn-outline-light px-3 me-2 active"
                  : "btn btn-outline-light px-3 me-2"
              }
              onClick={() => props.setCurrentOption("completed")}
            >
              completed
            </span>
          </div>
          <div className="grid ">
            {contestList &&
            contestList?.length > 0 &&
            props.currentOption === "all1" ? (
              contestList[0]?.length === 0 &&
              contestList[1]?.length === 0 &&
              contestList[2]?.length === 0 ? (
                <>
                  <div className="main-content registration-main">
                    {!isLoading ? (
                      <h2 className="text-white m-0">No Contest Found</h2>
                    ) : (
                      <div className="">
                        <div className="spinner"></div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                contestList.map((contestData, key1) => {
                  return (
                    contestData.length > 0 &&
                    contestData.map((contest, key) => {
                      return (
                        <div>
                          <ContestCard key={key} {...contest} />
                        </div>
                      );
                    })
                  );
                })
              )
            ) : contestList?.length > 0 &&
              contestList[0]?.length === undefined ? (
              contestList?.map((contest1, key2) => {
                // console.log("wweqweqw", props.currentOption);
                return (
                  <>
                    <ContestCard key={key2} {...contest1} />
                  </>

                  // <h2 className="text-white m-0"> Contest Found</h2>
                );
              })
            ) : (
              // <h2 className="text-white m-0"> Contest Found</h2>

              <>
                <div className="main-content registration-main">
                  {!isLoading ? (
                    <h2 className="text-white m-0">No Contest Found</h2>
                  ) : (
                    <div className="main-content registration-main">
                      <Oval
                        height={50}
                        width={50}
                        color="#fff"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffffd1"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      {props.currentOption === "all" || props.currentOption === "upcoming" ? (
        <div
          id="Gamerji_MyContest_320x50"
          style={{
            display: isVisible2 ? "block" : "none",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
            bottom: 0,
            left: 0,
            right: 0,
            color: "white",
          }}
        ></div>
      ) : (
        ""
      )}

      {props.currentOption === "completed" ? (
        <div
          id="Gamerji_MyContest_Complete_300x250"
          style={{
            display: isVisible2 ? "block" : "none",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
            bottom: 0,
            left: 0,
            right: 0,
            color: "white",
          }}
        ></div>
      ) : (
        ""
      )}
    </section>
  );
};
export default MyContest;
