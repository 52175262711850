import React, { useEffect, useState } from "react"
import { Link, useOutletContext, useParams, useNavigate } from "react-router-dom";
import contestApi from "../api/contest";
import List from "../view/contest/List";
import { useSelector, useDispatch } from "react-redux";
import BackImgIcon from "../assets/images/back-arrow.svg";
import gameApi from "../api/game";
import helper from "../common/Helper";
import { setCurrentGameName } from "../redux/user/user.action";
import clevertap from 'clevertap-web-sdk'
import { UserAdDisplay } from "../common/UserAdDisplay";


function ContestController() {
    const { setTitle, setFaqGameId, setImage } = useOutletContext();
    const [contestList, setContestList] = useState([])
    const [gameId, setGameId] = useState('')
    const perems = useParams()
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isLoader, setIsLoading] = useState(true);
    clevertap.privacy.push({optOut: false}) // Set the flag to true, if the user of the device opts out of sharing their data
    clevertap.privacy.push({useIP: true})  // Set the flag to true, if the user agrees to share their IP data
    clevertap.init(helper.cleverTapAccount, 'in') // 
    clevertap.setLogLevel(3) // 3 = all logs
    /** */
    /*const [isLoader, setIsLoading] = useState(true);
    getContestList -> else -> setIsLoading(false);
    List -> isLoaderShow={isLoader}*/
    // console.log("perems.gameId", perems.gameId);
    useEffect(() => {
        console.log()
        setTitle(user.userCurrentGameName)
        setTitle(user.userCurrentGameName)
        //  console.log(user)
    }, [user]);

    useEffect(() => {
        (async () => {
            if (perems.gameId) {
                setFaqGameId(perems.gameId)

               // await getGameById(perems.gameId)
                await getContestList(perems.gameId)
                setGameId(perems.gameId)
            }
        })();
    }, [perems.gameId]);



    async function getGameById(gameId) {

        const game = await gameApi.getGameById(gameId)
        if (game.error) {
            const error = game.error
            // console.log('error', error.message)
            navigate('/')

        } else {
            // console.log('ggggggggg',game)

            if (game.data) {
               
                const data = game.data
                /*clevertap.event.push("esports_event", {
                    "Product name":  "FreeFire Max",
                    "identity": user?.userDeatils?.phone,
                    "Date": new Date()
                });*/
                if (data.success) {
                    console.log(data.data.displayImage, '--------------');
                    setImage(data.data.displayImage)
                    if (data.data.name && data.data.name !== null && data.data.name !== '') {
                        dispatch(setCurrentGameName(data.data.name))
                    } else {
                        navigate('/')
                    }

                }
            }
        }

    }

    async function getContestList(gameId) {
        var payload = {
            search: {
                game: gameId,
                user: user?.userDeatils?.user_id
            },
            pagination: {
                pageNo: 1,
                recordPerPage: 10,
                sortBy: "created_at",
                sortDirection: "asc"
            }
        }

        const list = await contestApi.contestlistbygame(payload)
        if (list.error) {
            const error = list.error
            console.log('error', error.message)
        } else {
            setIsLoading(false)
            if (list.data) {
                const data = list.data
                if (data.success) {
                    console.log("data",data.displayImage,data.displayImage) /////kuldeep setcontest data
                    setContestList(data.data)
                    setImage(data.displayImage)
                    if (data.gamename && data.gamename !== null && data.gamename !== '') {
                        dispatch(setCurrentGameName(data.gamename))
                    } else {
                       
                    }
                }
            }
        }

    }


    return (
        <>
            {/* <div className="customer_care_header">
        <Link to={'/myaccount'}>
          <img className="customer_care_back_icon" src={BackImgIcon} />
        </Link>
        <div className="customer_care_header_title">customer care</div>
      </div> */}
            <List
                contestList={contestList}
                gameId={perems.gameId}
                isLoaderShow={isLoader}
            />
          
             </>

           

    )

}
export default ContestController