import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import calendarIcon from "../../assets/images/calendar-icon.svg";
import demo from "../../assets/images/home-banner-bg.png";
import backArrowImg from "../../assets/images/back-arrow.svg";
import expand from "../../assets/images/openframe.png";
import firstTimePlayingApi from "../../api/firstTimePlaying";
import helper from "../../common/Helper";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

import rupeeIcon from "../../assets/images/rupee-icon.svg";
import moment from "moment";
import { ImagePopup } from "./ImagePopup";

export const PlayedGameBefore = (contestDetails) => {
  const user = useSelector((state) => state.user);

  const [inputValue, setInputValue] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [expandimage, setExpandimage] = useState(false);
  const [howtojoindata, sethowtojoinData] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(null);
  const expandImage = (index) => {
    setExpandimage(true);
    setSelectedImage(index);
  };

  const handleConfirm = () => {
    setConfirmed(true);
  };

  async function howToJoin() {
    let data = {
      type: "1",
      game: "634a42a89633f3f5b7228455",
    };
    console.log(data);
    const HowtoJoin = await firstTimePlayingApi.howToJoin(JSON.stringify(data));
    console.log(data);
    console.log(HowtoJoin?.data?.data);
    sethowtojoinData(HowtoJoin?.data?.data);
    // console.log(howtojoindata.images)
  }

  useEffect(() => {
    howToJoin();
  }, []);

  console.log(contestDetails?.contestDetails?.entryFee);
  const details = contestDetails?.contestDetails;
  
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    // Show/hide the popup based on input value
    setShowPopup(value.trim() !== "");
  };

  console.log(details?.entryFee);
  return (
    <div className="joinGame-content height100 ">
      <header className="position-fixed">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 d-flex align-items-center">
              <div
                onClick={() => navigate(-1)}
                className="backButtonPadding backButtonAddPadding"
              >
                <img src={backArrowImg} alt="" />
              </div>
              <h1 className="mb-0 ms-3 text-white h2 fw-normal"></h1>
            </div>
          </div>
        </div>
      </header>

      <div className={`gameContent-body pt-0 ${showPopup ? "blur" : ""}`}>
        <div className="flex column-flex gap">
          <div className="gameContent-header">
            <h2 className="gameName">{user.userCurrentGameName}</h2>
            <div className="d-flex justify-content-center">
              <div className="d-flex align-items-center">
                <img className="calendar-icon" src={calendarIcon} alt="" />
                <p className="ms-1 mb-0 h3 text-silver">
                  {moment(details?.dateTime).format("DD MMM YY, hh:mmA")}
                </p>{" "}
              </div>
              <span className="vertical-line"></span>
              <div className="d-flex align-items-center">
                <img className="rupee-icon" src={rupeeIcon} alt="" />
                <p className="ms-1 mb-0 h3 text-silver">
                  {details?.entryFee == 0 ? "free" : details?.entryFee}
                </p>
              </div>
            </div>

            {/* <h3>{props.name}</h3> */}
          </div>{" "}
          <div className="text-center fontw-7 ">{howtojoindata.title}</div>
          {howtojoindata &&
            howtojoindata.images &&
            howtojoindata.images.map((detail,index) => (
              <div key={detail.label}>
                <p>{detail.label}</p>
                <div className="expand-image">
                  <img
                    className=""
                    src={helper.imageBaseUrl + detail.thumbImage}
                    alt=""
                    width="100%"
                    height="180px"
                  />
                  <button
                    className="expand-image-img"
                    onClick={() => expandImage(index)}
                  >
                    <img src={expand} alt="expand" />
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      {expandimage && (
        <ImagePopup
          setExpandimage={setExpandimage}
          imageUrl={
            helper.imageBaseUrl + howtojoindata.images[selectedImage].thumbImage
          }
        />
      )}
      <div>
        {" "}
        {showPopup ? (
          <div className="d-grid my-4">
            {confirmed ? (
              <div className="mt-4 mb-0 setInput">
                <label className="userGmaneName">
                  Enter and save your supercell ID
                </label>
                <input
                  type="text"
                  className="form-control userName"
                  value={inputValue}
                  id="floatingInput"
                  name="bgmiName"
                />
              </div>
            ) : (
              <input
                type="text"
                className="form-control userName"
                onChange={handleInputChange}
                id="floatingInput"
                name="bgmiName"
                placeholder="Enter and save your supercell ID"
              />
            )}

            <div className="d-grid my-4">
              <button
                className={`btn btn-confirm btn-lg ${
                  confirmed ? "confirmed" : ""
                }`}
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        ) : (
          <div className="d-grid my-4">
            <input
              type="text"
              className="form-control userName"
              onChange={handleInputChange}
              id="floatingInput"
              name="bgmiName"
              placeholder="Enter and save your supercell ID"
            />
          </div>
        )}
      </div>
      <div>
        <p className="h4 text-center margin-top5">
          By joining this contest/tournament, you accept the Gamerji’s <br />
          <Link to="/terms-and-conditions">
            <a className="fw-bold text-decoration-underline text-silver">
              {" "}
              terms & conditions
            </a>
          </Link>
        </p>
      </div>
    </div>
  );
};
