import backArrowImg from "../assets/images/back-arrow.svg"
import { Link, useNavigate, useLocation } from "react-router-dom"
import React, {  useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { setOpenExitPopup } from "../redux/user/user.action";
import { useDispatch } from "react-redux";
    
    const MainHeaderSection = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation()
    const navigationRef = React.createRef();
    const webViewRef = useRef(null);
    const onAndroidBackPress = () => {
        if (webViewRef.current) {
            toast("onAndroidBackPress handler");
          webViewRef.current.goBack();
          return true; // prevent default behavior (exit app)
        }
        return false;
      };
     
    const navigateUser = () => {
        
        if (location.pathname === '/home' || location.pathname === '/') {
            const storedCount = localStorage.getItem("count");
            
            localStorage.clear();

            localStorage.setItem("count", storedCount);
            //dispatch(setOpenExitPopup(true))
    
                /**below code is use for back to native app from pwa */
                 let value = JSON.stringify({"tag": "back pressed"});
                 if(window.ReactNativeWebView){
                       
                         window.ReactNativeWebView.postMessage(value);
    
                 }
 
        } else {
            navigate(-1)
        }

    }
    return (
        <header className="position-fixed">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-2">
                        <Link onClick={() => navigateUser()} className="backButtonPadding backButtonAddPadding"><img src={backArrowImg} alt="" /></Link>
                    </div>
                    <div className="col-8">
                        <h1 className="text-center mb-0 text-white">Esports</h1>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </header>
    )

}
export default MainHeaderSection