import Index from "../view/faq/Index";
import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import faqApi from "../api/faq"




function GameFaqController() {
    const { setTitle } = useOutletContext();
    const perems = useParams()
    const [faqList, setFaqList] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setTitle('FAQs')
    }, []);

    useEffect(() => {
        (async () => {
            if (perems?.gameId) {
                await getGameList(perems.gameId)
            }
        })();
    }, [perems]);

    async function getGameList(gameId) {
        setIsLoading(true)
        const list = await faqApi.listByGame(gameId)
        if (list.error) {
            const error = list.error
            console.log('error', error.message)
            setIsLoading(false)
        } else {
            setIsLoading(false)
            if (list.data) {
                const data = list.data
                if (data.success) {
                    // console.log(data.data)
                    setFaqList(data.data)
                } else {
                    setFaqList([])

                }
            }
        }

    }

    return (
        <Index
            faqList={faqList}
            isLoaderShow ={isLoading}
        />
    )

}
export default GameFaqController