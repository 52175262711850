import EditIcon from "../../assets/images/Edit.png";
import gameApi from "../../api/game";
import ingamenameApi from "../../api/ingamename";
import GroupIcon from "../../assets/images/Group.png";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

const Index = (props) => {
  const [gameList, setGameList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [gameId, setGameId] = useState("");

  const user = useSelector((state) => state.user);
  // console.log(user);
  useEffect(() => {
    (async () => {
      await getGameList();
    })();
  }, []);

  async function getGameList() {
    let data = {};
    data.pagination = {};

    data.pagination.pageNo = 1;
    data.pagination.recordPerPage = 200;
    data.pagination.sortBy = "order";
    data.pagination.sortDirection = "asc";

    const list = await gameApi.list(data);
    if (list.error) {
      const error = list.error;
    } else {
      if (list.data) {
        const data = list.data;
        if (data.success) {
          let body = {
            user: user?.userDeatils?.user_id,
          };
          const inGameName = await ingamenameApi.list(body);
          let tempData = [];
          data.data.map((game) => {
            let index = inGameName.data.dataItem.findIndex(
              (e) => e.game === game._id
            );
            if (index === -1) {
              tempData.push({
                name: game.name,
                userINGameName: "",
                _id: game._id,
              });
            } else {
              tempData.push({
                name: game.name,
                userINGameName: inGameName.data.dataItem[index].userINGameName,
                _id: game._id,
              });
            }
          });
          setGameList(tempData);
        }
      }
    }
  }
  const onOpenFieldSet = (value) => {
    if (value._id === gameId) {
      setIsOpen(!isOpen);
      setGameId(value._id);
    } else {
      setIsOpen(true);
      setGameId(value._id);
    }
  };

  const onClickSave = async (index) => {
    var payload = {
      user: user?.userDeatils?.user_id,
      userINGameName: gameList[index].userINGameName,
      //  gamerID: perems?.contestId,
      game: gameList[index]._id,
    };
    // console.log(payload);
    const addName = await ingamenameApi.add(payload);

    if (addName.error) {
      const error = addName.error;
      // console.log(error.response?.data)
      // console.log('error', error?.response?.data?.errors[0].msg);
      toast(error.response?.data?.data[0]?.message);
    } else {
      if (addName.data) {
        // const data = addName.data
        // console.log("data", addName.data);
        if (addName.data.success) {
          setGameId("");
          setIsOpen(false);
          toast('User in game name updated successfully.');
          // callRegisterUser();
        }
      }
    }
  };
  const onChange = (e, index) => {
    e.preventDefault();
    gameList[index].userINGameName = e.target.value;
    setGameList([...gameList]);
  };

  return (
    <div class="my-gamer-content">
      <ToastContainer />
      <section class="my-gamer-id">
        <div class="container-fluid">
          <div class="dropdown">
            <ul class="accordion">
              {gameList.length > 0 &&
                gameList.map((value, index) => {
                  return (
                    <li>
                      <p class="link mb-0">
                        <a
                          href="javascript::void(0)"
                          onClick={(e) => {
                            onOpenFieldSet(value);
                          }}
                        >
                          {value?.name}{" "}
                          {value.userINGameName ? (
                            <p>
                              {" "}
                              {value.userINGameName}{" "}
                              <img src={EditIcon} alt="" id="edit" />{" "}
                            </p>
                          ) : (
                            <img src={GroupIcon} alt="" id="edit" />
                          )}
                        </a>
                      </p>
                      {
                        <ul
                          class="submenu"
                          style={
                            isOpen && value._id === gameId
                              ? { display: "flex" }
                              : { display: "none" }
                          }
                        >
                          <li>
                            <div class="edit">
                              <form>
                                <fieldset class="fieldset">
                                  <legend>edit {value?.name} name</legend>
                                  <div class="legend-in">
                                    <label for="fname"></label>
                                    <input
                                      type="text"
                                      id={"fname_" + value._id}
                                      name={"fname_" + value._id}
                                      value={value.userINGameName}
                                      onChange={(e) => onChange(e, index)}
                                    />
                                    <div class="edit-btn">
                                      <a
                                        href="javascript::void(0)"
                                        onClick={() => onClickSave(index)}
                                      >
                                        save
                                      </a>
                                    </div>
                                  </div>
                                </fieldset>
                              </form>
                              <h1>
                                don’t have a {value?.name} name?{" "}
                                <a href=""> know how to create one</a>
                              </h1>
                            </div>
                          </li>
                        </ul>
                      }
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Index;
