import { useState, useEffect } from "react";
import { UserAdDisplay } from "../../common/UserAdDisplay";
// import { useAdVisibility } from "../../common/UseAdVisibility";
import { Link, useNavigate, useLocation, useHistory } from "react-router-dom";
export const AdPopup = (props) => {
  UserAdDisplay("Gamerji_GameFAQ_320x480");
  const [adPopup, setAdPopup] = useState(true);
  // const { isVisible } = useAdVisibility();

  const closeAd = () => {
    localStorage.setItem("isShowFAQAdInContestList", "false");
    setAdPopup(false);
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("after 1 min");
      if (adPopup) {
        setCross(true);
      }
    }, 4000);
    return () => clearTimeout(timer);
  }, []);


  const location = useLocation();
  const [popupeAd, setpopupeAd] = useState(0);
  const [cross, setCross] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);

  // useEffect(() => {
  //   const previousPath = "/gamefaq/" + props.props.gameId;
  //   const  currentPath = location.pathname;

  //   if (currentPath === "/contest/" + props.props.gameId && previousPath) {

  //   } else {

  //   }

  //   console.log(previousPath);
  // }, [location]);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedCount = localStorage.getItem("popupeAd");
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedTimestamp && currentTime > storedTimestamp) {
      localStorage.setItem("popupeAd", 0);
      localStorage.setItem("timestamp", new Date());
      setpopupeAd(0);
    } else {
      let newCount = Number(storedCount) + 1;

      // let date = new Date(new Date().getTime() + 15 * 1000);
      localStorage.setItem("popupeAd", newCount);
      // localStorage.setItem("timestamp", moment().format("DD-MM-YYYY"));
    }
    setTimeout(() => {
      // const storedCount = localStorage.getItem("popupeAd");
      const storedCount = localStorage.getItem("count");
      let newCount = Number(storedCount);

      setpopupeAd(newCount);
    }, 2000);
  }, []);

  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   if (popupeAd > 0 && popupeAd % 3 === 0) {
    //     setIsVisible2(true);
    //   } else {
    //     setIsVisible2(false);
    //   }
    // }, 10);

    // return () => clearTimeout(timeout);
    if (popupeAd >= 3) {
      setIsVisible2(true);
    } else {
      setIsVisible2(false);
    }
  }, [popupeAd]);

  return (
    <div>
      {adPopup && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: isVisible2 ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",

            // backgroundColor: "rgb(0 0 0 / 88%)",
            zIndex: 9999,
          }}
        >
          <div
            id="Gamerji_GameFAQ_320x480"
            style={{
              display: isVisible2 ? "block" : "none",
              position: "relative",
              // width: "80%",
              // maxWidth: "800px",

              borderRadius: "8px",
              padding: "20px",

              // margin: "0 auto",
              // display: isVisible2 ? "block" : "none",
              // color: "white",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-27px",
                right: "19px",
                color: "white",
                cursor: "pointer",
                fontSize: "2rem",
              }}
            >
            {cross && (
                <div  onClick={closeAd}>
                  X
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
