import calendarIcon from "../../assets/images/calendar-icon.svg"
import liveCircleIcon from "../../assets/images/live-circle.svg"
import copyIcon from "../../assets/images/copy-icon.svg"
import moment from "moment"
import { Link, useNavigate, useLocation } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ContestCard = (props) => {
    // console.log(props)
    const navigate = useNavigate()
    const location = useLocation()

    const [first, setfirst] = useState()
    const [firstk, setfirstk] = useState(false)

    var diff;

    setInterval(function () {
        // if(moment(props.contestData?.dateTime) > halfAnHourAgo){
        setfirstk(!firstk)
        // }
    }, 1000);
    let halfAnHourAgo = moment().subtract(30, 'minutes').toDate().getTime();


    useEffect(() => {
        // if(moment(props.contestData?.dateTime) > halfAnHourAgo){
        var countDownDate = moment(props.contestData?.dateTime);
        // console.log(props.contestData.title,'countDownDate',countDownDate)
        diff = countDownDate.diff(moment())

        // console.log(props.contestData.title,'diff',diff)

        var day = moment.utc(diff).format("D")
        var hour = moment.utc(diff).format("HH")
        var showTime = hour + ":" + moment.utc(diff).format("mm:ss")


        if (day > 1) {
            hour = parseInt(hour) + parseInt((day * 24))
            let duration = moment.duration(diff);
            if(duration._milliseconds<0){
                showTime = "00:00:00"
            }else{
                showTime = duration.days() + " days " + duration.hours() + ":" + duration.minutes() + ":" + duration.seconds()
                }

            
        }
        // var showTime = hour + ":" + moment.utc(diff).format("mm:ss")

        // console.log(props.contestData.title,'showTime',showTime)
               setfirst(showTime)
        // }

    }, [firstk])

    // if ( moment(props.contestData?.date) < halfAnHourAgo) {
    //     console.log('first')
    //     // color cell red
    // }


    const passwordCopy = (event) => {

        console.log("event pa", event);
        event.stopPropagation();

        console.log("passwordCopy", props.contestData.roomPassword);
        navigator.clipboard.writeText(props.contestData.roomPassword)
        toast("password copied");
    }

    const roomIdCopy = (event) => {

        event.stopPropagation();

        navigator.clipboard.writeText(props.contestData.roomId)
        .then(() => {
            toast("roomId copied");
          })
          .catch((e) => {
            toast(e);
          });
        
    }

    return (
        // <Link to ={'/afterjoin/'+props.contest}>

        // moment(props.contestData?.dateTime) > halfAnHourAgo ?
        <>
            <div className="contest-card card all" style={{ cursor: "pointer" }} onClick={(event) => {
                // console.log("event",event);
                //                 if (event.target !== event.currentTarget) return;
                navigate('/afterjoin/' + props.contestData._id, { state: { module: 'my_contest' } })
            }}>
                <div className="card-body">
                    <div className="card-header">
                        {
                            props.contestData?.title?.length > 20 ?
                                <h2 className="text-white mb-2 pb-1">

                                    <marquee >
                                        {props.contestData?.title}
                                    </marquee>
                                </h2>
                                :
                                <h2 className="text-white mb-2 pb-1">{props.contestData?.title}</h2>
                            // <h2 class="text-white mb-2 pb-1"><marquee scrollamount="5">{props.contestData.title}</marquee></h2>

                        }

                        <div className="d-flex align-items-center">
                            <img src={calendarIcon} alt="" />
                            {/* <p className="ms-2 mb-0 h3 text-muted">22 Sep 22, 2:30PM</p> */}
                            <p className="ms-2 mb-0 h3 text-muted">{moment(props.contestData?.dateTime).format('DD MMM YY, h:mmA')}</p>

                        </div>
                        {
                            (props.contestData?.status === 'inProcess' ||
                                props.contestData?.status === 'review') &&

                            <span className="badge badge-live">
                                <img className="me-1" src={liveCircleIcon} alt="" />
                                live</span>

                        }
                        {
                            (props.contestData?.status === 'started' || props.contestData?.status === 'waiting') &&
                            // <span className="badge badge-willStart">starts in 00:10:09</span>
                            <span className="badge badge-willStart">starts in {first}</span>

                            //<span className="badge badge-willStart">{moment.utc((props.contestData?.time).diff(moment())).format("HH:mm:ss")}</span>

                        }

                        {
                            props.contestData?.status === 'completed' &&
                            <span className="badge badge-completed">completed</span>
                        }
                        {
                            props.contestData?.status === 'cancelled' &&
                            <span className="badge badge-live">cancelled</span>
                        }

                    </div>
                    {
                        props.contestData?.status === 'completed' ?
                            <div className="card-content">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="">
                                            <h3 className="mb-1 text-white fw-bold d-flex align-items-center">{props.kills}</h3>
                                            <p className="mb-0 h4 text-muted">Kills</p>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="">
                                            <h3 className="mb-1 text-white fw-bold d-flex align-items-center">{props.rank}</h3>
                                            <p className="mb-0 h4 text-muted">Rank</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :


                            <div className="card-content">
                                <div className="row">
                                    <div className="col-4">
                                        {/* onClick={(event) => roomIdCopy(event)} */}
                                        <div className="" >
                                            <h3 className="mb-1 text-white fw-bold d-flex align-items-center">{props?.contestData?.roomId ? props.contestData?.roomId : '-'}
                                                {/*props.contestData?.roomId && <img className="ms-2" src={copyIcon} alt="" />*/}
                                            </h3>
                                            <p className="mb-0 h4 text-muted">Room ID</p>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        {/*onClick={(event) => passwordCopy(event)} */}
                                        <div className="" >
                                            <h3 className="mb-1 text-white fw-bold d-flex align-items-center">{props.contestData?.roomPassword ? props.contestData?.roomPassword : " - "}
                                                {/*props.contestData?.roomPassword && <img className="ms-2" src={copyIcon} alt="" />*/}
                                            </h3>
                                            <p className="mb-0 h4 text-muted">Password</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>

            <ToastContainer />
        </>
    )
}

export default ContestCard