import axios from "axios";
import customerCareApi from "../../api/customercare";
import React, { useEffect, useState } from "react";
import { Link, useLoaderData, useNavigate, useParams } from "react-router-dom";
import BackImgIcon from "../../assets/images/back-arrow-blue.svg";
import ArrowSubmitIcon from "../../assets/images/arrow-submit.svg";
import moment from "moment";

import helper from "../../common/Helper"
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const CustomerCareChats = () => {


    const perems = useParams()
    const [ticketData, setTicketData] = useState([]);
    const [conversation, setConversation] = useState([]);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();


    useEffect(() => {
        // (async () => {
            console.log("perems.ticketId", perems.ticketId)

             getTicketDetails(perems.ticketId)
        let intervalId = setInterval(async()=>{ await getTicketDetails(perems.ticketId)},10000)

        

            
            return(() => {
                clearInterval(intervalId)
            })
            

        // })()
    }, [perems.ticketId])


    async function getTicketDetails(ticketId) {

        // const list = await customerCareApi.ticketList(user?.userDeatils?._id)
        const list = await customerCareApi.getChatById(ticketId)
        console.log("list ticket", list);
        if (list.error) {
            const error = list.error
            console.log('error', error.message)
        } else {
            if (list.data) {
                const data = list.data
                if (data.success) {
                    console.log("res", data.data)

                    setTicketData(data.data)
                    setConversation(data.data.conversations)
                } else {

                    setTicketData([])

                }
            }
        }

    }

    const sendMessage = async () => {
        console.log("message", message);
        if (message != null && message != "") {
            let chat = {
                message: message,
                from: 'app user',
                dateAndTime: new Date().toString()
            }


            const newConv = [...conversation];
            newConv.push(chat);
            setConversation(newConv);

            const data = {
                _id: ticketData._id,
                message: message,
                from: 'app user',
                // dateAndTime: moment(),
                fromName: ticketData.name,
                status: 3

            }
            setMessage('')
            const list = await customerCareApi.sendMessage(data, ticketData._id)
            if (list.error) {
                const error = list.error
                console.log('error', error.message)
            } else {
                if (list.data) {
                    console.log("res", "succ");
                }
            }
        }
        else {
            toast("please enter message");

        }

    }
    return (


        <div>

            <body className="no-footer walletChat-body">
                <header className="position-fixed">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-12 d-flex align-items-center">
                                <Link onClick={() => navigate(-1)}>
                                    <img className="customer_care_back_icon" src={BackImgIcon} />
                                </Link>
                                <h1 className="mb-0 ms-3 h2 fw-normal text-purple">{ticketData.customerCareCategoryName} - #{ticketData.code}</h1>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="main-content">
                    <section className="customerCare-chats">
                        <div className="walletIssue-card card">
                            <div className="card-body">
                                <div className="card-header">
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <h2 className="mb-0 fw-normal">{ticketData.customerCareCategoryName}</h2>
                                        {
                                            ticketData.status === "1" &&
                                            <span class="badge badge-pending">{helper.TicketStatusTitles[ticketData.status]}</span>

                                        }
                                        {
                                            ticketData.status === "4" &&
                                            <span class="badge badge-resolved">{helper.TicketStatusTitles[ticketData.status]}</span>

                                        }
                                        {
                                            (ticketData.status === "3" || ticketData.status === "2") &&
                                            <span class="badge badge-inProgress">{helper.TicketStatusTitles[3]}</span>

                                        }
                                        {
                                            (ticketData.status === "5" || ticketData.status === "6") &&
                                            <span class="badge badge-closed">{helper.TicketStatusTitles[6]}</span>

                                        }
                                    </div>
                                    <p className="mb-3 h3">{ticketData.description}</p>
                                </div>
                                <div className="card-content pt-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="">
                                            <p className="mb-1 h4">raised on</p>
                                            <h3 className="mb-0 fw-bold d-flex align-items-center">{moment(ticketData.createdAt).format('DD MMM YYYY, hh:mm a')}</h3>
                                        </div>
                                        <div className="">
                                            <p className="mb-1 h4">ticket no.</p>
                                            <h3 className="mb-0 fw-bold d-flex align-items-center">#{ticketData.code}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            {conversation && conversation.length > 0 &&
                                <div className="chat-box">
                                    <ul>
                                        {
                                            conversation.map((ticket, i) => {
                                                return (
                                                    <>
                                                        {
                                                            i === 0 ?
                                                                <li className="date text-center py-4 h4 mb-0"> {moment(ticket.dateAndTime).format("DD MMMM YYYY")}</li>
                                                                :

                                                                moment(ticket.dateAndTime).format("DD MMMM YYYY") != moment(conversation[i - 1]?.dateAndTime).format("DD MMMM YYYY") ?

                                                                    <li className="date text-center py-4 h4 mb-0"> {moment(ticket.dateAndTime).format("DD MMMM YYYY")}</li>
                                                                    :
                                                                    ''
                                                        }
                                                        {ticket.from == 'Staff user' ?

                                                            <li className="received">
                                                                {ticket.message}
                                                                <div className="time">{moment(ticket.dateAndTime).format("hh:mm a")}</div>
                                                            </li>
                                                            :
                                                            <li className="sent">
                                                                {ticket.message}
                                                                <div className="time">{moment(ticket.dateAndTime).format("hh:mm a")}</div>
                                                            </li>
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            }
                            {
                                (ticketData.status === "1" || ticketData.status === "2" || ticketData.status === "3") &&
                                <div className="chat-reply">
                                    <div className="reply-box">
                                        <textarea className="form-control"  placeholder="type here.." aria-label="type here.." value={message} onChange={(e) => setMessage(e.target.value)}/>
                                        <button className="btn btn-outline-success" onClick={() => sendMessage()}><img src={ArrowSubmitIcon} alt="" /></button>
                                    </div>
                                </div>


                            }

                            {
                                ticketData.status === "4" &&

                                <div className="chat-reply resolved">

                                    <div className="resolved-box d-flex align-items-center justify-content-center">
                                        <img src="assets/images/resolved-icon.svg" className="me-2" alt="" />
                                        <h2 className="mb-0 fw-medium text-purple">Issue resolved!</h2>
                                    </div>

                                </div>
                            }

                            {ticketData.status === "6" &&

                                <div className="chat-reply resolved">
                                    <div className="info">
                                        <p>We haven’t heard back from you so we’ll close this conversation for now. </p>
                                        <p>You can always reach out to us again from <a href="customer-care-tickets">here <img src="assets/images/double-chevron.svg" alt="" /></a></p>
                                    </div>
                                    <div className="resolved-box d-flex align-items-center justify-content-center">
                                        <img src="assets/images/resolved-icon.svg" className="me-2" alt="" />
                                        <h2 className="mb-0 fw-medium text-purple">Conversation closed!</h2>
                                    </div>
                                </div>
                            }
                        </div>
                    </section>
                </div>

            </body>
            <ToastContainer />

        </div>
    )

}

export default CustomerCareChats;