import Index from "../view/home/Index";
import { useSelector, useDispatch } from "react-redux";
import gameApi from "../api/game";
import bannerApi from "../api/banner";
import React, { useEffect, useState, useRef } from "react";
import { useAdVisibility } from "../common/UseAdVisibility";
import {
  setUserAppToken,
  setUserDetails,
  setAgreeTerms,
} from "../redux/user/user.action";
import contestApi from "../api/contest";
import {
  useOutletContext,
  // useParams,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import loginApi from "../api/login";
import userApi from "../api/user";
import { injectStyle } from "react-toastify/dist/inject-style";
import helper from "../common/Helper";
import clevertap from "clevertap-web-sdk";
import { UserAdDisplay } from "../common/UserAdDisplay";

function HomeController() {
  const [gameList, setGameList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [contestList, setContestList] = useState([]);
  const [upcomingContestList, setUpcomingContestList] = useState([]);
  const [complatedContestList, setComplatedContestList] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // const perems = useParams();
  const location = useLocation();

  const listInnerRef = useRef();
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [lastList, setLastList] = useState(false);
  const [pageName, setPageName] = useState("Home");
  const [isAgreeTermsAndConditions, setIsAgreeTermsAndConditions] =
    useState(true);
  //const torcai = window.torcai;
  clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
  clevertap.privacy.push({ useIP: true }); // Set the flag to true, if the user agrees to share their IP data
  clevertap.init(helper.cleverTapAccount, "in"); //
  injectStyle();

  let urlToken = searchParams.get("token");

  const { count, isVisible } = useAdVisibility(urlToken);
  console.log(count);
  // const { count, isVisible } = useAdVisibility(user.userAuthToken);

  // useEffect(() => {
  //   console.log(user.isFirstTime);
  //   setIsAgreeTermsAndConditions(user.isAgreeTermsAndConditions);
  //   console.log(user.isAgreeTermsAndConditions);
  // }, [user.isAgreeTermsAndConditions]);

  useEffect(() => {
    // console.log("Count:", count);
    // console.log("Is Visible:", isVisible);

    // if (isVisible === true) {
    //   console.log("its true")
    //   localStorage.setItem("isVisibleAD", "true");
    // }

    // localStorage.setItem("isVisibleAD", "true")
  }, [count, isVisible]);

  //UserAdDisplay("Gamerji_HP_Sticky_320x50");
  const [myhomeAd, setmyhomeAd] = useState(0);
  const [isVisible2, setIsVisible2] = useState(false);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedCount = localStorage.getItem("myhomeAd");
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedTimestamp && currentTime > storedTimestamp) {
      localStorage.setItem("myhomeAd", 0);
      localStorage.setItem("timestamp", new Date());
      setmyhomeAd(0);
    } else {
      let newCount = Number(storedCount) + 1;

      // let date = new Date(new Date().getTime() + 15 * 1000);
      // localStorage.setItem("myhomeAd", newCount);
      // localStorage.setItem("timestamp", moment().format("DD-MM-YYYY"));
    }
    setTimeout(() => {
      // const storedCount = localStorage.getItem("myhomeAd");
      const storedCount = localStorage.getItem("count");
      console.log(storedCount);
      let newCount = Number(storedCount);
      console.log(newCount, "newCount SK");
      setmyhomeAd(newCount);
    }, 2000);
  }, []);

  useEffect(() => {
    if (myhomeAd >= 3) {
      setIsVisible2(true);
    } else {
      setIsVisible2(false);
    }
  }, [myhomeAd]);

  useEffect(() => {
    (async () => {
      await getBannerList();

      // await getContestList('waiting', user.userDeatils.user_id)

      // if (perems?.appToken && !user.userAuthToken) {
      //     await userLogin(perems?.appToken)
      //     dispatch(setUserAppToken(perems?.appToken))
      // }

      // console.log("location", location.search.split('token='));
      let urlToken = searchParams.get("token");
      if (urlToken) {
        // localStorage.clear();
        let token = location.search.split('token=')
        // console.log(urlToken, "tokenUrl");
        await userLogin(token[1]);
        console.log("pageName:", pageName);
        clevertap.event.push("Gamerji_Visited", {
          PageName: pageName,
          Date: new Date(),
        });
        dispatch(setUserAppToken(urlToken));
      }
      //  console.log(urlToken)
    })();
  }, []);
  // console.log(user)
  // console.log(user.isFirstTime);

  // {
  //   user && user.isFirstTime ? (
  //     <div></div>
  //   ) : (
  //     UserAdDisplay("Gamerji_HP_Sticky_320x50")
  //   );
  // }
  // console.log(!user.isAgreeTermsAndConditions);
  useEffect(() => {
    if (isAgreeTermsAndConditions) {
      setTimeout(() => {
        UserAdDisplay("Gamerji_HP_Sticky_320x50");
      }, 2000);
    }
  }, [isAgreeTermsAndConditions]);
  // {
  //   isAgreeTermsAndConditions ? (
  //     UserAdDisplay("Gamerji_HP_Sticky_320x50")
  //   ) : (
  //     <></>
  //   );
  // }

  useEffect(() => {
    (async () => {
      if (user?.userDeatils?.user_id) {
        await getContestList(
          ["waiting", "started"],
          user?.userDeatils?.user_id
        );
        // await getContestList("completed", user?.userDeatils?.user_id);
        // await getUserDetail(user?.userDeatils?._id)
      }
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      if (!lastList && prevPage !== currPage) {
        await getGameList();
      }
    })();
    // console.log(user.userDeatils.user_id)
  }, [currPage, lastList, prevPage, gameList]);

  const onScroll = () => {
    // if (listInnerRef.current) {
    //     const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
    //     if (scrollTop + clientHeight === scrollHeight) {
    //         setCurrPage(currPage + 1);
    //     }
    // }
  };

  // login user
  async function userLogin(token) {
    var tData = {};
    tData.token = token;
    console.log("Data:",tData)
    const login = await loginApi.loginCall(tData);

    if (login.error) {
      const error = login.error;
      console.log(error, '------');
      let value = JSON.stringify({ "tag": "back pressed" });
      if (window.ReactNativeWebView) {

        window.ReactNativeWebView.postMessage(value);
      }

      // console.log('error', error)
    } else {
      // console.log("login data", login.data)
      console.log("login", login.data.data)
      if (login.data.data) {
        const data = login.data;
        // console.log("login data", data.data)
        if (
          data.data.contentId ||
          data.data.contextId ||
          data.data.otherparameters
        ) {
          localStorage.setItem("isAd", true);
        } else {
          // localStorage.clear();
        }

        if (data.data.companyInfo) {
          localStorage.setItem("companyInfo", data.data?.companyInfo?._id);
        }

        setPageName(
          helper.rerdirectLoginUSer(
            data.data.contentId,
            data.data.contextId,
            data.data.otherparameters
          )
        );
        navigate(
          helper.rerdirectLoginUSer(
            data.data.contentId,
            data.data.contextId,
            data.data.otherparameters
          ),
          { replace: true }
        );
        // console.log("user",data.data.user)
        //  console.log("href:",helper.rerdirectLoginUSer(data.data.contentId, data.data.contextId, data.data.otherparameters))
        // var redirect = helper.rerdirectLoginUSer(data.data.contentId, data.data.contextId, data.data.otherparameters)
        //window.location.href = redirect
        if (data.data.success) {
          let storeDetails = {};
          storeDetails.userDeatils = data.data.user;
          storeDetails.userAuthToken = data.data.token;

          clevertap.onUserLogin.push({
            Site: {
              Identity: data.data?.user?.phone.toString(),
            },
          });
          dispatch(setUserDetails(storeDetails));
          dispatch(setAgreeTerms(data.data.user?.isAgreeTermsAndConditions));
        }
        //var redirect = helper.rerdirectLoginUSer(data.data.contentId, data.data.contextId, data.data.otherparameters)
        //window.location.href = redirect
      } else {
        console.log('--------------')
        let value = JSON.stringify({ "tag": "back pressed" });
        if (window.ReactNativeWebView) {

          window.ReactNativeWebView.postMessage(value);
        }
      }
    }
  }

  // home page All games List
  async function getGameList() {
    let data = {};
    data.pagination = {};

    data.pagination.pageNo = currPage;
    data.pagination.recordPerPage = 100;
    data.pagination.sortBy = "order";
    data.pagination.sortDirection = "asc";

    const list = await gameApi.list(data);
    if (list.error) {
      const error = list.error;
      // console.log('error', error.message)
    } else {
      if (list.data) {
        const data = list.data;
        if (data.success) {
          if (!data.data.length) {
            setLastList(true);
            return;
          }
          setGameList(data.data);
          setPrevPage(currPage);
          // setGameList([...gameList, ...data.data])
        }
      }
    }
  }

  // home page banner List
  async function getBannerList() {
    let data = {};

    data.pagination = {};
    data.search = {};
    data.search.isActive = true;

    data.pagination.recordPerPage = 100;
    data.pagination.sortBy = "order";
    data.pagination.sortDirection = "asc";
    const list = await bannerApi.list(data);
    if (list.error) {
      const error = list.error;
      // console.log("error", error.message);
    } else {
      if (list.data) {
        const data = list.data;
        if (data.success) {
          // console.log(data)
          setBannerList(data.data);
        }
      }
    }
  }

  async function getContestList(status, userId) {
    let data = {};
    data.search = {};
    data.search.user = userId;
    data.search.status = status;

    // console.log(data)
    if (status.includes("waiting") || status === "waiting") {
      // console.log(data.data)
      const list = await contestApi.upcomingContestList(data);

      console.log("upcoming list", list);
      if (list.error) {
        const error = list.error;
        // console.log('error', error.message)
      } else {
        if (list.data) {
          const data = list.data;
          if (data.success) {
            setUpcomingContestList(data.data);
          }
        }
      }
    }
    if (status === "completed") {
      const list = await contestApi.lastplayedContestList(data);
      // console.log(list)
      if (list.error) {
        const error = list.error;
        // console.log('error', error.message)
      } else {
        if (list.data) {
          const data = list.data;
          if (data.success) {
            setComplatedContestList(data.data);
          }
        }
      }
    }
  }

  async function getUserDetail(userId) {
    const userData = await userApi.getUserDetail(userId);
    if (userData.error) {
      const error = userData.error;
      console.log("error", error.message);
    } else {
      if (userData.data) {
        const data = userData.data;
        if (data.success) {
          let storeDetails = {};
          storeDetails.userDeatils = data.data;
          dispatch(setUserDetails(storeDetails));
        }
      }
    }
  }

  const agreeTerms = async () => {
    let uid = user?.userDeatils?.user_id;
    let data = {};
    data.isAgreeTermsAndConditions = true;
    data.user = uid;

    if (uid && uid !== null && uid !== undefined) {
      const agree = await userApi.agreeTremsAndCondition(data);
      if (agree.data) {
        dispatch(setAgreeTerms(agree.data.data.success));
      }
    }
  };

  return (
    <>
      <Index
        gameList={gameList}
        bannerList={bannerList}
        upcomingContestList={upcomingContestList}
        complatedContestList={complatedContestList}
        onScroll={onScroll}
        listInnerRef={listInnerRef}
        agreeTerms={agreeTerms}
        isLoaderShow={isLoading}
      />

      {/* {user && user.isFirstTime ? (
        <div></div>
      ) : (
       
      )} */}

      {/* {isAgreeTermsAndConditions ? (
        <div id="Gamerji_HP_Sticky_320x50"></div>
      ) : (
        ""
      )} */}
    </>
  );
}
export default HomeController;
