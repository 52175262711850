import faqBgmi from "../../assets/images/faq-bgmi.jpg";
import { Audio, Oval } from "react-loader-spinner";
import { UserAdDisplay } from "../../common/UserAdDisplay";
// import { useAdVisibility } from "../../common/UseAdVisibility";
import { useState, useEffect } from "react";
const Index = (props) => {
  // const { isVisible } = useAdVisibility();
  const faqList = props.faqList;
  const isShowLoader = props.isLoaderShow;
  UserAdDisplay("Gamerji_GameFAQ_300x250");
  const [gameFAQAd, setgameFAQAd] = useState(0);

  const [isVisible2, setIsVisible2] = useState(false);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedCount = localStorage.getItem("gameFAQAd");
    const storedTimestamp = localStorage.getItem("timestamp");
    localStorage.setItem("isShowFAQAdInContestList", "true");

    if (storedTimestamp && currentTime > storedTimestamp) {
      localStorage.setItem("gameFAQAd", 0);
      localStorage.setItem("timestamp", new Date());
      setgameFAQAd(0);
    } else {
      let newCount = Number(storedCount) + 1;

      // let date = new Date(new Date().getTime() + 15 * 1000);
      localStorage.setItem("gameFAQAd", newCount);
      // localStorage.setItem("timestamp", moment().format("DD-MM-YYYY"));
    }
    setTimeout(() => {
      // const storedCount = localStorage.getItem("gameFAQAd");
      const storedCount = localStorage.getItem("count");
      let newCount = Number(storedCount);
      // console.log(newCount, "newCount");
      setgameFAQAd(newCount);
    }, 2000);
  }, []);

  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   if (gameFAQAd > 0 && gameFAQAd % 3 === 0) {
    //     setIsVisible2(true);
    //   } else {
    //     setIsVisible2(false);
    //   }
    // }, 10);

    // return () => clearTimeout(timeout);
    if (gameFAQAd >= 3) {
      setIsVisible2(true);
    } else {
      setIsVisible2(false);
    }
  }, [gameFAQAd]);

  return (
    <section className="faq-detail">
      <div
        className="container-fluid  marginb"
        style={{
          height:"100vh",
          
        }}
      >
        <div className="accordion" id="faqAccordian">
          {isShowLoader ? (
            <div className="main-content registration-main">
              <Oval
                height={50}
                width={50}
                color="#fff"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#ffffffd1"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : faqList.length > 0 ? (
            faqList.map((faq, key) => {
              return (
                <>
                  <div className="accordion-item" key={key}>
                    <h2 className="accordion-header" id={"faq" + key}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#collapse" + key}
                        aria-expanded="false"
                        aria-controls={"collapse" + key}
                      >
                        {faq.title}
                      </button>
                    </h2>
                    <div
                      id={"collapse" + key}
                      className="accordion-collapse collapse"
                      aria-labelledby={"faq" + key}
                      data-bs-parent="#faqAccordian"
                    >
                      <div className="accordion-body">
                        {/* <p>The room ID and  password for the game will be provided 15 mins before the start of the game</p> */}
                        {/* <img src={faqBgmi} className="rounded-5 img-fluid" alt="" /> */}
                        {/* <p>{faq.description.replace(/<(.|\n)*?>/g, '')} </p> */}
                        <div
                          dangerouslySetInnerHTML={{ __html: faq.description }}
                        />
                        {/* <img src={faqBgmi} className="rounded-5 img-fluid" alt="" /> */}
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <>
              <div className="main-content registration-main">
                <h2 className="text-white">No FAQ Found</h2>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        style={{
          display: isVisible2 ? "block" : "none",
          // position: "fixed",
          // justifyContent: "center",
          // alignItems: "center",
          // margin: "auto",
          // bottom: 0,
          // left: 0,
          // right: 0,
          position: "fixed",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          bottom: 0,
          left: 0,
          right: 0,
          textAlign: "center"
        }}
      >
        <div id="Gamerji_GameFAQ_300x250"></div>
      </div>
    </section>
  );
};
export default Index;
