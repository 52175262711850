import { useOutletContext, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Index from "../view/termsAndConditions";
import tAndCApi from "../api/tremsAndCondition";


function TremsAndConditionController() {
    const { setTitle } = useOutletContext();
    const [value, setValue] = useState({})
    const [otherValue, setOtherValue] = useState({})


    useEffect(() => {
        (async () => {
            setTitle('Terms and conditions')
            await getTandC()
            await getOtherTandC()
        })();
    }, []);

    async function getTandC() {
        let data = {}
        data.search = {}
        data.search.slug = 'tnc'

        const list = await tAndCApi.getBySlug(data)
        if (list.error) {
            const error = list.error
        } else {
            if (list.data) {
                const data = list.data.data
                if (data.success) {
                    setValue(data.data)

                }else{
                    setValue({})
                }

            }
        }

    }
    async function getOtherTandC() {
        let data = {}
        data.search = {}
        data.search.slug = 'gatnc'

        const list = await tAndCApi.getBySlug(data)
        if (list.error) {
            const error = list.error
        } else {
            if (list.data) {
                const data = list.data.data
                if (data.success) {
                    setOtherValue(data.data)

                }else{
                    setOtherValue({})
                }

            }
        }

    }



    return (
        <Index value={value} otherValue={otherValue} />
    )


}
export default TremsAndConditionController