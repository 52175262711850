import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";


function rewards() {

    const rewardCategorylist = async (data) => {
        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl() + 'rewardCategory/list',
                responseType: 'json',
                headers: helper.headers(),
                data: data
            })
                .then(response => response.data)
        )

    }

    const list = async (data) => {
        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl() + 'rewardProduct/list',
                responseType: 'json',
                headers: helper.headers(),
                data: data
            })
                .then(response => response.data)
        )

    }

    const myRewardList = async (token, data) => {
        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl() + 'reward-by-user/list',
                responseType: 'json',
                headers: helper.headers(token),
                data: data
            })
                .then(response => response.data)
        )

    }

    const addReward = async (data) => {
        return await resolve(
            axios({
                method: 'post',
                url: helper.viApiUrl() + 'userReward/add',
                responseType: 'json',
                headers: helper.headers(),
                data: data
            })
          
                .then(response => response.data)
             
        )
     

    }
    return {
        rewardCategorylist,
        list,
        myRewardList,
        addReward,
    }
}
const rewardsApi = rewards();
export default rewardsApi;