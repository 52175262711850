import { Link } from "react-router-dom"
import rightChevroncon from "../../assets/images/right-chevron.svg"
const Index = (props) => {
    var key;
    return (
        <>
            {/*<section className="">
                <div className="container-fluid">
                    <div className="text-white" style={{fontSize:"12px"}} dangerouslySetInnerHTML={{ __html: props?.value?.description }} />
                </div>
    </section>*/}
        <section className="my-account-block">
        <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="info">
                        <h2 className="fw-bold text-white mb-0">Gamerji Esports Terms and conditions</h2>
                    </div>
                    <div className="icon">
                        <Link to='/tnc/gatnc'>
                            <img src={rightChevroncon} alt="" />
                        </Link>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="info">
                        <h2 className="fw-bold text-white mb-0">Vi Games Terms and conditions</h2>
                    </div>
                    <div className="icon">
                        <Link to='/tnc/tnc'>
                            <img src={rightChevroncon} alt="" />
                        </Link>
                    </div>
                </div>
        </div>
        </section>
        </>
    )

}
export default Index