import termsImg from "../assets/images/terms-img.svg"
import { Link } from "react-router-dom"

const Terms = (props) => {
    return (
        <section className="exit-popup terms-popup">
            <div className="container-fluid">
                <img className="mb-4" src={termsImg} alt="" />
                <p className="pt-3 h3 px-2 fw-normal">We have updated our <Link to="/terms-and-conditions" className="fw-medium text-decoration-underline text-silver">terms and conditions</Link> to protect your privacy throughout your gaming experience.</p>
                <div className="d-grid mt-4">
                    {/* <a href="#" className="btn btn-danger btn-lg">ok, got it</a> */}
                    <button className="btn btn-danger btn-lg" onClick={() => props.agreeTerms()}>ok, got it</button>

                </div>
            </div>
        </section>
    )
}
export default Terms