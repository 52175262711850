import rightChevroncon from "../../assets/images/right-chevron.svg";
import spector from "../../assets/images/separator-radial.svg";
import coinIcon from "../../assets/images/coin-gold.svg";
import DiamondImgIcon from "../../assets/images/diamond_img_icon.png";

import PlusImgIcon from "../../assets/images/plus-icon.svg";
import callIcon from "../../assets/images/call-icon.svg";
import mailIcon from "../../assets/images/mail-icon.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import userServices from "../../redux/user/user.services";
import React, { useState, useEffect } from "react";
import powered from "../../assets/images/powered2.png";

const Index = (props) => {
  const [totalBalance, setTotalBalance] = useState(0);
  const userDetails = props.userDetails;
  const user = useSelector((state) => state.user);

  useEffect(() => {
    (async () => {
      if (user?.userDeatils?._id) {
        const userDeatils = await userServices.getUserDetails(
          user?.userDeatils?._id
        );
        const wallet = userDeatils.wallet[0];
        let userWalletTotal = userServices.getWalletTotalAmount(wallet);
        setTotalBalance(userWalletTotal);
      }
    })();
  }, [user]);

  return (
    <>
      <section className="my-account-block">
        <div className="container-fluid">
          <div className="d-flex justify-content-between mb-4 mt-3">
            <h3 className="text-white mb-0 h1 fw-bold">
              {user?.userDeatils?.name}
            </h3>
            <div className="flex gap">
              <span className="badge coin-badge">
                <img className="me-1" src={DiamondImgIcon} alt="" />
                {totalBalance}
              </span>
              <Link
                className="all_rewards_header_coin_plus_icon"
                to={"/buy-gems"}
              >
                <img className="plus_icon" src={PlusImgIcon} />
              </Link>
            </div>
          </div>
          <div
            className="d-flex align-items-center"
            style={{ marginBottom: "40px" }}
          >
            <div className="icon me-3">
              <img src={callIcon} alt="" />
            </div>
            <div className="info">
              <h3 className="fw-bold text-white mb-0">{userDetails.mobile}</h3>
              <p className="text-gray mb-0 h3">Mobile</p>
            </div>
          </div>
          {/* <div className="d-flex align-items-center mb-4 pb-2">
                    <div className="icon me-3">
                        <img src={mailIcon} alt="" />
                    </div>
                    <div className="info">
                        <h3 className="fw-bold text-white mb-0">abhijeetgupta@gmail.com</h3>
                        <p className="text-gray mb-0 h3">Email ID</p>
                    </div>
                </div> */}
          {/* <a href="" className="contest-link"> */}
          <Link to="/mytransactions" className="contest-link">
            my transactions
            {/* <Link to='/mytransactions'> */}
            <img src={rightChevroncon} alt="" />
          </Link>
          {/* </a> */}
          <div className="mb-4 pb-3 separator text-center">
            <img className="align-top" src={spector} alt="" />
          </div>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="info">
              <h2 className="fw-bold text-white mb-0">buy gems</h2>
            </div>
            <div className="icon">
              <Link to="/buy-gems">
                <img src={rightChevroncon} alt="" />
              </Link>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="info">
              <h2 className="fw-bold text-white mb-0">my gamer IDs</h2>
            </div>
            <div className="icon">
              <Link to="/mygamerid">
                <img src={rightChevroncon} alt="" />
              </Link>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="info">
              <h2 className="fw-bold text-white mb-0">FAQ</h2>
            </div>
            <div className="icon">
              <Link to="/faq">
                <img src={rightChevroncon} alt="" />
              </Link>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="info">
              <h2 className="fw-bold text-white mb-0">customer care</h2>
            </div>
            <div className="icon">
              <Link to="/customer-care-tickets">
                <img src={rightChevroncon} alt="" />
              </Link>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="info">
              <h2 className="fw-bold text-white mb-0">terms & conditions</h2>
            </div>
            <div className="icon">
              <Link to="/terms-and-conditions">
                <img src={rightChevroncon} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/*<section className="last-played py-4 poweredMt">
                <img className="powered" src={powered} alt="Powered By Gamerji" />
            </section>*/}
    </>
  );
};
export default Index;
