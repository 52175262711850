import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";

function tremsAndCondition() {

    const getBySlug = async (data) => {

        return await resolve(
            axios({
                method: 'post',
                url: helper.viApiUrl() + 'page/get/bySlug',
                headers: helper.headers(),
                data:data
            })
                .then(response => response)
                .catch(error => error)
        )

    }
    return {
        getBySlug,
    }
}
const tAndCApi = tremsAndCondition();
export default tAndCApi;