import { Link } from "react-router-dom"
import rightChevroncon from "../../assets/images/right-chevron.svg"
const TncPage = (props) => {
    var key;
    return (
        <>
            <section className="">
                <div className="container-fluid">
                    <div className="text-white" style={{fontSize:"12px"}} dangerouslySetInnerHTML={{ __html: props?.value?.description }} />
                </div>
    </section>
        </>
    )

}
export default TncPage