import userApi from "../../api/user";





function Services() {

    const getUserDetails = async (userId) => {
         console.log("goToJoinGame>getUserDetails",3)
        let userDeatils = null
        const userData = await userApi.getUserDetail(userId)
        if (userData.error) {
            const error = userData.error

        } else {
            if (userData.data) {
                const data = userData.data
                if (data.success) {
                    userDeatils = data.data
                }
            }
        }
        return userDeatils;


    }

    const getWalletTotalAmount = (wallet = null) => {
        let userWalletTotal;
        // let userWalletTotal = Number(wallet.bonusAmount) + Number(wallet.packs) + Number(wallet.winningAmount)
        if (wallet && wallet?.winningAmount) {
            userWalletTotal = Number(wallet?.winningAmount)
        } else {
            userWalletTotal = 0
        }

        return userWalletTotal;



    }
    return {
        getUserDetails,
        getWalletTotalAmount
    }
}
const userServices = Services();
export default userServices;