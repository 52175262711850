// import { useState, useEffect } from "react";
// import moment from "moment";
// export const useAdVisibility = (token) => {
//   // const [count, setCount] = useState(() => {
//   //   const storedCount = localStorage.getItem("count");
//   //   const storedTimestamp = localStorage.getItem("timestamp");
//   //   const currentTime = new Date();

//   //   if (
//   //     storedTimestamp &&
//   //     currentTime - parseInt(storedTimestamp, 10) > 24 * 60 * 60 * 1000
//   //   ) {
//   //     localStorage.removeItem("count");
//   //     localStorage.removeItem("timestamp");
//   //     return 0;
//   //   }

//   //   return storedCount ? parseInt(storedCount, 10) : 0;
//   // });
//   const [count, setCount] = useState(0)

//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {

//     if (token) {
//       const currentTime = new Date().getTime();
//       const storedCount = localStorage.getItem("count");
//       const storedTimestamp = localStorage.getItem("timestamp");


//       if (storedTimestamp && currentTime > storedTimestamp) {
//         localStorage.setItem("count", 0);
//         localStorage.setItem("timestamp", new Date());
//         setCount(0);
//       } else {
//         let newCount = Number(storedCount) + 1;

//         let date = new Date(new Date().getTime() + 15 * 1000)
//         localStorage.setItem("count", newCount);
//         localStorage.setItem("timestamp", moment().format('DD-MM-YYYY'));
//       }
//     } else {
//       setTimeout(() => {

//         const storedCount = localStorage.getItem("count");
//         let newCount = Number(storedCount);
//         console.log(newCount, 'newCount');
//         setCount(newCount);
//       }, 2000);
//     }
//   }, [token]);

//   useEffect(() => {
//     const timeout = setTimeout(() => {
//       console.log(count);
//       if (count > 0 && count % 3 === 0) {
//         setIsVisible(true);
//       } else {
//         setIsVisible(false);
//       }
//     }, 10);

//     return () => clearTimeout(timeout);
//   }, [count]);

//   // useEffect(() => {
//   //   const resetTimeout = setTimeout(() => {
//   //     const storedTimestamp = localStorage.getItem("timestamp");
//   //     const storedDate = storedTimestamp ? new Date(storedTimestamp).getDate() : null;
//   //     const currentDate = new Date().getDate();

//   //     if (storedDate !== currentDate) {
//   //       localStorage.removeItem("count");
//   //       localStorage.removeItem("timestamp");
//   //       setCount(0);
//   //     }
//   //   }, 1000);

//   //   return () => clearTimeout(resetTimeout);
//   // }, []);

//   return { count, isVisible };
// };

import { useState, useEffect } from "react";
import moment from "moment";

export const useAdVisibility = (token) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(!!token);

  useEffect(() => {
    if (token) {

      // if (storedTimestamp && currentTime > storedTimestamp) {
      //   localStorage.setItem("count", 0);
      //   localStorage.setItem("timestamp", new Date());
      //   setCount(0);
      // } else {
      // setTimeout(() => {
      const currentTime = new Date().getTime();

      const storedTimestamp = localStorage.getItem("timestamp");

      const storedCount = localStorage.getItem("count");

      let newCount = Number(storedCount) + 1;

      let date = new Date(new Date().getTime() + 15 * 1000);
      localStorage.setItem("count", 3);
      localStorage.setItem("timestamp", moment().format('DD-MM-YYYY'));
      // }, 3000);
      // }
    }
    // else {
    //   setTimeout(() => {
    //     const storedCount = localStorage.getItem("count");
    //     let newCount = Number(storedCount);
    //     console.log(newCount, 'newCount');
    //     setCount(newCount);
    //   }, 2000);
    // }
  }, [token]);

  return { isVisible };
};
