import MyRewards from "../view/rewards/MyRewards"
import { useOutletContext, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AllRewards from "../view/rewards/AllRewards";
import NavigationSection from "../component/NavigationSection";
import rewardsApi from "../api/rewards";

function AllRewardsController() {


    const [rewardCategoryList, setRewardCategoryList] = useState([])
    const [rewardProductList, setRewardProductList] = useState([])
    const [isLoader, setIsLoading] = useState(true);
    useEffect(() => {
        (async () => {
            await getRewardCategoryList()

        })()
    }, [])

    async function getRewardCategoryList() {
        const list = await rewardsApi.rewardCategorylist({ search: { isActive: true } })
        if (list.error) {
            const error = list.error
            // console.log('error', error.message)
        } else {
            if (list.data) {
                const data = list.data
                //   console.log("rewarsCon",data);
                if (data.success) {
                    let rewardCategory_types = [...data.data];
                    data.data.forEach((element, i) => {
                        rewardCategory_types[i].isActive = false;
                        rewardCategory_types[0].isActive = true;

                    })
                    await getRewardProductList(rewardCategory_types);
                    setRewardCategoryList(rewardCategory_types);
                } else {
                    setRewardCategoryList([])

                }
            }
        }
    }

    async function getRewardProductList(rewardCategoryList) {
        // console.log(("rewardCategoryList", rewardCategoryList)); 
        var payload = {
            pagination: {
                pageNo: 1,
                recordPerPage: 10,
                sortBy: "name",
                sortDirection: "desc",

            }
        }
        payload.search = {
            category: rewardCategoryList[0]._id,
            isActive: true
        }

        const list = await rewardsApi.list(payload)
        if (list.error) {
            const error = list.error
            // console.log('error', error.message)
        } else {
            setIsLoading(false)
            if (list.data) {
                const data = list.data
                //  console.log("rewarsCon",data);
                if (data.success) {

                    setRewardProductList(data.data)
                } else {
                    setRewardProductList([])

                }
            }
        }
    }



    return (
        <>
            <NavigationSection activeTab="reward" />

            <AllRewards rewardCategoryList={rewardCategoryList} rewardProductList={rewardProductList} isLoaderShow={isLoader}/>
        </>
    )

}
export default AllRewardsController