import { Outlet } from 'react-router-dom';
import GamePageHeader from '../component/GamePageHeader';


export default function GameLayout() {
    return (
        <>
            <GamePageHeader />
            <Outlet />
        </>
    )

}