import { Link, useNavigate } from "react-router-dom"
import battleground1India from "../../assets/images/battleground-india.jpg"
import { useSelector, useDispatch } from "react-redux";
import { setCurrentGameName } from "../../redux/user/user.action";



const Game = (props) => {

    console.log(props?.title)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const goToContest = () => {
        dispatch(setCurrentGameName(props.name))
        //navigate('/contest/' + props._id)
        window.location.href='/contest/' + props._id  
    }

    return (
        <div className="col-6" key={props?.myKey} onClick={() => goToContest()} style={{cursor:"pointer"}}>
            {/* <Link to={'/contest/' + props._id}> */}
            <div className="game-card card">
                <div className="card-img">
                    <img src={props.displayImage} alt="" />
                </div>
                <div className="card-title">{props.name}</div>
            </div>
            {/* </Link> */}
        </div>
    )

}
export default Game