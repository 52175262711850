import backArrowImg from "../assets/images/back-arrow.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import contestback from "../assets/images/contest-back.svg";
import helper from "../common/Helper";
import { useState } from "react";


const PageHeader = (props) => {
  let curr = props?.module?.split("/");
  var isAd = localStorage.getItem("isAd");
  const location = useLocation();

  const navigate = useNavigate();

  return (
    <header className="position-fixed pt-0">
      <div
        className={
          location.pathname.startsWith("/contest/")
            ? "container-fluid position-fixed-back"
            : "container-fluid"
        }
        style={
          location.pathname.startsWith("/contest/")
            ? { backgroundImage: `url(${props.image})` }
            : {}
        }
      >
        <div className="row align-items-center">
          <div className="col-8 d-flex align-items-center">
            {/* <Link onClick={() => onClickBack()} className="backButtonPadding"><img src={backArrowImg} alt="back" /></Link> */}
            {/* {console.log("isAd",isAd)} */}
            {isAd ? (
              <Link
                to="/home"
                // onClick={() => navigate(-1)}
                className="backButtonPadding backButtonAddPadding"
              >
                <img src={backArrowImg} alt="back" />
              </Link>
            ) : location.pathname.startsWith("/registration/done/") ? (
              <></>
            ) : // <Link
            //     to={'/afterjoin/' + location?.pathname?.split('/')[3]}
            //     // onClick={() => navigate(location.pathname.startsWith('/registration/done/') ? '/afterjoin/' + location?.pathname?.split('/')[3] : -1)}
            //     className="backButtonPadding backButtonAddPadding"><img src={backArrowImg} alt="back" />
            // </Link>

            //myContest
            location.pathname.startsWith("/myaccount/") ? (
              <Link
                to={"/home"}
                //  onClick={() => navigate(location.pathname.startsWith('/registration/done/') ? '/afterjoin/' + location?.pathname?.split('/')[3] : -1)}
                className="backButtonPadding backButtonAddPadding"
              >
                <img src={backArrowImg} alt="back" />
              </Link>
            ) : //game/join
            location.pathname.startsWith("/contest/") ? (
              <Link
                to={"/home"}
                //  onClick={() => navigate(location.pathname.startsWith('/registration/done/') ? '/afterjoin/' + location?.pathname?.split('/')[3] : -1)}
                className="backButtonPadding backButtonAddPadding"
              >
                <img src={backArrowImg} alt="back" />
              </Link>
            ) : (
              <Link
                // to='/'
                // onClick={() => handlePopup()}
                onClick={() =>
                  navigate(
                    location.pathname.startsWith("/registration/done/")
                      ? "/afterjoin/" + location?.pathname?.split("/")[3]
                      : -1
                  )
                }
                className="backButtonPadding backButtonAddPadding"
              >
                {/* {adPopup && <AdPopup setAdPopup={setAdPopup} />} */}
                <img src={backArrowImg} alt="back" />
              </Link>
            )}

            <h1 className="mb-0 ms-3 text-white h2 fw-normal">{props.title}</h1>
          </div>

          {curr.includes("contest") && (
            <div className="col-4 d-flex justify-content-end align-items-center">
              <Link
                to={"/gamefaq/" + props?.faqGameId}
                className="text-white h3 text-decoration-none border-bottom border-1 mb-0"
              >
                FAQs
              </Link>
            </div>
          )}
          {curr.includes("mycontest") && (
            <div className="col-4 d-flex justify-content-end align-items-center">
              <Link
                to={"/gamefaq/" + props?.faqGameId}
                className="text-white h3 text-decoration-none border-bottom border-1 mb-0"
              >
                FAQs
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};
export default PageHeader;
