import React, { useState, useEffect } from 'react';
import banner1 from "../assets/images/banner-1.jpg"
import helper from "../common/Helper";

import { Swiper, SwiperSlide } from 'swiper/react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { Virtual } from 'swiper';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
// import 'swiper/css/effect-coverflow';
// import 'swiper/css/autoplay';

import configData from "../config/config.json";

import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { useDispatch } from 'react-redux';

const HomeBanner = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    let bannerList = props.bannerList
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
// console.log(bannerList)
        // let urlToken = searchParams.get("token");
        // console.log(urlToken)
        // if (urlToken) {
        //     getCMSSetting(urlToken);
        // }
    }, [])

    async function getCMSSetting(token) {
        var response = await fetch(configData.SERVER_URL + "login/login", {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: token
            })
        }).then(responses => {
            const dataResponse = responses.json().then(res => {
                console.log("api post", res)
            })
        })
    }

    const goToContestList = (value) => {
        console.log("banner-game",value)
        //navigate('/contest/' + value)
        window.location.href='/contest/' + value;  
      //  window.location.href='/contest/637f4956dad1874c4b2f508e'; 
    }

    return (
        <section className="home-banner">
            <div className="container-fluid px-0">
                <div className="swiper bannerSwiper">
                    <div className="swiper-wrapper">
                        <Swiper
                            // modules={[Virtual]}
                            className='bannerSwiper'
                            modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                            spaceBetween={14}
                            // onSlideChange={() => console.log('slide change')}
                            // onSwiper={(swiper) => console.log(swiper)}
                            effect="coverflow"
                            slidesPerView="auto"
                            // loop={true}
                            grabCursor={true}
                            centeredSlides={true}
                            coverflowEffect={{
                                rotate: 20,
                                stretch: 0,
                                depth: 200,
                                modifier: 1,
                                slideShadows: true
                            }}
                            autoplay={{ delay: 2500, disableOnInteraction: false }}
                            pagination={{
                                el: ".swiper-pagination",
                            }}
                        // virtual

                        >
                            {
                                bannerList?.length > 0 &&
                                bannerList?.map((banner, key) => {
                                    // console.log("banner",banner )
                                    return (
                                        <SwiperSlide virtualIndex={banner._id} key={banner._id} >

                                            <img src= {banner.image?helper.imageBaseUrl + banner.image?.default:""} />
                                            <div className="slide-content">
                                                <h3 className="h1 text-white mb-3">{banner.name}</h3>
                                                {/* <a href="#" className="btn btn-danger">join now</a> */}
                                                <div className="btn btn-danger" onClick={() => goToContestList(banner.game)}>join for free</div>

                                            </div>
                                            {/* <div class="swiper-slide-home">
                                                <img src={banner.image?helper.imageBaseUrl + banner.image?.default:""} />
                                                <div class="playfree" style={{position: 'absolute'}}>
                                                    <h1>{banner.name}</h1>
                                                    <a href="javascript:void(0)" onClick={() => goToContestList(banner.game)}>
                                                        <div class="btn">join for free</div>
                                                    </a>
                                                </div>
                                            </div> */}
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>

                    </div>
                    <div class="swiper-pagination">
                        <div class="swiper-pagination-bullet"></div>
                    </div>
                </div>
            </div>
        </section>
    )

}
export default HomeBanner