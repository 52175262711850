// import faqBgmi from '../../assets/images/faq-bgmi.jpg'
import { Audio, Oval } from 'react-loader-spinner';


const ESportsFaq = (props) => {
    const faqList = props.faqList
    const isShowLoader = props.isLoaderShow
    return (
        <section className="faq-detail">
            <div className="container-fluid"  >
                <div className="accordion" id="faqAccordian">
                    {
                         isShowLoader ?
                         <div className="main-content registration-main">
                                <Oval
                                      height={50}
                                      width={50}
                                      color="#fff"
                                      wrapperStyle={{}}
                                      wrapperClass=""
                                      visible={true}
                                      ariaLabel='oval-loading'
                                      secondaryColor="#ffffffd1"
                                      strokeWidth={2}
                                      strokeWidthSecondary={2}
                                />
                         </div>
                         :
                        faqList.length > 0 ?
                            faqList.map((faq, key) => {
                                return (
                                    <div className="accordion-item" key={key}>
                                        <h2 className="accordion-header" id={"faq" + key}>
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target={"#collapse" + key} aria-expanded="false" aria-controls={"collapse" + key}>
                                                {faq.title}
                                            </button>
                                        </h2>
                                        <div id={"collapse" + key} className="accordion-collapse collapse" aria-labelledby={"faq" + key}
                                            data-bs-parent="#faqAccordian">
                                            <div className="accordion-body">
                                                {/* <p>The room ID and  password for the game will be provided 15 mins before the start of the game</p> */}
                                                {/* <img src={faqBgmi} className="rounded-5 img-fluid" alt="" /> */}
                                                {/* <p>{faq.description.replace(/<(.|\n)*?>/g, '')} </p> */}
                                                <div dangerouslySetInnerHTML={{ __html: faq.description }} />
                                                {/* <img src={faqBgmi} className="rounded-5 img-fluid" alt="" /> */}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <>
                                <div className="main-content registration-main">
                                    <h2 className="text-white">No FAQ Found</h2>
                                </div>
                            </>
                    }

                </div>
            </div>
        </section>
    )

}
export default ESportsFaq