import { useEffect } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";

const NavigationSection = (props) => {
    const location = useLocation()
    const navigate = useNavigate()

    const activeTab = props.activeTab;
    var activeTabHome = "";
    var activeTabReward = "";
    var activeTabAccount = "";

   // useEffect(() => {
        if (activeTab == 'home'){
            activeTabHome = 'active'
        }
        else if (activeTab == 'reward')
            activeTabReward = 'active'
        else if (activeTab == 'account')
        activeTabAccount = 'active'
        else
            activeTabAccount = 'active'
   // }, []);
  
   const onTabChange = (value) => {
    navigate(value); 
    //window.location.href=value
    
  }
    return (
        <nav>
            <div className="container-fluid">
                <ul className="nav">

                    <li className="nav-item">
                       <div onClick={()=>  onTabChange('/home')} className={"nav-link "+activeTabHome} aria-current="page" >
                            <i className="icon icon-home">Home</i>
                            Home
                        </div>
                        {/* <Link onClick={() => onTabChange('/home')} className={"nav-link "+activeTabHome}> <i className="icon icon-home">Home</i> Home</Link> */}
                    </li>

                    <li className="nav-item">

                        <div  onClick={()=>  onTabChange('/rewards')} className={"nav-link "+activeTabReward} >
                            <i className="icon icon-store">Rewards</i>
                            Rewards
                        </div>
                    </li>


                    <li className="nav-item">
                        <div onClick={()=>  onTabChange('/myaccount')} className={"nav-link "+activeTabAccount}  >
                            <i className="icon icon-account">My account</i>
                            My account
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    )

}
export default NavigationSection