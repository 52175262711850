
import { SET_APP_TOKEN, UPDATE_USER_DETAILS, SET_CURRENT_GAME_NAME, SET_OPEN_EXIT_POPUP, SET_AGREE_TERMS } from "./user.type";
import userInitialState from "./user.initialstate";


const userReducer = (state = userInitialState, action) => {

    switch (action.type) {
        case UPDATE_USER_DETAILS:
            return {
                ...state,
                userDeatils: action.payload.userDeatils,
                isFirstTime: action.payload.userDeatils.isAgreeTermsAndConditions,
                userAuthToken: action.payload.userAuthToken ? action.payload.userAuthToken : state.userAuthToken
            }

        case SET_APP_TOKEN:
            return {
                ...state,
                userAppToken: action.payload
            }
        case SET_CURRENT_GAME_NAME:
            return {
                ...state,
                userCurrentGameName: action.payload
            }
        case SET_OPEN_EXIT_POPUP:
            return {
                ...state,
                openExitPopup: action.payload
            }
        case SET_AGREE_TERMS: {
            return {
                ...state,
                isAgreeTermsAndConditions: action.payload
            }
        }

        default:
            return state;
    }
};

export default userReducer;