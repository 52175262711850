import calendarIcon from "../../assets/images/Calender.svg";
import backArrow from "../../assets/images/back-arrow.svg";
import shareIcon from "../../assets/images/share-icon.svg";
import joinContestBanner from "../../assets/images/join-contest-banner.jpg";
import trophyIcon from "../../assets/images/Vector.svg";
import trophyIcon2 from "../../assets/images/Trophy.svg";

import infoIcon from "../../assets/images/info-icon.svg";
import mapIcon from "../../assets/images/map-icon.svg";
import perspectiveIcon from "../../assets/images/perspective-icon.svg";
import coinIcon from "../../assets/images/coin-icon.svg";
import DiamondImgIcon from "../../assets/images/diamond_img_icon.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import helper from "../../common/Helper";
import moment from "moment";
import { useLayoutEffect, useRef, useState } from "react";
import { RWebShare } from "react-web-share";
import downchevronIcon from "../../assets/images/down-chevron.svg";
import closeIcon from "../../assets/images/close-btn-1.svg";
import diamondIcon from "../../assets/images/diamond-icon.svg";
import { isIOS } from "react-device-detect";
import { UserAdDisplay } from "../../common/UserAdDisplay";
import { useEffect } from "react";
import { AfterjoinPopup } from "./AfterjoinPopup";
import { MyContestPopup } from "./MyContestPopup";
import { useAdVisibility } from "../../common/UseAdVisibility";

const AfterJoin = (props) => {
  UserAdDisplay("Gamerji_AfterJoin_Sticky_320x50");
  localStorage.getItem("joincontestAd", "true");
  const [adJoincontestPopup, setadJoincontestPopup] = useState(false);
  const [admycontestPopup, setadmycontestPopup] = useState(false);
  const { isVisible } = useAdVisibility();

  useEffect(() => {
    const JoinAd = localStorage.getItem("joincontestAd");

    if (JoinAd === "true") {
      setadJoincontestPopup(true);

      localStorage.setItem("joincontestAd", "false");
    }
  }, []);

  useEffect(() => {
    console.log("Is Visible:", isVisible);
  }, [isVisible]);

  useEffect(() => {
    const MyContest = localStorage.getItem("isVisibleAD");

    if (MyContest === "true") {
      console.log("fxdgh");
      setadmycontestPopup(true);

      localStorage.setItem("isVisibleAD", "false");
    }
  }, []);

  const contestDetails = props.contestDetails;
  const navigate = useNavigate();
  const location = useLocation();
  var device =
    localStorage.getItem("persist:root") &&
    JSON.parse(JSON.parse(localStorage.getItem("persist:root"))?.user)
      ?.userDeatils?.platform;
  //console.log("device:", device)
  const [ruleOpen, setRuleOpen] = useState(false);

  // console.log("location",location.state.module)

  const stickyHeader = useRef();
  useLayoutEffect(() => {
    const mainHeader = document.getElementById("scroll-bg");
    //   let fixedTop = stickyHeader.current.offsetTop
    // helper.addLog("sti", stickyHeader.current)
    if (stickyHeader.current) {
      const { scrollTop, scrollHeight, clientHeight } = stickyHeader.current;
      // helper.addLog("scrollTop", scrollTop)

      const fixedHeader = () => {
        if (window.pageYOffset > scrollTop) {
          mainHeader.classList.add("fixed-bg");
        } else {
          mainHeader.classList.remove("fixed-bg");
        }
      };
      window.addEventListener("scroll", fixedHeader);
    }
  }, []);

  const [AfterJoin_StickyAD, setAfterJoin_StickyAD] = useState(0);
  const [isVisible2, setIsVisible2] = useState(false);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedAfterJoin_StickyAD = localStorage.getItem("AfterJoin_StickyAD");
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedTimestamp && currentTime > storedTimestamp) {
      localStorage.setItem("AfterJoin_StickyAD", 0);
      localStorage.setItem("timestamp", new Date());
      setAfterJoin_StickyAD(0);
    } else {
      let newAfterJoin_StickyAD = Number(storedAfterJoin_StickyAD) + 1;

      let date = new Date(new Date().getTime() + 15 * 1000);
      localStorage.setItem("AfterJoin_StickyAD", newAfterJoin_StickyAD);
      // localStorage.setItem("timestamp", moment().format("DD-MM-YYYY"));
    }
    setTimeout(() => {
      // const storedAfterJoin_StickyAD = localStorage.getItem("AfterJoin_StickyAD");
      const storedAfterJoin_StickyAD = localStorage.getItem("count");
      let newAfterJoin_StickyAD = Number(storedAfterJoin_StickyAD);
      // console.log(newAfterJoin_StickyAD, "newAfterJoin_StickyAD");
      setAfterJoin_StickyAD(newAfterJoin_StickyAD);
    }, 2000);
  }, []);

  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   console.log(AfterJoin_StickyAD);
    //   if (AfterJoin_StickyAD > 0 && AfterJoin_StickyAD % 3 === 0) {
    //     setIsVisible2(true);
    //   } else {
    //     setIsVisible2(false);
    //   }
    // }, 10);

    // return () => clearTimeout(timeout);
    if (AfterJoin_StickyAD >= 3) {
      setIsVisible2(true);
    } else {
      setIsVisible2(false);
    }
  }, [AfterJoin_StickyAD]);

  return (
    <div>
      <div className="width">
        <header className="" id="scroll-bg" ref={stickyHeader}>
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-10 d-flex align-items-center">
                <Link
                  onClick={() =>
                    navigate(
                      location && location.state && location.state.module
                        ? location.state.module === "registration_done"
                          ? -3
                          : -2
                        : -2
                    )
       
                   

                  }
                >
                  <img src={backArrow} alt="" />
                </Link>
                {/* <button onClick={() => navigate(location?.state?.module ? location.state.module === 'registration_done' ? -4 : -1 : -1)} className='btn'><img src={backArrow} alt="" /></button> */}
                {/* <a href="#"><img src={backArrow} alt="" /></a> */}
                <h1 className="mb-0 ms-2 text-white h2 fw-normal">
                  {contestDetails.title}
                </h1>
              </div>
              <div className="col-2 d-flex justify-content-end align-items-center">
                <RWebShare
                  data={{
                    text: "Hey! Join me on Vi for this exciting match. Click on hte following link and let's play together",
                    url:
                      "https://www.myvi.in/VI_ESPORT_GAMES_WITH_CONTENT_ID$" +
                      contestDetails.game,
                  }}
                  onClick={() => console.log("shared successfully!")}
                >
                  {
                    <a className="share-link">
                      <img src={shareIcon} alt="" />
                    </a>
                  }
                </RWebShare>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div className=" joinContest-main" id="joinContest-main">
        <div className="container-fluid px-0">
          <div className="joinContest-cover">
            <img
              className="w-100"
              src={
                contestDetails?.afterJoinImage?.default
                  ? helper.imageBaseUrl +
                    contestDetails?.afterJoinImage?.default
                  : joinContestBanner
              }
              alt=""
            />
          </div>
          <div className="joinContest-content">
            <h2>{contestDetails?.gameAry?.name}</h2>
            <div className="d-flex justify-content-between pt-1">
              <div className="d-flex align-items-center">
                <img src={calendarIcon} alt="" />
                {/* <p className="ms-2 mb-0 h3">22 Sept 22’, 2:30PM</p> */}
                <p className="ms-2 mb-0 h3">
                  {moment(contestDetails.dateTime).format("DD MMM YY,h:mmA")}
                </p>
              </div>
              <div
                className="d-flex align-items-center"
                data-bs-toggle="modal"
                data-bs-target={"#prizepoolModal"}
              >
                {/*<img src={trophyIcon2} alt="" />*/}
                <img src={DiamondImgIcon} alt="" />
                <p className="ms-2 mb-0 h3">
                  {contestDetails.prizePool}{" "}
                  <img src={downchevronIcon} alt="" />
                </p>
              </div>
            </div>
            <div className="contest-card card mt-3">
              <div className="card-body">
                <div className="card-header text-center">
                  <h2 className="text-white mb-0">
                    Username - {contestDetails.userInGameName}
                  </h2>
                </div>
                {/* <div className="card-content">
                                    <div className="row align-items-end">
                                        <div className="col-3">
                                            <div className="">
                                                <h3 className="mb-1 text-white fw-bold d-flex align-items-center">{contestDetails.roomId ? contestDetails.roomId : '-'}</h3>
                                                <p className="mb-0 h4 text-muted">Room ID</p>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="">
                                                <h3 className="mb-1 text-white fw-bold d-flex align-items-center">{contestDetails.roomPassword ? contestDetails.roomPassword : '-'}</h3>
                                                <p className="mb-0 h4 text-muted">password</p>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-label="Basic example" style={{ width: helper.calculatePercentage(contestDetails.totalJoinedPlayers, contestDetails.totalPlayers) + '%' }} aria-valuenow={helper.calculatePercentage(contestDetails.totalJoinedPlayers, contestDetails.totalPlayers)} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <p className="text-white h4 mt-2 mb-0"><strong>{contestDetails.totalJoinedPlayers}</strong>/{contestDetails.totalPlayers} players joined </p>
                                        </div>
                                    </div>
                                </div> */}
                {contestDetails.status === "waiting" ||
                contestDetails.status === "started" ||
                contestDetails.status === "inProcess" ||
                contestDetails.status === "review" ||
                contestDetails.status === "cancelled" ? (
                  <div className="card-content">
                    <div className="row align-items-end">
                      <div className="col-3">
                        <div className="">
                          <h3
                            className="mb-1 text-white fw-bold d-flex align-items-center"
                            style={{ fontSize: "12px" }}
                          >
                            {contestDetails.roomId
                              ? contestDetails.roomId
                              : "-"}
                          </h3>
                          <p className="mb-0 h4 text-muted">Room ID</p>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="">
                          <h3
                            className="mb-1 text-white fw-bold d-flex align-items-center"
                            style={{ fontSize: "12px" }}
                          >
                            {contestDetails.roomPassword
                              ? contestDetails.roomPassword
                              : "-"}
                          </h3>
                          <p className="mb-0 h4 text-muted">Password</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-label="Basic example"
                            style={{
                              width:
                                helper.calculatePercentage(
                                  contestDetails.totalJoinedPlayers,
                                  contestDetails.totalPlayers
                                ) + "%",
                            }}
                            aria-valuenow={helper.calculatePercentage(
                              contestDetails.totalJoinedPlayers,
                              contestDetails.totalPlayers
                            )}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <p className="text-white h4 mt-2 mb-0">
                          <strong>{contestDetails.totalJoinedPlayers}</strong>/
                          {contestDetails.totalPlayers} players joined{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : contestDetails.status === "completed" ? (
                  <div className="card-content">
                    <div className="row align-items-center">
                      <div className="col-3">
                        <div className="">
                          <h3 className="mb-1 text-white fw-bold d-flex align-items-center">
                            {contestDetails?.join.kills}
                          </h3>
                          <p className="mb-0 h4 text-muted">Kills</p>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="">
                          <h3 className="mb-1 text-white fw-bold d-flex align-items-center">
                            {contestDetails?.join.rank}
                          </h3>
                          <p className="mb-0 h4 text-muted">Rank</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <Link
                          className="fw-medium text-decoration-underline text-silver"
                          to={"/leaderboard/" + contestDetails._id}
                          state={{ gameName: contestDetails.gameAry.name }}
                        >
                          view leaderboard
                        </Link>
                        {/* <a href="#" className="fw-medium text-decoration-underline text-silver">view leaderboard</a> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            {!contestDetails.roomPassword || !contestDetails.roomId ? (
              <p className="d-flex mt-3 h4 text-silver">
                <span className="me-2">
                  <img src={infoIcon} alt="" />
                </span>{" "}
                The room ID and password for the game will be provided 15 mins
                before the start of the game
              </p>
            ) : (
              <></>
            )}
            <div className="accordion mt-5" id="faqAccordian">
              <div className="accordion-item">
                <h2 className="accordion-header" id="faqOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    more details
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="faqOne"
                  data-bs-parent="#faqAccordian"
                >
                  <div className="accordion-body">
                    <div className="row">
                      {/* {
                                                contestDetails?.titles?.length > 0 &&
                                                contestDetails.titles.map((title, key) => {
                                                    return (
                                                        <div className={"col-" + 12 / Math.round(contestDetails?.titles?.length + 1) + ""}>
                                                            <div className="d-flex align-items-center">
                                                                <img src={perspectiveIcon} alt="" />
                                                                <p className="ms-2 mb-0 h3 text-muted">{title.value}</p>
                                                            </div>
                                                        </div>
                                                    )

                                                })
                                            } */}
                      <div className={"col-4"}>
                        <div className="d-flex align-items-center">
                          <img src={mapIcon} alt="" />
                          <p className="ms-1 mb-0 h3 text-muted">
                            {contestDetails?.titles?.length > 0
                              ? contestDetails?.titles[0]?.value ?? "-"
                              : "-"}
                          </p>
                        </div>
                      </div>
                      {/* {contestDetails?.titles?.length > 1 && contestDetails?.titles[1] && */}
                      {contestDetails?.titles?.length > 0 &&
                        contestDetails?.titles[1].isSelection && (
                          <div className={"col-4"}>
                            <div className="d-flex align-items-center">
                              <img src={perspectiveIcon} alt="" />
                              <p className="ms-1 mb-0 h3 text-muted">
                                {contestDetails?.titles?.length > 1
                                  ? contestDetails?.titles[1]?.value ?? "-"
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        )}
                      <div className={"col-4"}>
                        <div className="d-flex align-items-center">
                          <img src={coinIcon} alt="" />
                          <p className="ms-1 mb-0 h3 text-muted">
                            {contestDetails.entryFee == 0
                              ? "FREE"
                              : contestDetails.entryFee ?? "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                    {contestDetails.shortCode && (
                      <span class="badge mt-4">
                        ID - {contestDetails.shortCode}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="faqTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    onClick={() => setRuleOpen(!ruleOpen)}
                  >
                    rules
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="faqTwo"
                  data-bs-parent="#faqAccordian"
                >
                  <div className="accordion-body">
                    {
                      // contestDetails?.rules?.replace(/(<([^>]+)>)/ig, '')

                      <div
                        dangerouslySetInnerHTML={{
                          __html: contestDetails?.rules,
                        }}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
            {contestDetails.status === "completed" ? (
              <div
                id="other-games"
                className={
                  "d-flex justify-content-center  fixed-button" +
                  (ruleOpen ? " btn-shadow" : "")
                }
              >
                {/* <a href="#" class="btn btn-outline-gray"> */}
                <Link
                  to={"/contest/" + contestDetails?.gameAry?._id}
                  className="btn btn-outline-gray"
                >
                  join other games
                </Link>
                {/* </a> */}
              </div>
            ) : (
              <div
                id="other-games"
                className={
                  "d-grid     fixed-button" + (ruleOpen ? " btn-shadow" : "")
                }
              >
                {console.log("device", isIOS)}
                {
                  //  downloadLink downloadLinkIos name
                  isIOS ? (
                    <a
                      href={contestDetails?.gameAry?.downloadLinkIos}
                      target="_blank"
                      className="btn btn-danger btn-lg"
                    >
                      open {contestDetails?.gameAry?.name} App
                    </a>
                  ) : (
                    <a
                      href={contestDetails?.gameAry?.downloadLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-danger btn-lg"
                    >
                      open {contestDetails?.gameAry?.name} App
                    </a>
                  )
                }
              </div>
            )}
          </div>
          <div
            id="Gamerji_AfterJoin_Sticky_320x50"
            style={{
              display: isVisible2 ? "block" : "none",
              position: "fixed",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              bottom: 0,
              left: 0,
              right: 0,
              color: "white",
            }}
          ></div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        class="modal fade common-modal"
        id={"prizepoolModal"}
        tabindex="-1"
        aria-labelledby="prizepoolModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <a
                href="#"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src={closeIcon} alt="" />
              </a>
              <div class="d-flex align-items-center justify-content-between">
                <h2>prize pool breakup</h2>
                <div class="d-flex align-items-center icon-title">
                  <img src={diamondIcon} alt="" />
                  <h3 id="prizepool" class="ms-1 my-0 fw-bold">
                    {contestDetails?.prizePool}
                  </h3>
                </div>
              </div>
              <hr />

              {contestDetails?.prizePoolInfo?.length > 0 ? (
                contestDetails?.prizePoolInfo?.map((p1, key) => {
                  // console.log('44',p1)
                  return (
                    // <h1>ff</h1>
                    <div class="d-flex justify-content-between align-items-center py-2">
                      <h3 class="m-0" id={"rank" + key}>
                        {p1.rankFrom === p1.rankTo ? (
                          <span>Rank {p1.rankFrom} </span>
                        ) : (
                          <span>
                            Rank {p1.rankFrom}
                            {p1.rankTo !== "0" && " to "}
                            {p1.rankTo !== "0" && p1.rankTo}
                          </span>
                        )}
                      </h3>
                      <div class="d-flex align-items-center icon-title">
                        <img src={diamondIcon} alt="" />
                        <h3 class="ms-1 my-0" id={"amount" + key}>
                          {p1.amount}
                        </h3>
                      </div>
                    </div>
                  );
                })
              ) : (
                // <div className="">
                //     <h2 className="text-white m-0">No Prizepool added for this contest.</h2>
                // </div>
                <div class="d-flex justify-content-between align-items-center py-2">
                  <h3 class="m-0" id={"rank"}>
                    <span> Per Kill</span>
                  </h3>
                  <div class="d-flex align-items-center icon-title">
                    <img src={diamondIcon} alt="" />
                    <h3 class="ms-1 my-0" id={"amount"}>
                      {contestDetails.killPoints}
                    </h3>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isVisible && (
        <AfterjoinPopup
          setadJoincontestPopup={setadJoincontestPopup}
          props={props}
        />
      )}
      {admycontestPopup && (
        <MyContestPopup
          setadmycontestPopup={setadmycontestPopup}
          props={props}
        />
      )}
    </div>
  );
};
export default AfterJoin;
