import Index from "../view/myAccount/Index"
import { useOutletContext } from "react-router-dom";
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import NavigationSection from "../component/NavigationSection";


function MyAccountController(){
    const { setTitle } = useOutletContext();
    const user = useSelector((state) => state.user);
    const [userDetails, setUserDetails] = useState({
        mobile:"",
        email:"",
        name:""
    })

    useEffect(() => {
        setTitle('my account')
        
        setUserDetails({
            ...userDetails,
            mobile:user?.userDeatils?.phone
        })
    }, [user?.userDeatils]);

    return(
        <>
        <NavigationSection activeTab='account'/>

        <Index
        userDetails={userDetails}
        />
        </>
    )

}
export default MyAccountController