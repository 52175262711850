import coinIcon from "../../assets/images/coin-gold.svg";
import BuyCoins from "./BuyCoins";

const Index = (props) => {
  const buyCoins = [
    { coins: 100, amount: 5 },
    { coins: 200, amount: 8 },
    { coins: 300, amount: 10 },
    { coins: 400, amount: 15 },
    { coins: 500, amount: 20 },
  ];
  // const buyCoins = props.buyCoinList;
  return (
    <>
      <section className="coin-section">
        <div className="container-fluid">
          <div class="d-flex justify-content-between mb-4">
            <h3 class="text-white mb-0 h1 fw-bold">buy coins</h3>
            <span className="badge coin-badge">
              <img className="me-1" src={coinIcon} alt="" />
              400
            </span>
          </div>
        </div>
      </section>
      <section class="coin-buy">
        <div class="container-fluid">
          {/* <BuyCoins /> */}
          {buyCoins?.length > 0 ? (
            buyCoins?.map((buyCoin, key) => {
              return <BuyCoins key={key} myKey={key} {...buyCoin} />;
            })
          ) : (
            <></>
          )}
        </div>
      </section>
    </>
  );
};
export default Index;
