import {
    BrowserRouter,
    Routes,
    Route,
    useNavigate,
    useHistory,
    useLocation
} from "react-router-dom";
import RegistrationDone from "../component/RegistrationDone";
import MyContestController from "../controllers/MyContestController";
import GameFaqController from "../controllers/GameFaqController";
import ContestController from "../controllers/ContestController";
import HomeController from "../controllers/HomeControllers";
import JoinGameController from "../controllers/JoinGameController";
import BuyCoinController from "../controllers/BuyCoinController";
import GameLayout from "./GameLayout";
import HomeLayout from "./HomeLayout";
import ListLayout from "./ListLayout";
import AfterJoinController from "../controllers/AfterJoinController";
import MyRewardsController from "../controllers/MyRewardsController";
import RewardCouponController from "../controllers/RewardCouponController";
import MyAccountController from "../controllers/MyAccountController";
import AccountFaqController from "../controllers/AccountFaqController";
import LeaderboardController from "../controllers/LeaderboardController";
import TransectionController from "../controllers/TransectionController";

import AllRewards from "../view/rewards/AllRewards";
import AllRewardsController from "../controllers/AllRewardsController";

import BuyGems from "../view/rewards/BuyGems";
import CustomerCare from "../view/customerCare/CustomerCare";
import CustomerCareTickets from "../view/customerCare/CustomerCareTickets";
import RewardCoupon from "../view/rewards/RewardCoupon";
import CustomerCareChats from "../view/customerCare/CustomerCareChats";
import TremsAndConditionController from "../controllers/TremsAndConditionController";
import TncController from "../controllers/TncController";
import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MyGamerIdController from "../controllers/MyGamerIdController";
import ESportsFaqController from "../controllers/ESportsFaqController";
import PaymentStatus from "../view/paymentStatus/PaymentStatus";

export default function MainRoutes() {
    const location = useLocation()

    useEffect(() => {

        if (location.pathname === '/home' || location.pathname === '/') {

            let value = JSON.stringify({ "tag": "esports_home" });
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(value);
            }
        } else {
            let value = JSON.stringify({ "tag": "esports_false" });
            if (window.ReactNativeWebView) {

                window.ReactNativeWebView.postMessage(value);

            }
        }


    }, [location])


    return (
        <>
            <Routes>
                <Route path="/" element={<HomeLayout />}>
                    <Route path="home" element={<HomeController />} />
                    <Route path="/:appToken" element={<HomeController />} />
                    <Route path="/" element={<HomeController />} />
                </Route>

                <Route path="/" element={<ListLayout />}>
                    <Route path="contest/:gameId" element={<ContestController />} />
                    <Route path="/gamefaq/:gameId" element={<GameFaqController />} />
                    <Route path="/mycontest/:gameId" element={<MyContestController />} />
                    {/* <Route path="/myrewards" element={<MyRewardsController />} /> */}
                    <Route path="/myaccount" element={<MyAccountController />} />
                    <Route path="/faq" element={<AccountFaqController />} />
                    <Route path="/buycoin" element={<BuyCoinController />} />
                    <Route path="/leaderboard/:contestId" element={<LeaderboardController />} />
                    <Route path="/mytransactions" element={<TransectionController />} />
                    <Route path="/registration/done/:contestId" element={<RegistrationDone />} />
                    <Route path="/terms-and-conditions" element={<TremsAndConditionController />} />
                    <Route path="/tnc/:slug" element={<TncController />} />
                    <Route path="/mygamerid" element={<MyGamerIdController />} />
                    <Route path="/ESportsFaq" element={<ESportsFaqController />} />



                </Route>

                <Route path="/" element={<GameLayout />}>
                    <Route path="/game/join/:contestId/:gameId" element={<JoinGameController />} />
                    <Route path="/coupon" element={<RewardCouponController />} />

                </Route>

                <Route path="/afterjoin/:contestId" element={<AfterJoinController />} />
                <Route path="/myrewards" element={<MyRewardsController />} />
                <Route path="/rewards" element={<AllRewardsController />} />
                <Route path="/rewardcoupon" element={<RewardCoupon />} />
                <Route path="/buy-gems" element={<BuyGems />} />
                <Route path="/customer-care" element={<CustomerCare />} />
                <Route path="/customer-care-tickets" element={<CustomerCareTickets />} />
                <Route path="/customer-care-Chats/:ticketId" element={<CustomerCareChats />} />
                <Route path="/pg-status" element={<PaymentStatus />} />


            </Routes>
        </>

    )

}