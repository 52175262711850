import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DiamondImgIcon from "../assets/images/diamond_img_icon.png";
import userServices from "../redux/user/user.services";
import { Link } from "react-router-dom";
import PlusImgIcon from "../assets/images/plus-icon.svg";

const CoinSection = () => {
  const user = useSelector((state) => state.user);
  const [totalBalance, setTotalBalance] = useState(0);
  const balance =
    parseInt(user?.userDeatils?.wallet[0]?.winningAmount) +
    parseInt(user?.userDeatils?.wallet[0]?.packs);

  useEffect(() => {
    (async () => {
      if (user?.userDeatils?._id) {
        const userDeatils = await userServices.getUserDetails(
          user?.userDeatils?._id
        );
        const wallet = userDeatils && userDeatils?.wallet[0];
        let userWalletTotal = userServices.getWalletTotalAmount(wallet);
        setTotalBalance(userWalletTotal);
        localStorage.setItem("userBalance",userWalletTotal)
        
      }
    })();
  }, [user]);

  // useEffect(() => {
  //     (async () => {

  //         console.log("getInstalledRelatedApps","call");

  //        const relatedApps = await navigator.getInstalledRelatedApps();
  //         //console.log("relatedApps",relatedApps);

  //     if ('Slack' in window.navigator) {
  //          relatedApps = await navigator.getInstalledRelatedApps();
  //         relatedApps.forEach((app) => {
  //           //if your PWA exists in the array it is installed
  //           console.log("getInstalledRelatedApps"+app.platform, app.url);
  //         });
  //       }
  //     })()
  // }, [])

  return (
    <section className="coin-section">
      <div className="container-fluid">
        <div className="d-flex justify-content-between mb-4">
          <h3 className="text-white mb-0 h1 fw-normal">
            {user?.userDeatils?.name}
          </h3>
          <div className="flex gap">
            <span className="badge coin-badge">
              <img className="me-1" src={DiamondImgIcon} alt="" />
              {totalBalance}
            </span>
            <Link
              className="all_rewards_header_coin_plus_icon"
              to={"/buy-gems"}
            >
              <img className="plus_icon" src={PlusImgIcon} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CoinSection;
