import upcoming1 from "../assets/images/upcoming-1.jpg";
import calendarIcon from "../assets/images/calendar-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { useNavigate } from "react-router-dom";
import helper from "../common/Helper";
import { useState,useEffect } from "react";
import { UserAdDisplay } from "../common/UserAdDisplay";

const UpcomingBattles = (props) => {
  const [isAgreeTermsAndConditions, setIsAgreeTermsAndConditions] =
    useState(true);
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();
  const upcomingContestList = props.upcomingContestList;
  // console.log("upcomingContestList",upcomingContestList)

  // {
  //     user && user.isFirstTime ? (
  //       <div></div>
  //     ) : (
  //         UserAdDisplay("Gamerji_HP_320x50")
  //     );
  //   }
  useEffect(() => {
    if(isAgreeTermsAndConditions) {
      setTimeout(() => {
        UserAdDisplay("Gamerji_HP_320x50")
      }, 2000);
    }
  }, [isAgreeTermsAndConditions]);
  // {isAgreeTermsAndConditions ? (
  //   UserAdDisplay("Gamerji_HP_320x50")
  // ) : (
  //   <></>
  // )}

  useEffect(() => {
   
    

    localStorage.setItem("isVisibleAD", "true")
  }, []);

  useEffect(() => {
    // console.log(user.isFirstTime);
    setIsAgreeTermsAndConditions(user.isAgreeTermsAndConditions);
    // console.log(user.isAgreeTermsAndConditions);
  }, [user.isAgreeTermsAndConditions]);

  const goToContestDetail = (contest) => {
    // console.log("contest",contest);
    //navigate('/afterjoin/' + contest.contest)
    window.location.href = "/afterjoin/" + contest.contest;
  };

  //UserAdDisplay("Gamerji_HP_320x50");
  const [myupcommingAd, setmyupcommingAd] = useState(0);
  const [isVisible2, setIsVisible2] = useState(false);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedCount = localStorage.getItem("myupcommingAd");
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedTimestamp && currentTime > storedTimestamp) {
      localStorage.setItem("myupcommingAd", 0);
      localStorage.setItem("timestamp", new Date());
      setmyupcommingAd(0);
    } else {
      let newCount = Number(storedCount) + 1;

      // let date = new Date(new Date().getTime() + 15 * 1000);
      localStorage.setItem("myupcommingAd", newCount);
      // localStorage.setItem("timestamp", moment().format("DD-MM-YYYY"));
    }
    setTimeout(() => {
      // const storedCount = localStorage.getItem("myupcommingAd");
      const storedCount = localStorage.getItem("count");
      let newCount = Number(storedCount);
      // console.log(newCount, "newCount");
      setmyupcommingAd(newCount);
    }, 2000);
  }, []);

  useEffect(() => {
   
    if (myupcommingAd >= 3) {
      setIsVisible2(true);
    } else {
      setIsVisible2(false);
    }
  }, [myupcommingAd]);








  return (
    <section className="upcoming-battle py-4" style={{ marginTop: "-70px" }}>
      <div className="container-fluid pt-1 pe-0 pl-0">
        <h2 className="h1 text-white mb-3">
          upcoming battles - Brace yourself
        </h2>
        <div className="swiper upcomingBattleSwiper">
          <div className="swiper-wrapper">
            <Swiper
              className={
                upcomingContestList?.length === 1
                  ? "upcomingBattleSwiper onebattel"
                  : "upcomingBattleSwiper"
              }
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              effect="coverflow"
              spaceBetween={10}
              slidesPerView="1.5"
              centeredSlides={false}
              loop={false}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 0,
                modifier: 1,
                slideShadows: true,
              }}
              pagination={{
                el: ".swiper-pagination1",
                clickable: true,
              }}
              //loop={true}
              // autoplay={{ delay: 2500, disableOnInteraction: false }}
            >
              {upcomingContestList?.length > 0 &&
                upcomingContestList?.map((con, key) => {
                  // console.log("con"+key,con)
                  return (
                    <SwiperSlide key={con._id}>
                      {/* <div class="swiper-slide-upcoming">
                                               <img src={con?.contestData?.displayImage} alt=""/>
                                                <div class="free-fire" style={{position: 'absolute'}}>
                                                    <h1 class="h2 text-white mb-2">{con?.contestData?.title}</h1>
                                                    <div className="date d-flex align-items-center">
                                                        <img src={calendarIcon} alt="" />
                                                        <p className="ms-2 mb-0 h4 text-muted">{moment(con?.contestData?.dateTime).format('DD MMM YY, h:mmA')}</p>

                                                    </div>
                                                </div>
                                            </div> */}
                      <div
                        className="upcomingBattle-card card"
                        onClick={() => goToContestDetail(con)}
                      >
                        {/* <img className="thumb" src={upcoming1} /> */}
                        {/* { con.contestData.featuredImage && */}
                        <img
                          className="thumb"
                          src={con?.contestData?.displayImage}
                          alt=""
                        />
                        {/* } */}
                        <div className="card-content">
                          <h3 className="h2 text-white mb-2">
                            {con?.contestData?.title}
                          </h3>
                          <div className="date d-flex align-items-center">
                            <img src={calendarIcon} alt="" />
                            <p className="ms-2 mb-0 h4 text-muted">
                              {moment(con?.contestData?.dateTime).format(
                                "DD MMM YY, h:mmA"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}

              {/* <SwiperSlide>

                                <div className="upcomingBattle-card card">
                                    <img className="thumb" src={upcoming1} />
                                    <div className="card-content">
                                        <h3 className="h2 text-white mb-2">Free Fire Max</h3>
                                        <div className="date d-flex align-items-center">
                                            <img src={calendarIcon} alt="" />
                                            <p className="ms-2 mb-0 h4 text-muted">22 Sep 22, 2:30PM</p>
                                        </div>
                                    </div>
                                </div>

                            </SwiperSlide>

                            <SwiperSlide>

                                <div className="upcomingBattle-card card">
                                    <img className="thumb" src={upcoming1} />
                                    <div className="card-content">
                                        <h3 className="h2 text-white mb-2">Free Fire Max</h3>
                                        <div className="date d-flex align-items-center">
                                            <img src={calendarIcon} alt="" />
                                            <p className="ms-2 mb-0 h4 text-muted">22 Sep 22, 2:30PM</p>
                                        </div>
                                    </div>
                                </div>

                            </SwiperSlide>

                            <SwiperSlide>

                                <div className="upcomingBattle-card card">
                                    <img className="thumb" src={upcoming1} />
                                    <div className="card-content">
                                        <h3 className="h2 text-white mb-2">Free Fire Max</h3>
                                        <div className="date d-flex align-items-center">
                                            <img src={calendarIcon} alt="" />
                                            <p className="ms-2 mb-0 h4 text-muted">22 Sep 22, 2:30PM</p>
                                        </div>
                                    </div>
                                </div>

                            </SwiperSlide>

                            <SwiperSlide>

                                <div className="upcomingBattle-card card">
                                    <img className="thumb" src={upcoming1} />
                                    <div className="card-content">
                                        <h3 className="h2 text-white mb-2">Free Fire Max</h3>
                                        <div className="date d-flex align-items-center">
                                            <img src={calendarIcon} alt="" />
                                            <p className="ms-2 mb-0 h4 text-muted">22 Sep 22, 2:30PM</p>
                                        </div>
                                    </div>
                                </div>

                            </SwiperSlide>

                            <SwiperSlide>

                                <div className="upcomingBattle-card card">
                                    <img className="thumb" src={upcoming1} />
                                    <div className="card-content">
                                        <h3 className="h2 text-white mb-2">Free Fire Max</h3>
                                        <div className="date d-flex align-items-center">
                                            <img src={calendarIcon} alt="" />
                                            <p className="ms-2 mb-0 h4 text-muted">22 Sep 22, 2:30PM</p>
                                        </div>
                                    </div>
                                </div>

                            </SwiperSlide>
                            
                            <SwiperSlide>

                                <div className="upcomingBattle-card card">
                                    <img className="thumb" src={upcoming1} />
                                    <div className="card-content">
                                        <h3 className="h2 text-white mb-2">Free Fire Max</h3>
                                        <div className="date d-flex align-items-center">
                                            <img src={calendarIcon} alt="" />
                                            <p className="ms-2 mb-0 h4 text-muted">22 Sep 22, 2:30PM</p>
                                        </div>
                                    </div>
                                </div>

                            </SwiperSlide> */}
              {/**code below is add to adjust slider functionality  */}
              {/* {
                                upcomingContestList?.length > 0 ?
                                <SwiperSlide>
                                <div className="" style={{marginLeft:"10px"}} >
                                    <img className="thumb" alt="" />
                                    <div className="card-content">
                                        <h3 className="h2 text-white mb-2"></h3>
                                        <div className="date d-flex align-items-center">
                                            <img alt="" />
                                            <p className="ms-2 mb-0 h4 text-muted"></p>

                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            :""
                            } */}
            </Swiper>

            {/* <div className="swiper-slide">
                        <div className="upcomingBattle-card card">
                            <img className="thumb" src={upcoming1} />
                            <div className="card-content">
                                <h3 className="h2 text-white mb-2">Free Fire Max</h3>
                                <div className="date d-flex align-items-center">
                                    <img src="assets/images/calendar-icon.svg" alt=""/>
                                    <p className="ms-2 mb-0 h4 text-muted">22 Sep 22, 2:30PM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="upcomingBattle-card card">
                            <img className="thumb" src={upcoming1} />
                            <div className="card-content">
                                <h3 className="h2 text-white mb-2">Free Fire Max</h3>
                                <div className="date d-flex align-items-center">
                                    <img src="assets/images/calendar-icon.svg" alt=""/>
                                    <p className="ms-2 mb-0 h4 text-muted">22 Sep 22, 2:30PM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="upcomingBattle-card card">
                            <img className="thumb" src={upcoming1} />
                            <div className="card-content">
                                <h3 className="h2 text-white mb-2">Free Fire Max</h3>
                                <div className="date d-flex align-items-center">
                                    <img src="assets/images/calendar-icon.svg" alt=""/>
                                    <p className="ms-2 mb-0 h4 text-muted">22 Sep 22, 2:30PM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="upcomingBattle-card card">
                            <img className="thumb" src={upcoming1} />
                            <div className="card-content">
                                <h3 className="h2 text-white mb-2">Free Fire Max</h3>
                                <div className="date d-flex align-items-center">
                                    <img src="assets/images/calendar-icon.svg" alt=""/>
                                    <p className="ms-2 mb-0 h4 text-muted">22 Sep 22, 2:30PM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="upcomingBattle-card card">
                            <img className="thumb" src={upcoming1} />
                            <div className="card-content">
                                <h3 className="h2 text-white mb-2">Free Fire Max</h3>
                                <div className="date d-flex align-items-center">
                                    <img src="assets/images/calendar-icon.svg" alt=""/>
                                    <p className="ms-2 mb-0 h4 text-muted">22 Sep 22, 2:30PM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="upcomingBattle-card card">
                            <img className="thumb" src={upcoming1} />
                            <div className="card-content">
                                <h3 className="h2 text-white mb-2">Free Fire Max</h3>
                                <div className="date d-flex align-items-center">
                                    <img src="assets/images/calendar-icon.svg" alt=""/>
                                    <p className="ms-2 mb-0 h4 text-muted">22 Sep 22, 2:30PM</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
          </div>
          <div class="swiper-pagination">
            <div class="swiper-pagination-bullet"></div>
          </div>
          <div class="swiper-pagination1">
            <div class="swiper-pagination-bullet"></div>
          </div>
        </div>
      </div>

      {/* {user && user.isFirstTime ? (
        <div></div>
      ) : (
        <div id="Gamerji_HP_320x50"></div>
      )} */}

      {/* {isAgreeTermsAndConditions ? <div id="Gamerji_HP_320x50" style={{marginTop:"1rem"}}></div> : ""} */}

      {/*<div
        id="Gamerji_HP_320x50"
        style={{
          display: isVisible2 ? "block" : "none",marginTop:"1rem"
        }}
      ></div>*/}
     

      {/* <div id = 'Gamerji_HP_320x50'></div> */}
    </section>
  );
};
export default UpcomingBattles;
