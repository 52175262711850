import couponBanner from "../../assets/images/coupon-banner.jpg";
import couponBrand from "../../assets/images/coupon-brand.jpg";
import copyIcon from "../../assets/images/copy-icon.svg";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useEffect,useState } from "react";
import backArrowImg from "../../assets/images/back-arrow.svg";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import helper from "../../common/Helper";
import { UserAdDisplay } from "../../common/UserAdDisplay";

const RewardCoupon = () => {
  // const { setTitle } = useOutletContext();
  // useEffect(() => {
  //     setTitle('Flipkart')
  // }, []);
  const location = useLocation();
  const rewardProduct = location?.state?.data;
  const navigate = useNavigate();

  const copyCoupnCode = (code) => {
    navigator.clipboard.writeText(code);
    toast("copied");
  };
  UserAdDisplay("Gamerji_MyRewards_Detail_320x50");

  // console.log(rewardProduct.rewardProductDetails.stepsOfRedeem)

  const [rewardCouponAd, setrewardCouponAd] = useState(0);

  const [isVisible2, setIsVisible2] = useState(false);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedrewardCouponAd = localStorage.getItem("rewardCouponAd");
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedTimestamp && currentTime > storedTimestamp) {
      localStorage.setItem("rewardCouponAd", 0);
      localStorage.setItem("timestamp", new Date());
      setrewardCouponAd(0);
    } else {
      let newrewardCouponAd = Number(storedrewardCouponAd) + 1;

      let date = new Date(new Date().getTime() + 15 * 1000);
      localStorage.setItem("rewardCouponAd", newrewardCouponAd);
      // localStorage.setItem("timestamp", moment().format("DD-MM-YYYY"));
    }
    setTimeout(() => {
      // const storedrewardCouponAd = localStorage.getItem("rewardCouponAd");
      const storedrewardCouponAd = localStorage.getItem("count");
      let newrewardCouponAd = Number(storedrewardCouponAd);
      console.log(newrewardCouponAd, "newrewardCouponAd");
      setrewardCouponAd(newrewardCouponAd);
    }, 2000);
  }, []);

  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   console.log(rewardCouponAd);
    //   if (rewardCouponAd > 0 && rewardCouponAd % 3 === 0) {
    //     setIsVisible2(true);
    //   } else {
    //     setIsVisible2(false);
    //   }
    // }, 10);

    // return () => clearTimeout(timeout);
    if (rewardCouponAd >= 3) {
      setIsVisible2(true);
    } else {
      setIsVisible2(false);
    }
  }, [rewardCouponAd]);

  return (
    <>
      <header className="position-fixed">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 d-flex align-items-center">
              <div
                onClick={() => navigate(-1)}
                className="backButtonPadding backButtonAddPadding"
              >
                <img src={backArrowImg} alt="" />
              </div>
              <h1 className="mb-0 ms-3 text-white h2 fw-normal">
                {rewardProduct?.rewardProductDetails?.name}
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="main-content coupon-main">
        <section className="coupon-outer">
          <div className="container-fluid px-0">
            <div className="coupon-cover">
              <img
                className="w-100"
                src={
                  rewardProduct?.rewardProductDetails?.img2?.default
                    ? helper.imageBaseUrl +
                      rewardProduct.rewardProductDetails?.img2?.default
                    : couponBanner
                }
                alt=""
              />
            </div>
            <div className="container-fluid">
              <div className="coupon-info">
                <div className="d-flex align-items-end">
                  <img
                    className="brand-icon"
                    width={"15%"}
                    src={
                      rewardProduct?.rewardProductDetails?.img?.default
                        ? helper.imageBaseUrl +
                          rewardProduct.rewardProductDetails?.img.default
                        : couponBrand
                    }
                    alt=""
                  />
                  <div className="detail ps-3">
                    <h3 className="mb-2">
                      {rewardProduct?.rewardProductDetails?.name}
                    </h3>
                    <p className="mb-0">
                      {rewardProduct?.rewardProductDetails?.description}
                    </p>
                  </div>
                </div>
                <div
                  className="coupon-code"
                  onClick={() =>
                    copyCoupnCode(rewardProduct?.redeemVoucherCode)
                  }
                >
                  <p className="mb-0">
                    {rewardProduct?.redeemVoucherCode ?? "N/A"}
                  </p>
                  <h3
                    className="mb-0 d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <img className="me-2" src={copyIcon} alt="" /> copy
                  </h3>
                </div>
                <p className="mb-0 mt-3 text-gray h4">
                  {moment(rewardProduct?.createdAt).format(
                    "DD MMM YYYY, hh:mmA"
                  )}
                </p>
              </div>
              {rewardProduct?.rewardProductDetails?.stepsOfRedeem && (
                <div className="redeem-steps">
                  <h2 className="mb-3">steps to redeem</h2>
                  {/* {
                                    <div dangerouslySetInnerHTML={{ __html: rewardProduct?.rewardProductDetails?.rewardVoucher?.redemptionInstructions }} />

                                } */}
                  <ul>
                    {rewardProduct?.rewardProductDetails?.stepsOfRedeem &&
                      rewardProduct?.rewardProductDetails?.stepsOfRedeem
                        .length > 0 &&
                      rewardProduct?.rewardProductDetails?.stepsOfRedeem.map(
                        (data, i) => {
                          return (
                            <li>
                              <h4 className="text-gray mb-0">step {i + 1} </h4>
                              <h3 style={{ textTransform: "capitalize" }}>
                                {data.value}
                              </h3>
                            </li>
                          );
                        }
                      )}
                    {/* <li>
                                        <h4 className="text-gray mb-0">step 1 </h4>
                                        <h3>Details of step 1 will be here</h3>
                                    </li> */}
                    {/* <li>
                                        <h4 className="text-gray mb-0">step 2 </h4>
                                        <h3>Details of step 2 will be here</h3>
                                    </li>
                                    <li>
                                        <h4 className="text-gray mb-0">step 3 </h4>
                                        <h3>Details of step 3 will be here</h3>
                                    </li> */}
                  </ul>
                </div>
              )}

              <div
                style={{
                  position: "fixed",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  display: isVisible2 ? "block" : "none",
                }}
              >
                <div id="Gamerji_MyRewards_Detail_320x50"></div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </>
  );
};
export default RewardCoupon;
