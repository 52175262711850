import { useOutletContext, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TncPage from "../view/termsAndConditions/tncpage";
import tAndCApi from "../api/tremsAndCondition";
import { withRouter } from "react-router-dom";

function TncController() {
    const { setTitle } = useOutletContext();
    const [value, setValue] = useState({});
    const perems = useParams()
    var newSlug = "tnc";
    if (perems?.slug) {
        newSlug = perems?.slug
    }
    console.log(newSlug)
      
    useEffect(() => {
        (async () => {
            setTitle('Terms and conditions')
            await getTandC(newSlug)
        })();
    }, []);

    async function getTandC(slug) {
        let data = {}
        data.search = {}
        data.search.slug = slug

        const list = await tAndCApi.getBySlug(data)
        if (list.error) {
            const error = list.error
        } else {
            if (list.data) {
                const data = list.data.data
                if (data.success) {
                    setValue(data.data)

                }else{
                    setValue({})
                }

            }
        }

    }
   


    return (
        <TncPage value={value} />
    )


}
export default TncController