import searchIcon from "../../assets/images/Search.svg";
import { Audio, Oval } from "react-loader-spinner";
import { UserAdDisplay } from "../../common/UserAdDisplay";
// import { useAdVisibility } from "../../common/UseAdVisibility";
import { useState, useEffect } from "react";
const AcaccountFAQAdFaq = (props) => {
  const faqList = props.faqList;
  const onChange = props.onChange;
  const searchValue = props.searchValue;
  const isShowLoader = props.isLoaderShow;

  // const { isVisible } = useAdVisibility();
  let searchInputStyle = {
    background:
      "radial-gradient(159.33% 246.64% at -16.8% -38.01%, rgba(255, 221, 102, 0.25) 0%, rgba(25, 31, 45, 0.25) 100%)" /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    // opacity: 0.6,
    backdropFilter: "blur(21px)",
  };

  // ******logic for the ad when ad count would be independent *****

  const [accountFAQAd, setaccountFAQAd] = useState(0);

  const [isVisible2, setIsVisible2] = useState(false);

    /*let searchInputStyle = {
        background: 'radial-gradient(159.33% 246.64% at -16.8% -38.01%, rgba(255, 221, 102, 0.25) 0%, rgba(25, 31, 45, 0.25) 100%)', /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
/*        opacity: 0.6,
        backdropFilter: 'blur(21px)',
    }*/

  UserAdDisplay("Gamerji_FAQ_300x250");

  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedaccountFAQAd = localStorage.getItem("accountFAQAd");
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedTimestamp && currentTime > storedTimestamp) {
      localStorage.setItem("accountFAQAd", 0);
      localStorage.setItem("timestamp", new Date());
      setaccountFAQAd(0);
    } else {
      let newaccountFAQAd = Number(storedaccountFAQAd) + 1;

      let date = new Date(new Date().getTime() + 15 * 1000);
      localStorage.setItem("accountFAQAd", newaccountFAQAd);
      // localStorage.setItem("timestamp", moment().format("DD-MM-YYYY"));
    }
    setTimeout(() => {
      // const storedaccountFAQAd = localStorage.getItem("accountFAQAd");
      const storedaccountFAQAd = localStorage.getItem("count");
      let newaccountFAQAd = Number(storedaccountFAQAd);
      console.log(newaccountFAQAd, "newaccountFAQAd");
      setaccountFAQAd(newaccountFAQAd);
    }, 2000);
  }, []);

  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   console.log(accountFAQAd);
    //   if (accountFAQAd > 0 && accountFAQAd % 3 === 0) {
    //     setIsVisible2(true);
    //   } else {
    //     setIsVisible2(false);
    //   }
    // }, 10);

    // return () => clearTimeout(timeout);
    if (accountFAQAd >= 3) {
      setIsVisible2(true);
    } else {
      setIsVisible2(false);
    }
  }, [accountFAQAd]);

  return (
    <>
    <section className="my-acaccountFAQAd-faq">
      <div className="container-fluid marginb">
        {/* <form className="search-box mb-4" role="search">
          <input
            className="form-control me-2"
            type="text"
            style={searchInputStyle}
            placeholder="search for your queries"
            aria-label="Search"
            value={searchValue}
            onChange={(e) => onChange(e)}
          />
          <button className="search-btn" type="submit">
            <img className="align-top" src={searchIcon} alt="" />
          </button>
        </form> */}

        <form className="search-box mb-4" role="search">
          <div
            className="search-box mb-4"
            role="search"
            style={{ position: "relative" }}
          >
            <input
              className="form-control me-2 search_inputbox"
              type="text"
              style={searchInputStyle}
              placeholder="search for your queries"
              aria-label="Search"
              value={searchValue}
              onChange={(e) => onChange(e)}
            />
            <button
              className="search-btn"
              type="submit"
              style={{
                position: "absolute",
                right: "5px",
                top: "50%",
                transform: "translateY(-50%)",
                background: "none",
                border: "none",
                padding: 0,
              }}
            >
              <img className="align-top" src={searchIcon} alt="" />
            </button>
          </div>
        </form>

        {faqList.length > 0 ? (
          <>
          
            <div
              className="account-faq faq-detail"
              style={{
                // height: "40vh",
                // overflow: "auto",
                height:  "100vh",
                // overflow :isVisible2? "auto":""

              }}
            >


              <h2 className="mb-2 text-white">popular queries</h2>
              <div className="accordion" id="accordionExample">
                {isShowLoader ? (
                  <div className="main-content registration-main">
                    <Oval
                      height={50}
                      width={50}
                      color="#fff"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffffd1"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                ) : faqList.length > 0 ? (
                  faqList.map((faq, key) => {
                    return (
                      <div className="accordion-item" key={key}>
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapse" + key}
                            aria-expanded="false"
                            aria-controls={"collapse" + key}
                          >
                            {faq.title}
                          </button>
                        </h2>
                        <div
                          id={"collapse" + key}
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            dangerouslySetInnerHTML={{
                              __html: faq.description,
                            }}
                          ></div>
                        </div>
                        {/* <div>khj</div> */}
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}

                {/* <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">how can I earn more coins and make use of it to earn rewards?</button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">To change your username, Go to Settings profile edit username and then save</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">how to change your username?</button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">To change your username, Go to Settings profile edit username and then save</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">how can I score well?</button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div className="accordion-body">To change your username, Go to Settings profile edit username and then save</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">how to join a contest?</button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div className="accordion-body">To change your username, Go to Settings profile edit username and then save</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">how can I earn more coins and make use of it to earn rewards?</button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                <div className="accordion-body">To change your username, Go to Settings profile edit username and then save</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSeven">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">how to change your username?</button>
                            </h2>
                            <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                <div className="accordion-body">To change your username, Go to Settings  profile  edit username and then save</div>
                            </div>
                        </div> */}
              </div>
            </div>
          </>
        ) : (
          <h2 className="text-white">No FAQ found</h2>
        )}
      </div>
    </section>
     <div
       style={{
        // position: "fixed",
        // justifyContent: "center",
        // alignItems: "center",
        // margin: "auto",
        // bottom: 0,
        // left: 0,
        // right: 0,
        // display: isVisible2 ? "block" : "none",
        // zIndex:9
        position: "fixed",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        bottom: 0,
        left: 0,
        right: 0,
        display: isVisible2 ? "block" : "none",
        zIndex:9,
        textAlign: 'center'
      }}
      id="Gamerji_FAQ_300x250"></div>
    </>
    
  );
};
export default AcaccountFAQAdFaq;
