import moment from "moment";
import helper from "../../common/Helper";
import DiamondImgIcon from "../../assets/images/diamond_img_icon.png";
import ruppesIcon from "../../assets/images/rupee-icon.svg";
import { UserAdDisplay } from "../../common/UserAdDisplay";
// import { useAdVisibility } from "../../common/UseAdVisibility";
import { useState,useEffect } from "react";

const Index = (props) => {
  const transectionList = props.transectionList;

  //console.log(transectionList)
  // const { isVisible } = useAdVisibility();
  const [transectionAd, settransectionAd] = useState(0);

  const [isVisible2, setIsVisible2] = useState(false);

 

  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedCount = localStorage.getItem("transectionAd");
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedTimestamp && currentTime > storedTimestamp) {
      localStorage.setItem("transectionAd", 0);
      localStorage.setItem("timestamp", new Date());
      settransectionAd(0);
    } else {
      let newCount = Number(storedCount) + 1;

      // let date = new Date(new Date().getTime() + 15 * 1000);
      localStorage.setItem("transectionAd", newCount);
      // localStorage.setItem("timestamp", moment().format("DD-MM-YYYY"));
    }
    setTimeout(() => {
      // const storedCount = localStorage.getItem("transectionAd");
      const storedCount = localStorage.getItem("count");
      let newCount = Number(storedCount);
      // console.log(newCount, "newCount");
      settransectionAd(newCount);
    }, 2000);
  }, []);

  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   if (transectionAd > 0 && transectionAd % 3 === 0) {
    //     setIsVisible2(true);
    //   } else {
    //     setIsVisible2(false);
    //   }
    // }, 10);

    // return () => clearTimeout(timeout);
    if (transectionAd >= 3) {
      setIsVisible2(true);
    } else {
      setIsVisible2(false);
    }
  }, [transectionAd]);

    //console.log(transectionList)





  UserAdDisplay("Gamerji_MyTransaction_320x50");
  // const transectionList = [{ coin: 100, amount: 5 }, { coin: 200, amount: 8 }, { coin: 300, amount: 10 }, { coin: 400, amount: 15 }, { coin: 500, amount: 20 }];
  return (
    <section className="container-fluid transactions-main">
      <div
        onScroll={props.onScroll}
        ref={props.listInnerRef}
        style={{ height: "90vh", overflowY: "auto" }}
      >
        <div className="transaction-inner" style={{ height: "auto" }}>
          {transectionList.length > 0 ? (
            transectionList.map((transection, key1) => {
              return (
                <>
                  <h4 className="text-coral">
                    {transection.month} -{" "}
                    {moment(transection.games[0].createdAt).format("YYYY")}
                  </h4>
                  <div className="accordion" id="accordionExample" key={key1}>
                    {transection?.games.length > 0 &&
                      transection?.games.map((tr, key) => {
                        // console.log(tr)
                        // console.log(helper.paymentTypeDetails['1'])
                        return (
                          <>
                            {tr.amount == 0 && tr.transactionType == 1 ? (
                              ""
                            ) : (
                              <div className="accordion-item" key={key1 + key}>
                                <h2
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target={"#collapse" + key1 + key}
                                  aria-expanded="false"
                                  aria-controls={"collapse" + key1 + key}
                                >
                                  <span className="me-3">
                                    {moment(tr.createdAt).format("DD")}
                                  </span>{" "}
                                  <p
                                    className="mb-2"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {tr.paymentType}
                                    <br />{" "}
                                    <span className="fw-normal text-lGray">
                                      {tr.gameName}
                                    </span>
                                  </p>{" "}
                                  <span className="value ms-auto">
                                    {tr.amount != 0 && tr.transactionType != "1"
                                      ? tr.transactionType === "1"
                                        ? "+"
                                        : "-"
                                      : ""}
                                      {
                                        tr.currency.name === 'Gems' ?
                                          <img
                                            className="me-1"
                                            src={DiamondImgIcon}
                                            alt=""
                                          />
                                        :
                                          <img
                                            className="me-1"
                                            src={ruppesIcon}
                                            alt=""
                                          />
                                      }
                                    
                                    {tr.amount == 0 && tr.transactionType != "1"
                                      ? "Free"
                                      : tr.amount}
                                  </span>
                                </h2>
                                <div
                                  id={"collapse" + key1 + key}
                                  className="accordion-collapse collapse "
                                  aria-labelledby="headingOne"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <div className="d-flex justify-content-between">
                                      <p className="h4 text-lGray pe-2 text-nowrap">
                                        Transaction ID
                                      </p>
                                      <p className="h4 fw-medium text-purple  text-break">
                                        {tr.transactionUniqueID}
                                      </p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <p className="h4 text-lGray pe-2 text-nowrap">
                                        Transaction date
                                      </p>
                                      <p className="h4 fw-medium text-purple  text-break">
                                        {moment(tr.createdAt).format(
                                          "DD MMM YYYY h:mm A"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                  </div>
                  <hr />
                </>
              );
            })
          ) : (
            <h1>No records found</h1>
          )}

          {/* <h4 className="text-coral">Aug</h4>
                <div className="accordion" id="accordian1">
                    <div className="accordion-item">
                        <h2 className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree" aria-expanded="true" aria-controls="collapsethree">
                            <span className="me-3">05</span> <p className="mb-2">won the league <br /> <span className="fw-normal text-lGray">Brawl Stars</span></p> <span className="value ms-auto">+ ₹ 50</span>
                        </h2>
                        <div id="collapsethree" className="accordion-collapse collapse" aria-labelledby="headingOne"
                            data-bs-parent="#accordian1">
                            <div className="accordion-body">
                                <div className="d-flex justify-content-between">
                                    <p className="h4 text-lGray">Transaction ID</p>
                                    <p className="h4 fw-medium text-purple">W354V1155428KIK796</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p className="h4 text-lGray">Transaction date</p>
                                    <p className="h4 fw-medium text-purple">05 Aug, 2022 15:54 PM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                            <span className="me-3">04</span> <p className="mb-2">Join league <br /> <span className="fw-normal text-lGray">Free Fire Max</span></p> <span className="value ms-auto">- ₹ 0</span>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingOne"
                            data-bs-parent="#accordian1">
                            <div className="accordion-body">
                                <div className="d-flex justify-content-between">
                                    <p className="h4 text-lGray">Transaction ID</p>
                                    <p className="h4 fw-medium text-purple">W354V1155428KIK796</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p className="h4 text-lGray">Transaction date</p>
                                    <p className="h4 fw-medium text-purple">05 Aug, 2022 15:54 PM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr /> */}

          <div
            style={{
              position: "fixed",
              justifyContent: "center",
              alignItems: "center",
              //margin: "auto",
              marginLeft: "auto",
              marginRight: "auto",
              bottom: 0,
              left: 0,
              right: 0,
              display: isVisible2 ? "block" : "none",
            }}
          >
            <div id="Gamerji_MyTransaction_320x50"></div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Index;
