import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import rewardsApi from "../../api/rewards";
import clevertap from "clevertap-web-sdk";

import CloseImgIcon from "../../assets/images/close-btn.svg";
import BrandImg from "../../assets/images/coupon-brand.jpg";
import DiamondImgIcon from "../../assets/images/diamond_img_icon.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Rewards.css";
import helper from "../../common/Helper";
import userServices from "../../redux/user/user.services";

export default function GetRewardPopup(props) {
  //console.log("rewa==>", props);
  clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
  clevertap.privacy.push({ useIP: true }); // Set the flag to true, if the user agrees to share their IP data
  clevertap.init(helper.cleverTapAccount, "in"); //
  clevertap.setLogLevel(3);
  // const [activeTab, setActiveTab] = useState(0);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);

  const onClickProceed = async (index) => {
    const userDetails = await userServices.getUserDetails(
      user.userDeatils?._id
    );
    console.log("ghd");
    console.log(userDetails);
    console.log(userDetails.wallet[0]);
    let wallet = userDetails.wallet[0].winningAmount;
    console.log(wallet);
    // let selectedRewardCurrencyWallet = null;
    //  selectedRewardCurrencyWallet = wallet.find(
    //   (e) => e.currency === props.selectedReward.rewardCurrency._id
    // );
    // console.log(selectedRewardCurrencyWallet);
    var balance = wallet;

    // console.log( Number.balance);
    console.log(props.selectedReward.coinAmount);
    console.log(balance);
    setIsSubmitBtnDisabled(true);
    // const userDetails = await userServices.getUserDetails(
    //   user.userDeatils?._id
    // );
    // let wallet = userDetails.wallet;

    // let selectedRewardCurrencyWallet = null;
    // selectedRewardCurrencyWallet = wallet.find(
    //   (e) => e.currency === props.selectedReward.rewardCurrency._id
    // );

    if (wallet !== null) {
      // var balance =
      //   selectedRewardCurrencyWallet?.winningAmount +
      //   user.userDeatils.wallet[0]?.packs;

      if (balance >= props.selectedReward.coinAmount) {
        var data = {
          user: user?.userDeatils?.user_id,
          rewardProduct: props.selectedReward._id,
          amount: props.selectedReward.coinAmount,
          currency: props.selectedReward.rewardCurrency._id,
        };
        console.log(data);
        await redeemReward(data);
        // console.log("its working");
      } else {
        toast("Don't have enough balance to buy this product");
        setIsSubmitBtnDisabled(false);
      }
    } else {
      toast("Don't have enough balance to buy this product");
      setIsSubmitBtnDisabled(false);
    }
  };

  async function redeemReward(data) {
    setIsSubmitBtnDisabled(true);
    const res = await rewardsApi.addReward(data);
    console.log(data);
    console.log(res);
    // toast("res=>"+JSON.stringify(res.error.response.data.errors[0].msg));
    // console.log("RES", res);
    console.log("Amount", data?.amount);
    console.log("typeId", data?.rewardProduct);
    if (res.error) {
      const error = res.error;
      console.log(error.response.data.errors[0].msg);
      toast(error.response.data.errors[0].msg);

      // console.log('error', error.message)
    } else {
      if (res.data) {
        const data = res.data;
        // console.log('data', data)

        if (data.success) {
          const AvailableBalance = localStorage.getItem("userBalance");
          console.log(AvailableBalance);
          toast.info(data.msg);
          console.log("Amount", data?.amount);
          console.log("typeId", data?.rewardProduct);
          console.log("balance",AvailableBalance)

          const userProperty = {
            GemBalance: AvailableBalance,
          };
          clevertap.event.push("Gamerji_GemDebit", {
            Amount: data?.amount,
            // GameName: "contestDetails?.gameAry.name",
            type: "reward",
            typeId: data?.rewardProduct,
            status: "Pass",
            Date: new Date(),
            userProperty: [userProperty],
          });
          navigate("/myrewards");
        } else {
          toast(data.errors[0].msg);
        }
      }
    }
  }

  return (
    <>
      <section class="reward-overview">
        <a
          href="javascript:void(0)"
          class="close-btn"
          onClick={() => props.onClickCancelIcon(false)}
        >
          <img src={CloseImgIcon} />
        </a>
        <div class="reward-img">
          <img
            src={
              props.selectedReward.img2.default
                ? helper.imageBaseUrl + props.selectedReward.img2.default
                : BrandImg
            }
          />
        </div>
        <div class="reward-info">
          <div class="container-fluid">
            <div class="d-flex align-items-center">
              <img
                class="brand-icon"
                src={
                  props.selectedReward.img.default
                    ? helper.imageBaseUrl + props.selectedReward.img.default
                    : BrandImg
                }
              />
              <div class="detail ps-3 text-start">
                <h3 class="mb-1">{props.selectedReward.name}</h3>
                <p class="mb-0">{props.selectedReward.description}</p>
                <div className="get_reward_popup_reward_price">
                  get it for{" "}
                  <img className="diamond_icon" src={DiamondImgIcon} />{" "}
                  {props.selectedReward.coinAmount}
                </div>
              </div>
            </div>
            <div class="d-grid get-reward">
              <button
                disabled={isSubmitBtnDisabled}
                className="btn btn-danger"
                style={{
                  backgroundColor: isSubmitBtnDisabled
                    ? "var(--color-silver)"
                    : "var(--color-red)",
                }}
                onClick={() => {
                  onClickProceed();
                }}
              >
                get now
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="get_reward_popup_main_component reward-overview">
        <div className="get_reward_popup">
          <div className="get_reward_popup_header">
            <div></div>
            <div className="get_reward_popup_cancel_icon">
              <img src={CloseImgIcon} onClick={() => props.onClickCancelIcon(false)} />
            </div>
          </div>


          <div className="get_reward_popup_body">
            <div className="get_reward_popup_body_scrollable_component">
              <div className="get_reward_popup_bg_component" style={{ backgroundImage: `url(${props.selectedReward.img2.default ? helper.imageBaseUrl + props.selectedReward.img2.default : BrandImg})` }}>
                <div className="get_reward_popup_reward_details_component">
                  <div className="d-flex w-100">
                    <img className="get_reward_popup_brand_img" src={props.selectedReward.img.default ? helper.imageBaseUrl + props.selectedReward.img.default : BrandImg} />
                    <div className="d-flex flex-column justify-content-between">
                      <div className="get_reward_popup_brand_name">{props.selectedReward.name}</div>
                      <div className="get_reward_popup_details">{props.selectedReward.description}</div>
                      <div className="get_reward_popup_reward_price">
                        get it for <img className="diamond_icon" src={DiamondImgIcon} /> {props.selectedReward.coinAmount}
                      </div>
                    </div>
                  </div>
                  <div className="get_reward_popup_btn_component d-grid">
                    <button className="btn btn-danger" onClick={() => { onClickProceed() }}>
                      get now
                    </button>
                  </div>
                  <div className="get_reward_popup_hr_divider"></div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> */}

      <ToastContainer limit={1} />
    </>
  );
}
