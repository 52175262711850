import React from 'react';
import MainRoutes from './routes/MainRoutes';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { store } from './store.js';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <MainRoutes />
      </BrowserRouter>
    </Provider>

  );
}

export default App;
