import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import DiamondImgIcon from "../../assets/images/diamond_img_icon.png";
import PlusImgIcon from "../../assets/images/plus-icon.svg";

import GetRewardPopup from "./GetRewardPopup";
import NavigationSection from "../../component/NavigationSection";

import "./Rewards.css";
import rewardsApi from "../../api/rewards";
import { useSelector } from "react-redux";
import helper from "../../common/Helper";
import userServices from "../../redux/user/user.services";
import Lottie from "react-lottie";
import * as animationData from "../../RewardStore.json";
import rewardBanner from "../../assets/images/reward-banner.png";
import { Audio, Oval } from "react-loader-spinner";
import { UserAdDisplay } from "../../common/UserAdDisplay";
// import { useAdVisibility } from "../../common/UseAdVisibility";

export default function AllRewards(props) {
  var rewardCategoryList = props.rewardCategoryList;
  var rewardProductList = props.rewardProductList;
  const isShowLoader = props.isLoaderShow;

  UserAdDisplay("Gamerji_Reward_HP_300x250");
  //  const { isVisible } = useAdVisibility();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [allrewardsAd, setallrewardsAd] = useState(0);

  const [isVisible2, setIsVisible2] = useState(false);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedCount = localStorage.getItem("allrewardsAd");
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedTimestamp && currentTime > storedTimestamp) {
      localStorage.setItem("allrewardsAd", 0);
      localStorage.setItem("timestamp", new Date());
      setallrewardsAd(0);
    } else {
      let newCount = Number(storedCount) + 1;

      // let date = new Date(new Date().getTime() + 15 * 1000);
      localStorage.setItem("allrewardsAd", newCount);
      // localStorage.setItem("timestamp", moment().format("DD-MM-YYYY"));
    }
    setTimeout(() => {
      // const storedCount = localStorage.getItem("allrewardsAd");
      const storedCount = localStorage.getItem("count");
      let newCount = Number(storedCount);
      // console.log(newCount, "newCount");
      setallrewardsAd(newCount);
    }, 2000);
  }, []);

  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   if (allrewardsAd > 0 && allrewardsAd % 3 === 0) {
    //     setIsVisible2(true);
    //   } else {
    //     setIsVisible2(false);
    //   }
    // }, 10);

    // return () => clearTimeout(timeout);
    if (allrewardsAd >= 3) {
      setIsVisible2(true);
    } else {
      setIsVisible2(false);
    }
  }, [allrewardsAd]);

  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  // console.log("user.userDetails", user.userDeatils);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedReward, setSelectedReward] = useState();
  const [isShowGetRewardPopup, setIsShowGetRewardPopup] = useState(false);
  const [allRewardList, setAllRewardList] = useState([]);
  // const balance = parseInt(user.userDeatils.wallet.winningAmount) + parseInt(user.userDeatils.wallet.packs)
  const [userDetails, setuserDetails] = useState();
  const [totalBalance, setTotalBalance] = useState(0);

  // const userDeatils =await userServices.GetUserDetails()
  var payload = {
    pagination: {
      pageNo: 1,
      recordPerPage: 10,
      sortBy: "name",
      sortDirection: "desc",
    },
  };
  // console.log('userDeatils serv',userDeatils)

  const [filterData, setFilterData] = useState([
    { img: "../../assets/images/flipkart-reward.jpg", amount: 6500 },
    { img: "../../assets/images/brand-reward.jpg", amount: 7500 },
    { img: "../../assets/images/redbull-reward.jpg", amount: 6500 },
    { img: "../../assets/images/lovecorn-reward.jpg", amount: 7500 },
    { img: "../../assets/images/flipkart-reward.jpg", amount: 6500 },
    { img: "../../assets/images/brand-reward.jpg", amount: 7500 },
    { img: "../../assets/images/redbull-reward.jpg", amount: 6500 },
  ]);

  useEffect(() => {
    (async () => {
      setAllRewardList(rewardProductList);
      if (user?.userDeatils?._id) {
        const userDeatils = await userServices.getUserDetails(
          user?.userDeatils?._id
        );
        const wallet = userDeatils && userDeatils?.wallet[0];
        let userWalletTotal = userServices.getWalletTotalAmount(wallet);
        setTotalBalance(userWalletTotal);
        localStorage.setItem("userBalance",userWalletTotal)
        console.log(totalBalance);

      

      
      }
    })();
  }, [rewardProductList, user]);

  async function getAllRewardList(data) {
    const list = await rewardsApi.list(data);
    if (list.error) {
      const error = list.error;
      console.log("error", error.message);
    } else {
      if (list.data) {
        const data = list.data;
        if (data.success) {
          setAllRewardList(data.data);
          rewardProductList = data.data;
        } else {
          setAllRewardList([]);
          rewardProductList = [];
        }
      }
    }
  }

  const onClickFilterType = async (index) => {
    let filter_types = [...rewardCategoryList];
    rewardCategoryList.forEach((element, i) => {
      if (i === index) filter_types[i].isActive = true;
      else filter_types[i].isActive = false;
    });
    payload.search = {
      category: rewardCategoryList[index]._id,
      isActive: true,
    };
    await getAllRewardList(payload);

    rewardCategoryList = filter_types;
  };

  const onClickRewadBox = (data) => {
    // console.log("data", data);
    setSelectedReward(data);
    setIsShowGetRewardPopup(true);
  };

  const onTabChange = (value) => {
    setActiveTab(value);
    if (value == 1)
      // My Reward
      navigate("/myrewards");
  };

  // over-ride style
  /*const all_rewards_filter_component_Style = {
    width: '100%',
    display: '-webkit-inline-box',
    padding: '20px',
    overflow: 'auto',
  }

  const all_rewards_filter_type_style = {
    // padding: '0px 16px 10px',
    marginRight: '10px',
    fontWeight: '500',
    fontSize: '14px',
  }*/
  return (
    <div className="all_rewards_main_component main-content reward-main">
      {isShowGetRewardPopup && (
        <GetRewardPopup
          onClickCancelIcon={setIsShowGetRewardPopup}
          selectedReward={selectedReward}
        />
      )}

      <div class="container-fluid px-0">
        <div class="reward-cover d-flex">
          <img class="w-100" src={rewardBanner} alt="" />
          <Lottie
            options={defaultOptions}
            height={130}
            width={130}
            background="transparent"
            style={{ position: "absolute", right: "45px", top: "10px" }}
            speed="1"
            loop
            autoplay
          />
        </div>
      </div>

      <div class="reward-content">
        <div class="container-fluid">
          <div class="d-flex mb-3 pb-1 add-coin">
            <a class="d-flex align-items-center text-decoration-none">
              <span class="badge coin-badge me-2">
                <img class="me-1" src={DiamondImgIcon} alt="" />
                {totalBalance}
              </span>
              <Link
                className="all_rewards_header_coin_plus_icon"
                to={"/buy-gems"}
              >
                <img className="plus_icon" src={PlusImgIcon} />
              </Link>
            </a>
          </div>
        </div>
        <ul
          class="nav nav-tabs justify-content-center"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === 0 && "active"}`}
              onClick={() => {
                onTabChange(0);
              }}
            >
              all rewards
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === 1 && "active"}`}
              onClick={() => {
                onTabChange(1);
              }}
            >
              my rewards
            </a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="allReward-tab-pane"
            role="tabpanel"
            aria-labelledby="allReward-tab"
            tabindex="0"
          >
            <div
              class="rewardFilter pb-3 pt-4 pb-1"
              style={{ position: "unset" }}
            >
              {" "}
              {/* style added for categories position  */}
              {rewardCategoryList.map((value, i) => {
                return (
                  <span
                    style={{ textTransform: "lowercase" }}
                    className={`btn btn-outline-light px-3 me-2
                    ${value.isActive ? "active" : ""}`}
                    key={i}
                    onClick={() => {
                      onClickFilterType(i);
                    }}
                  >
                    {value.name}
                  </span>
                );
              })}
            </div>
            <div className="all_rewards_filtered_data_scrollable_component">
              <div
                className={
                  "all_rewards_filtered_data_component" +
                  (allRewardList.length > 0 ? "" : " no-reward")
                }
              >
                {isShowLoader ? (
                  <div className="main-content registration-main">
                    <Oval
                      height={50}
                      width={50}
                      color="#fff"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffffd1"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                ) : allRewardList.length > 0 ? (
                  allRewardList.map((value, i) => {
                    let imgUrl = value.img.default
                      ? helper.imageBaseUrl + value.img.default
                      : filterData[0].img;
                    return (
                      <>
                        <div className="card reward-card" key={i}>
                          <div className="card-img">
                            <img
                              className="card-img"
                              src={imgUrl}
                              onClick={() => onClickRewadBox(value)}
                            />
                          </div>
                          <div className="card-content">
                            <div className="card-title">
                              <img className="me-1" src={DiamondImgIcon} />
                              <h5 className="mb-0">{value.coinAmount}</h5>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <>
                    {/* <h2 className="text-white m-0" >No Reward Found</h2> */}
                    <div className="d-flex align-items-center justify-content-center">
                      <h2 className="text-white text-align-center">
                        No Reward Found
                      </h2>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              id="Gamerji_Reward_HP_300x250"
              style={{
                 display: isVisible2 ? "block" : "none",
                // // position: "fixed",
                // justifyContent: "center",
                // alignItems: "center",
                // margin: "auto",
                // bottom: "63px",
                // position: 'absolute',
                // left: '50%',
                // transform: 'translate(-50%, 0)',
                // // left: 0,
                // // right: 0,
                // color: "white",
                position: "fixed",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                      bottom: "63px",
                      left: 0,
                      right: 0,
                      color: "white",
                      textAlign: 'center',
                      color:"white"
              }}
            ></div>
          </div>
        </div>
      </div>

      {/* <div className="all_rewards_main_screen">
        <div className="all_rewards_header">
          <div className="all_rewards_header_coin_row">
            <div className="all_rewards_header_coins">
              <img className="diamond_icon" src={DiamondImgIcon} />{totalBalance}
            </div>
            <Link className="all_rewards_header_coin_plus_icon" to={'/buy-gems'}>
              <img className="plus_icon" src={PlusImgIcon} />
            </Link>
          </div>
        </div>

        <div className="reward-content">
          <div className="all_rewards_tabs_component">
            <div className={`all_rewards_tab mr-2
            ${activeTab === 0 && 'all_rewards_active_tab'}`}
              onClick={() => { onTabChange(0); }}
            >
              all rewards
            </div>
            <div className={`all_rewards_tab 
            ${activeTab === 1 && 'all_rewards_active_tab'}`}
              onClick={() => { onTabChange(1); }}
            >
              my rewards
            </div>
          </div>

          <div className="rewardFilter pb-3 pt-4 pb-1">
            {
              rewardCategoryList.map((value, i) => {
                return (
                  <span className={`btn btn-outline-light px-3 me-2
                    ${value.isActive ? 'active' : ''}`}
                    key={i} onClick={() => { onClickFilterType(i); }}
                  >
                    {value.name}
                  </span>
                )
              })
            }
          </div>

          <div className="all_rewards_filtered_data_scrollable_component" style={{ height: "unset" }}>
            <div className={"all_rewards_filtered_data_component" + (allRewardList.length > 0 ? "" : " no-reward")}>
              {
                allRewardList.length > 0 ?
                  allRewardList.map((value, i) => {
                    let imgUrl = value.img.default ? helper.imageBaseUrl + value.img.default : filterData[0].img
                    return (
                      <div className="all_rewards_reward_box"
                        key={i}
                        style={{ backgroundImage: `url(${imgUrl})` }}
                        onClick={() => onClickRewadBox(value)}
                      >
                        <div className="all_rewards_reward_data">
                          <img className="diamond_icon_card" src={DiamondImgIcon} />{value.coinAmount}
                        </div>
                      </div>
                    )
                  })
                  :
                  <>
                    <h2 className="text-white m-0" >No Reward Found</h2>
                    <div className="d-flex align-items-center justify-content-center">
                      <h2 className="text-white text-align-center">No Reward Found</h2>
                    </div>

                  </>
              }

            </div>
          </div>

        </div>
      </div> */}

      {/* <NavigationSection activeTab="reward" /> */}
    </div>
  );
}
