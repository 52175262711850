import Index from "../view/transection/Index"
import { useOutletContext } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import userApi from "../api/user";
import moment from "moment";

function TransectionController() {
    const { setTitle } = useOutletContext();
    const [transectionList, setTransectionList] = useState([])
    const user = useSelector((state) => state.user);


    const listInnerRef = useRef();
    const [currPage, setCurrPage] = useState(1);
    const [prevPage, setPrevPage] = useState(0);
    const [lastList, setLastList] = useState(false);
    
    useEffect(() => {
        (async () => {

            setTitle('my transactions')
            if (user?.userDeatils?.user_id) {
                if (!lastList && prevPage !== currPage) {

                await getTransectionList(user?.userDeatils?.user_id)
                }
            }
        })()
    // }, [user])
}, [user, currPage, lastList, prevPage, transectionList])



    async function getTransectionList(userId) {

        let data = {}
        data.search = {}
        data.search.user = userId
        data.pagination = {
            pageNo: currPage,
            recordPerPage: 20,
            sortDirection:"desc"
        }
        const list = await userApi.transectionList(data)
        if (list.error) {
            const error = list.error
            // console.log('error', error.message)
        } else {
            if (list.data) {
                const data = list.data
                if (data.success) {
                    // console.log(data)
                    // console.log(data.data)

                    const groups = data.data.reduce((groups, game) => {
                        const month = moment(game.createdAt).format('MMM')
                         
                        if (!groups[month]) {
                            groups[month] = [];
                        }
                        groups[month].push(game);
                        return groups;
                    }, {});

                    // Edit: to add it in the array format instead
                    const groupArrays = Object.keys(groups).map((month) => {
                        return {
                            month,
                            games: groups[month]
                        };
                    });
                    // console.log(groupArrays);

                    if (!data.data.length) {

                        setLastList(true);
                        return;
                    }
            
            
                    setPrevPage(currPage);
                    setTransectionList([...transectionList, ...groupArrays])
                    // setTransectionList(groupArrays)



                    // setTransectionList(data.data)
                }
            }
        }

    }

    
    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                setCurrPage(currPage + 1);
            }
        }
    };


    return (
   
        <Index
            transectionList={transectionList}
            onScroll={onScroll}
            listInnerRef={listInnerRef}
        />
     
      
    )
}
export default TransectionController