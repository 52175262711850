import coinIcon from "../../assets/images/coin-gold.svg"


const BuyCoins = (props) => {
console.log("props",props)
    return (
        <div class="coin-card card" key={props?.myKey}>
            <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center text-white"><img className="me-1" src={coinIcon} alt=""/>{props.coins}</div>
                    <a href="#" class="btn btn-danger btn-lg">₹ {props.amount}</a>
                </div>
            </div>
        </div>
    )

}
export default BuyCoins