import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Pending from "../../assets/images/pending-payment.png";
import Fail from "../../assets/images/fail-payment.png";
import Success from "../../assets/images/success-payment.png";

import "./PaymentStatus.css";
import { useSelector } from "react-redux";
import buycoinsApi from "../../api/buycoins";
import buyCoinsXsollaAPI from "../../api/buyCoinsXsolla";

export default function PaymentStatus(props) {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [transactionData, setTansactionData] = useState({});
    const user = useSelector((state) => state.user);
    let invoiceId = searchParams.get("invoice_id") || null;
    let status = searchParams.get("status") || null;
    let foreignInvoice = searchParams.get("foreignInvoice") || null;
    let user_id = searchParams.get("user_id") || null
    let CPAmount = localStorage.getItem('CPAmount') || 0
    let sku = localStorage.getItem('sku') || 0
    let CPCoin = localStorage.getItem('CPCoin') || 0
    useEffect(() => {
        console.log(">>1")
        if (invoiceId && status && user_id && foreignInvoice && CPAmount && sku && CPCoin) {
            console.log(">>2")
            onPurchaseGems(invoiceId, status, foreignInvoice, CPAmount, user_id, sku, CPCoin)
        }
    }, [invoiceId]);
    async function onPurchaseGems(invoiceId, status, foreignInvoice, CPAmount, user_id, sku, CPCoin) {

        try {


            let gemPurchaseData = localStorage.getItem("body");
            let payload = {
                "transactionUniqueID": foreignInvoice,
                "coin": CPCoin,
                "sku": sku,
                "amount": CPAmount,
                "pgData": {
                    "status": status,
                    "invoice_id": invoiceId
                }
            }

            // console.log(">>4")
            let data = await buyCoinsXsollaAPI.buyXsollaCoinUpdate(payload);
            console.log("RESPONSE::::::::", data.data)
            data = data.data
            if (data?.success) {
                setTansactionData(data?.data);
                //   setTimeout(() => {
                //     navigate('/home');
                //   }, 3000);
            } else {
                // toast.error(data?.message);
            }
        } catch (error) {

        }
    }

    // useEffect(() => {
    //     console.log(searchParams.get("txnid"))
    //     if (searchParams.get("txnid")) {
    //         const params = {};
    //         for (let entry of searchParams.entries()) {
    //             // console.log(entry[1])
    //             params[entry[0]] = entry[1]
    //             // params.push(entry);
    //         }
    //         console.log(params)
    //         setTansactionData(params)
    //         onPurchaseGems(params);
    //     }
    // }, [searchParams])
    // const onPurchaseGems = async (pgData) => {
    //     let paymentStatus = "";
    //     if (pgData.status === "success") {
    //         paymentStatus = "SUCCESS";
    //     } else if (pgData.status === "failure") {
    //         paymentStatus = "FAILED";
    //     } else if (pgData.status === "userCancelled") {
    //         paymentStatus = "USER_CANCELLED";
    //     } else {
    //         paymentStatus = pgData.status.toUpperCase();
    //     }
    //     let params = {
    //         transactionId: pgData.txnid,
    //         pgData: pgData,
    //         paymentStatus: paymentStatus,
    //         paymentMode: pgData.card_type,
    //         paymentRefID: pgData.easepayid,
    //         gemsAmount: Number(pgData.udf1),
    //         coinStore: pgData.udf2,
    //     };

    //     await buycoinsApi.purchasedCoin(params);
    // };

    return (
        <div className="pg_status_main_component">
            {/* <ToastContainer /> */}

            <div className="pg_status_body">
                <div className="pg_status_title_component">
                    <div className="pg_title">Payment Status</div>
                </div>
                <div className="pg_ps_status_component">
                    <div className={
                        transactionData?.paymentStatus === "SUCCESS" ? "pg_status_background_success"
                            : transactionData?.paymentStatus === 'FAILED' ? 'pg_status_background_failed'
                                : 'pg_status_background_pending'
                    }>
                        {/* Status Icon */}
                        <img className="pg_status_icon" src={
                            transactionData?.paymentStatus === 'SUCCESS' ? Success
                                : transactionData?.paymentStatus === 'FAILED' ? Fail
                                    : Pending
                        } />

                        {/* Title */}
                        <div className="pg_status_title">
                            {transactionData?.paymentStatus === 'SUCCESS' ? 'Success!'
                                : transactionData?.paymentStatus === 'FAILED' ? 'Failure!'
                                    : 'Processing...'}
                        </div>

                        {/* Amount */}
                        {
                            transactionData?.paymentStatus &&
                            <>
                                <div className="pg_status_amount">₹ {transactionData?.amount}</div>

                                {/* Date Time */}
                                <div className="pg_status_date">{transactionData?.paymentTimestamp}</div>

                                <div className="pg_status_transaction">Transaction ID : {foreignInvoice}</div>
                                {transactionData?.paymentStatus === 'SUCCESS' ?
                                    <div className="pg_status_transaction">Your {CPCoin} gems balance will be credited soon!</div>
                                    : <div className="pg_status_transaction">We couldn`t process your payment.</div>
                                }
                            </>
                        }
                        <button className="btn main-btn next mt-4 mb-4 ml-2" style={{ width: "50%", border: '1px solid' }} onClick={() => { navigate('/home') }}>DONE</button>


                    </div>
                </div>
            </div>
        </div>
    );
}
