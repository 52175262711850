import { SET_APP_TOKEN, UPDATE_USER_DETAILS,SET_CURRENT_GAME_NAME,SET_OPEN_EXIT_POPUP,SET_AGREE_TERMS } from "./user.type";

export const setUserDetails = (data) => ({
  type: UPDATE_USER_DETAILS,
  payload: data,
});

export const setUserAppToken = (data) => ({
  type: SET_APP_TOKEN,
  payload: data,
});

export const setCurrentGameName = (data) => ({
  type: SET_CURRENT_GAME_NAME,
  payload: data,
});

export const setOpenExitPopup = (data) => ({
  type: SET_OPEN_EXIT_POPUP,
  payload: data,
});

export const setAgreeTerms = (data) => ({
  type: SET_AGREE_TERMS,
  payload: data,
});