import Index from "../view/leaderboard/Index"
import { useOutletContext, useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import contestApi from "../api/contest";

function LeaderboardController() {
    const { setTitle } = useOutletContext();
    const [contestUserList, setContestUserList] = useState([])
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector((state) => state.user);
    const perems = useParams()
    const navigate = useNavigate()
    setTitle('leaderboard')

    // console.log("perems.contestId", perems.contestId);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            await getLeaderboardList();

        })()
    }, []);

    async function getLeaderboardList() {
        
        var payload = {
            pagination: {
                pageNo: page,
                recordPerPage: 100,
                sortBy: "rank",
                sortDirection: "asc"
            },
            search: {
                user: user.userDeatils.user_id
            }
        }
        const userList = await contestApi.getContestUserList(perems.contestId, payload)
        if (userList.error) {
            setIsLoading(false)
            const error = userList.error
            console.log('error', error.message)
        } else {
            if (userList.data) {
                const data = userList.data;
                let zeroArray = [];
                let tempArray = [];
                if (data.success) {
                    if (data.data.length > 0) {
                        if (data.data[0].contestData?.status === 'completed') {
                            if (page === 1) {
                                data.data.map((value) => {
                                    console.log(value.rank);
                                    if(value.rank == 0) {
                                        zeroArray.push(value)
                                    } else {
                                        tempArray.push(value);
                                    }
                                });
                                tempArray.sort((a, b) => a.rank - b.rank);
                                const j = tempArray.concat(zeroArray)
                                setContestUserList([...j])
                            } else {
                                let tempData = contestUserList.concat(data.data)
                                setContestUserList([...tempData])
                            }

                            setTotalPages(data.totalPages)
                        } else {
                            navigate(-1)
                        }
                    }
                    setIsLoading(false)
                    // } else {
                    //     navigate(-1)
                    // }
                }
                else {
                    setIsLoading(false)
                    setContestUserList([])
                }
            } else {
                setIsLoading(false);
            }
        }

    }

    async function getLoadMore() {
        console.log('------', page);
        let i = page + 1;
        setPage(i);
        await getLeaderboardList();
    }

    return (
        <Index contestUserList={contestUserList} totalPages={totalPages} page={page} getLoadMore={() => getLoadMore()} isLoading={isLoading} />
    )

}
export default LeaderboardController