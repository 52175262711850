import { Outlet } from 'react-router-dom';
import MainHeaderSection from "../component/MainHeaderSection";
import NavigationSection from "../component/NavigationSection";



export default function HomeLayout() {
    return (
        <>
            <MainHeaderSection />
            <NavigationSection activeTab="home"/>
            <div id="wrapper" className="main-content">
                <Outlet />
            </div>
        </>
    )

}