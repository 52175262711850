import MyRewards from "../view/rewards/MyRewards"
import { useOutletContext, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import NavigationSection from "../component/NavigationSection";
import rewardsApi from "../api/rewards";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';


function MyRewardsController() {
    // const { setTitle } = useOutletContext();
    const user = useSelector((state) => state.user);

    const [myRewardList, setMyRewardList] = useState([])
    const [isLoader, setIsLoading] = useState(true);

    useEffect(() => {
        // setTitle('my rewards')
    }, []);


    useEffect(() => {
        (async () => {
            if (user.userAppToken && user.userAppToken !== '' && user.userAppToken !== null) {
                // console.log('user',user.userDeatils.user_id)
                await getMyRewardList(user.userAppToken, user.userDeatils.user_id)

            }

        })()
    }, [user.userAppToken])

    async function getMyRewardList(token, userId) {
        let data = {}
        data.search = {}
        data.search.user = userId
        // console.log('token',token)
        const list = await rewardsApi.myRewardList(token, data)
        // console.log('list',list)
        if (list.error) {
            const error = list.error
            // console.log('error', error.message)
        } else {
            setIsLoading(false)
            if (list.data) {
                const data = list.data
                if (data.success) {
                    // console.log('data.data',data.data)
                    setMyRewardList(data.data)
                } else {
                    setMyRewardList([])

                }
            }
        }
    }
    return (
        <>
            <ToastContainer limit={1} />

            <NavigationSection activeTab="reward" />
            <MyRewards 
                myRewardList={myRewardList} 
                isLoaderShow={isLoader}
            />

        </>
    )

}
export default MyRewardsController