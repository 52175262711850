import Index from "../view/mygamerid"
import { useOutletContext } from "react-router-dom";
import React, { useEffect } from "react"
// import { useSelector, useDispatch } from "react-redux";
// import NavigationSection from "../component/NavigationSection";


function MyGamerIdController() {
    const { setTitle } = useOutletContext();
    // const user = useSelector((state) => state.user);
    // const [userDetails, setUserDetails] = useState({
    //     mobile:"",
    //     email:"",
    //     name:""
    // })

    useEffect(() => {
        setTitle('my gamer IDs')

        // setUserDetails({
        //     ...userDetails,
        //     mobile:user?.userDeatils?.phone
        // })
    }, []);

    return (
        <>
            <Index />
        </>
    )

}
export default MyGamerIdController