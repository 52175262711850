import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";


function contest() {

    const listByUser = async (data) => {
        let url = helper.saasApiUrl() + 'joinUserContest/list'
        // let url = helper.saasApiUrl() + 'v2/contest/vi/mylist'
        return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(),
                data: data
            })
                .then(response => response.data)
        )

    }

    const upcomingContestList = async (data) => {

        // let url = helper.saasApiUrl() + 'joinUserContest/list?user=' + userId + '&status=' + status
        // if (status === 'all') {
        //     url = helper.saasApiUrl() + 'joinUserContest/list?user=' + userId
        // }
        let url = helper.saasApiUrl1() + 'vi/upcomingContest'
        console.log("API req:url ",url)
        // let data = {}
        // data.user = userId
        // if (status !== 'all') {
        //     data.status = status

        // }

        return await resolve(
            axios({
                method: 'post',
                // url: helper.saasApiUrl() + 'contest/list?status='+status,
                // url: helper.saasApiUrl() + 'joinUserContest/list?user='+userId+'&status='+status,
                url: url,
                responseType: 'json',
                headers: helper.headers(),
                data: data
            })
                .then(response => response.data)
        )

    }
    const lastplayedContestList = async (data) => {

        // let url = helper.saasApiUrl() + 'joinUserContest/list?user=' + userId + '&status=' + status
        // if (status === 'all') {
        //     url = helper.saasApiUrl() + 'joinUserContest/list?user=' + userId
        // }
        let url = helper.saasApiUrl1() + 'vi/LastPlayed'
        console.log("API req:url ",url)
        // let data = {}
        // data.user = userId
        // if (status !== 'all') {
        //     data.status = status

        // }

        return await resolve(
            axios({
                method: 'post',
                // url: helper.saasApiUrl() + 'contest/list?status='+status,
                // url: helper.saasApiUrl() + 'joinUserContest/list?user='+userId+'&status='+status,
                url: url,
                responseType: 'json',
                headers: helper.headers(),
                data: data
            })
                .then(response => response.data)
        )

    }
    const listByGame = async (data) => {
        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl() + 'contest/list',
                responseType: 'json',
                headers: helper.headers(),
                data:data
            })
                .then(response => response.data)
        )

    }
    const contestlistbygame = async (data) => {
        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl() + 'vi/contestList',
                responseType: 'json',
                headers: helper.headers(),
                data:data
            })
                .then(response => response.data)
        )

    }

    const joinContest = async (data) => {
        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl() + 'joinUserContest/join/',
                responseType: 'json',
                headers: helper.headers(),
                data: data
            })
                .then(response => response.data)
        )

    }

    const getContestById = async (contestId, userId = '') => {

        let data ={}
        data.search = {}
        data.search.user = userId


        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl() + 'contest/get/' + contestId,
                responseType: 'json',
                headers: helper.headers(),
                // data:{
                //     user:userId
                // }
                data:data
            })
                .then(response => response.data)
        )

    }
    const getContestUserList = async (contestId,data) => {

       
        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl() + 'joinedContestUserByContestId/' + contestId,
                responseType: 'json',
                headers: helper.headers(),                
                data:data
            })
                .then(response => response.data)
        )

    }

    return {
        upcomingContestList,
        listByUser,
        listByGame,
        joinContest,
        getContestById,
        getContestUserList,
        lastplayedContestList,
        contestlistbygame
    }
}
const contestApi = contest();
export default contestApi;