import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";

function buyCoinsXsolla() {
    const list = async (data) => {
        console.log("")
        let url = helper.viApiUrl() + 'xsolla/list'
               return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(),
                data:data 
            })
                .then(response => response.data)
        )

    }
    const buyXsollaCoin = async (data) => {
        let url = helper.viApiUrl() + 'xsolla/create-xsolla-transaction'
        return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(),
                data: data
            })
            .then(response => response.data)
        )

    }
    const buyXsollaCoinUpdate = async (data) => {
        let url = helper.viApiUrl() + 'xsolla/update-xsolla-transaction'
        return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(),
                data: data
            })
            .then(response => response.data)
        )

    }
    // const purchasedCoin = async (data) => {
    //     let url = helper.viApiUrl() + 'coin/purchased'
    //     return await resolve(
    //         axios({
    //             method: 'post',
    //             url: url,
    //             responseType: 'json',
    //             headers: helper.headers(),
    //             data: data
    //         })
    //         .then(response => response.data)
    //     )

    // }
    // const getPaymentOption = async (data) => {
    //     let url = helper.viApiUrl() + 'paymentOption'
    //     return await resolve(
    //         axios({
    //             method: 'get',
    //             url: url,
    //             responseType: 'json',
    //             headers: helper.headers()
    //         })
    //             .then(response => response.data)
    //     )

    // }
    // const createPhonePay = async (body) => {
    //     let url = helper.viApiUrl() + 'create-phonepay-transaction'
    //     return await resolve(
    //         axios({
    //             method: 'post',
    //             url: url,
    //             responseType: 'json',
    //             headers: helper.headers(),
    //             data: body
    //         }).then(response => response.data)
    //     )
    // }
    // const updatePhonePay = async (body) => {
    //     let url = helper.viApiUrl() + 'update-phonepay-transaction'
    //     return await resolve(
    //         axios({
    //             method: 'post',
    //             url: url,
    //             responseType: 'json',
    //             headers: helper.headers(),
    //             data: body
    //         }).then(response => response.data)
    //     )
    // }
    // const createCashfreePay = async (body) => {
    //     let url = helper.viApiUrl() + 'create-cashfree-transaction'
    //     return await resolve(
    //         axios({
    //             method: 'post',
    //             url: url,
    //             responseType: 'json',
    //             headers: helper.headers(),
    //             data: body
    //         }).then(response => response.data)
    //     )
    // }
    // const updateCashfreePay = async (body) => {
    //     let url = helper.viApiUrl() + 'update-cashfree-transaction'
    //     return await resolve(
    //         axios({
    //             method: 'post',
    //             url: url,
    //             responseType: 'json',
    //             headers: helper.headers(),
    //             data: body
    //         }).then(response => response.data)
    //     )
    // }
    return {
        list,
        buyXsollaCoin,
        buyXsollaCoinUpdate
        // buyCoin,
        // purchasedCoin,
        // getPaymentOption,
        // createPhonePay,
        // updatePhonePay,
        // createCashfreePay,
        // updateCashfreePay
    }
}
const buyCoinsXsollaAPI = buyCoinsXsolla();
export default buyCoinsXsollaAPI;