import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";


function game() {

    const list = async (data) => {
        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl1() + 'vi/game/list',
                responseType: 'json',
                headers: helper.headers(),
                data:data
            })
                .then(response => response.data)
        )

    }

    const getGameById = async (gameId) => {
        return await resolve(
            axios({
                method: 'get',
                url: helper.saasApiUrl() + 'game/get/'+gameId,
                responseType: 'json',
                headers: helper.headers()
            })
                .then(response => response.data)
        )

    }
    return {
        list,
        getGameById
    }
}
const gameApi = game();
export default gameApi;