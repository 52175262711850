import calendarIcon from "../../assets/images/calendar-icon.svg";
// import Edit from "../../assets/images/edit.svg"
import bgmiContestJoin from "../../assets/images/bgmi-contestJoin.jpg";
import rupeeIcon from "../../assets/images/rupee-icon.svg";
// import info from "../../assets/images/info.svg";
// import backArrowIcon from "../../assets/images/back-arrow.svg";
// import GamePageHeader from "../../component/GamePageHeader";
import EditIcon from "../../assets/images/editIcon.svg";
import InfoIcon from "../../assets/images/info.svg";
import { useSelector } from "react-redux";
import moment from "moment";
import helper from "../../common/Helper";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import diamondIcon from "../../assets/images/diamond-icon.svg";
import { PlayedGameBefore } from "./PlayedGameBefore";
import { NotPlayedGame } from "./NotPlayedGame";
// import firstTimePlayingApi from "../../api/firstTimePlaying";
// import { useEffect } from "react";
import { Oval } from "react-loader-spinner";

const JoinGame = (props) => {
  const [playedPopup, setPlayedPopup] = useState(false);
  const [NotplayedPopup, setNotPlayedPopup] = useState(false);
  const [firstTime, setFirstTime] = useState("");
  const [buttonDisbale, setButtonDisbale] = useState(false);

  const PlayedGame = () => {
    setPlayedPopup(true);
  };

  const NotPlayed = () => {
    setNotPlayedPopup(true);
  };

  // async function getFirstTimePlaying() {
  //   let data = {
  //     userId: "GSVI0000163",
  //     gameId: "634a42a89633f3f5b7228455",
  //   };

  //   const firstTimeResponse = await firstTimePlayingApi.firsttime(data);
  //   // setFirstTime(firstTimeResponse?.data?.isFirstTime);
  // }

  // useEffect(() => {
  //   getFirstTimePlaying();
  // }, []);

  const user = useSelector((state) => state.user);
  const contestDetails = props.contestDetails;
  const [focus, setFocus] = useState(false);
  // const [playedGameBefore]
  const [disabled, setDisabled] = useState(true);
  const onChangeEditName = (e) => {
    //if(e.target.value)
    setDisabled(false);
  };
  return (
    <div className="main-content joinGame-main">
      <section className="join-game">
        <div className="container-fluid px-0">
          <div className="joinGame-cover">
            <img
              className="w-100"
              src={
                contestDetails?.featuredImage?.default
                  ? helper.imageBaseUrl + contestDetails?.featuredImage?.default
                  : bgmiContestJoin
              }
              alt=""
            />
          </div>
          
          {!props.loader ? !props.isAddName ? (
            <div className="joinGame-content 3">
              <div className="gameContent-header">
                <h2>{user.userCurrentGameName}</h2>
              </div>
              <hr className="hr m-0"></hr>
              <div className="gameContent-body">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <img src={calendarIcon} alt="" />
                    {/* <p className="ms-2 mb-0 h3 text-silver">22 Sep 22, 2:30PM</p> */}
                    <p className="ms-2 mb-0 h3 text-silver">
                      {moment(contestDetails.dateTime).format(
                        "DD MMM YY, hh:mmA"
                      )}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <img src={rupeeIcon} alt="" />
                    <p className="ms-2 mb-0 h3 text-silver">
                      {
                        Number(contestDetails.entryFee) === 0 ? (
                          "FREE"
                        ) : (
                          <div className="d-flex align-items-center icon-title">
                            <img src={diamondIcon} alt="" />
                            <h3 id="prizepool" className="ms-1 my-0 fw-bold">
                              {contestDetails.entryFee}
                            </h3>
                          </div>
                        )
                      }
                    </p>
                  </div>
                </div>
                <p className="mt-4 pt-2 h4">
                  By joining this contest/tournament, you accept the
                  Gamerji’s&nbsp;
                  <Link to="/terms-and-conditions">
                    <a className="fw-bold text-decoration-underline text-silver">
                      {" "}
                      terms & conditions
                    </a>
                  </Link>
                </p>
                <div className="d-grid mt-4">
                  <button
                    className="btn btn-danger btn-lg"
                    onClick={() => props.setAddName(true)}
                  >
                    add {user.userCurrentGameName} name
                  </button>
                </div>
              </div>
            </div>
          ) : props.isUserInGameName ? (
              // firstTime ? (
              //   <>
              //     <div className="joinGame-content">
              //       <div className="gameContent-header">
              //         <h2 className="gameName">
              //           {user.userCurrentGameName} - {props.gameType}
              //         </h2>
              //         <div className="d-flex justify-content-center">
              //           <div className="d-flex align-items-center">
              //             <img
              //               className="calendar-icon"
              //               src={calendarIcon}
              //               alt=""
              //             />
              //             {/* <p className="ms-2 mb-0 h3 text-silver">22 Sep 22, 2:30PM</p> */}
              //             <p className="ms-1 mb-0 h3 text-silver">
              //               {moment(contestDetails.dateTime).format(
              //                 "DD MMM YY, hh:mmA"
              //               )}
              //             </p>{" "}
              //           </div>
              //           <span className="vertical-line"></span>
              //           <div className="d-flex align-items-center">
              //             <img className="rupee-icon" src={rupeeIcon} alt="" />
              //             <p className="ms-1 mb-0 h3 text-silver">
              //               {Number(contestDetails.entryFee) == 0
              //                 ? "free"
              //                 : contestDetails.entryFee}
              //             </p>
              //           </div>
              //         </div>

              //         {/* <h3>{props.name}</h3> */}
              //       </div>
              //       <div className="gameContent-body pt-0">
              //         <div className="text-center fontw-7">
              //           hey Alwyn! Have you played
              //           <br />
              //           Clash Royale before?
              //         </div>
              //         {!disabled ? (
              //           <p className="h4 mt-4 text-center">
              //             Don’t have a {user.userCurrentGameName} name?{" "}
              //             <Link
              //               className="text-white"
              //               to={"/gamefaq/" + contestDetails?.gameAry?._id}
              //             >
              //               Know how to create one
              //             </Link>
              //           </p>
              //         ) : (
              //           ""
              //         )}

              //         <div className="d-grid my-4">
              //           <button
              //             className="btn btn-danger btn-lg"
              //             onClick={() => PlayedGame()}
              //           >
              //             Yes
              //           </button>
              //         </div>
              //         <div className="d-grid my-4">
              //           <button
              //             className="btn  btn-lg btn-lg-no "
              //             onClick={() => NotPlayed()}
              //           >
              //             No
              //           </button>
              //         </div>

              //         <p className="h4 text-center">
              //           By joining this contest/tournament, you accept the
              //           Gamerji’s
              //           <br />;
              //           <Link to="/terms-and-conditions">
              //             <a className="fw-bold text-decoration-underline text-silver">
              //               {" "}
              //               terms & conditions
              //             </a>
              //           </Link>
              //         </p>
              //       </div>
              //     </div>
              //   </>
              // ) : (
                <>
                  <div className="joinGame-content 4">
                    <div className="gameContent-header">
                      <h2 className="gameName">
                        {user.userCurrentGameName} - {props.gameType}
                      </h2>
                      <div className="d-flex justify-content-center">
                        <div className="d-flex align-items-center">
                          <img
                            className="calendar-icon"
                            src={calendarIcon}
                            alt=""
                          />
                          {/* <p className="ms-2 mb-0 h3 text-silver">22 Sep 22, 2:30PM</p> */}
                          <p className="ms-1 mb-0 h3 text-silver">
                            {moment(contestDetails.dateTime).format(
                              "DD MMM YY, hh:mmA"
                            )}
                          </p>{" "}
                        </div>
                        <span className="vertical-line"></span>
                        <div className="d-flex align-items-center">
                          <img className="rupee-icon" src={rupeeIcon} alt="" />
                          <p className="ms-1 mb-0 h3 text-silver">
                            {Number(contestDetails.entryFee) == 0
                              ? "FREE"
                              : contestDetails.entryFee}
                          </p>
                        </div>
                      </div>
                      <div className="mt-4 mb-0 setInput">
                        <label className="userGmaneName">
                          {!disabled ? "edit " : ""}
                          enter your {user.userCurrentGameName} name
                        </label>
                        <input
                          disabled={disabled ? true : false}
                          type="text"
                          className="form-control userName"
                              value={props.name}
                          onBlur={() => setFocus(false)}
                          onFocus={() => setFocus(true)}
                          id="floatingInput"
                          name="bgmiName"
                          placeholder={`enter your ${user.userCurrentGameName} name`}
                          onChange={(e) => props.onChangeEditedName(e)}
                        />
                        <img
                          style={{ display: disabled ? "" : "none" }}
                          className="editIcon"
                          src={EditIcon}
                          onClick={(e) => onChangeEditName(e)}
                        />
                      </div>
                          {/* <h3>{props.name}</h3> */}
                    </div>
                    <div className="gameContent-body pt-0">
                      <div
                        className="alert alert-secondary warringBackground"
                        role="alert"
                      >
                        <img
                          src={InfoIcon}
                          className="info-icon me-1 pl-0"
                          alt=""
                        />{" "}
                        <p className="warring">
                          The {user.userCurrentGameName} name should be the same
                          as the one created on the app.
                        </p>
                      </div>
                      {!disabled ? (
                        <p className="h4 mt-4 text-center">
                          Don’t have a {user.userCurrentGameName} name?{" "}
                          <Link
                            className="text-white"
                            to={"/gamefaq/" + contestDetails?.gameAry?._id}
                          >
                            Know how to create one
                          </Link>
                        </p>
                      ) : (
                        ""
                      )}

                      <div className="d-grid my-4">
                        {
                          buttonDisbale ? 
                          <button className="btn btn-danger btn-lg" disabled>
                            Loading....
                          </button>
                          :
                          <button
                            className="btn btn-danger btn-lg"
                            onClick={() => { setButtonDisbale(true); props.registerUser() }}
                          >
                            {!disabled ? "save & confirm" : "confirm"}
                          </button>
                        }
                      </div>
                      <p className="h4 text-center">
                        By joining this contest/tournament, you accept the
                        Gamerji’s&nbsp;
                        <Link to="/terms-and-conditions" className="fw-bold text-decoration-underline text-silver">
                          {/* <a > */}
                            {" "}
                            terms & conditions
                          {/* </a> */}
                        </Link>
                      </p>
                    </div>
                  </div>
                 </>
              // )
            ) : (
              <div className="joinGame-content 5">
                <div className="gameContent-header border-0">
                  <h2>what’s your {user.userCurrentGameName} name?</h2>
                </div>
                <div className="gameContent-body jigar">
                  <div className="form-floating mb-2">
                    <input
                      type="text"
                      className="form-control"
                      onBlur={() => setFocus(false)}
                      onFocus={() => setFocus(true)}
                      id="floatingInput"
                      name="bgmiName"
                      placeholder={`enter your ${user.userCurrentGameName} name`}
                      onChange={(e) => props.onChangeName(e)}
                    />
                    <label htmlFor="floatingInput">
                      {focus || props.name
                        ? user.userCurrentGameName + " name"
                        : `enter your ${user.userCurrentGameName} name`}
                    </label>
                  </div>
                  <div
                    className="alert alert-secondary mt-4 warringBackground"
                    role="alert"
                  >
                    <img src={InfoIcon} className="me-1" alt="" />{" "}
                    <p className="warring">
                      The {user.userCurrentGameName} name should be the same as
                      the one created on the app.
                    </p>
                  </div>
                  <p className="h4">
                    Don’t have a {user.userCurrentGameName} name?{" "}
                    <Link
                      className="text-white"
                      to={"/gamefaq/" + contestDetails?.gameAry?._id}
                    >
                      Know how to create one
                    </Link>
                  </p>

                  <div className="d-grid mt-4">
                    {/* <button className={props.name === '' ? "btn btn-danger btn-lg disabled" : "btn btn-danger btn-lg"} disabled={props.name === ''} onClick={() => props.registerUser()}>Confirm</button> */}
                    <a
                      className={
                        props.name === ""
                          ? "btn btn-danger btn-lg disabled"
                          : "btn btn-danger btn-lg"
                      }
                      disabled={props.name === ""}
                      onClick={() => props.registerUser()}
                    >
                      confirm
                    </a>
                  </div>
                </div>
              </div>
            )
          : <div className="joinGame-content 3">
              <div style={{left: '43%', position: 'relative'}}>
                <Oval
                  height={300}
                  width={50}
                  color="#fff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#ffffffd1"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            </div>
          }
        </div>
      </section>
      {/* <div>kjd</div> */}
      {playedPopup && <PlayedGameBefore contestDetails={contestDetails} />}
      {NotplayedPopup && <NotPlayedGame contestDetails={contestDetails} />}
    </div>
  );
};
export default JoinGame;
